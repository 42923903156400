import React from 'react';
import "../Css/Footer.css";
import logo from '../img/logo.png';
import { Link } from 'react-router-dom';
import { MdAddIcCall } from 'react-icons/md';
import { GoMail } from 'react-icons/go';
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrGoogle } from 'react-icons/gr';



const Footer = () => {
    return (
        <>
            <div className='footer pt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 border-end border-bottom'>
                            <div className="address_main">
                                <div class="footer-logo">
                                    <Link to="/">
                                        <img src={logo} class="img-responsive" alt="footer_logo" />
                                    </Link>
                                </div>
                                <p>
                                Welcome to Thunga STH hospital, a leading multi-speciality hospital committed to delivering exceptional healthcare services. Our state-of-the-art facility is equipped with cutting-edge technology and a team of dedicated healthcare professionals.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-8 border-bottom'>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <div className='footer-head'>
                                        <h3>Quick Links</h3>
                                    </div>
                                    <div className='footer-link'>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/Doctor">Doctor's</Link></li>
                                            <li><Link to="/hospital-in-andheri-west">About Us</Link></li>
                                            <li><Link to="/PatientCare">Patient Care</Link></li>
                                            <li><Link to="/Internationalpt">International</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='footer-head'>
                                        <h3>Department</h3>
                                    </div>
                                    <div className='footer-service'>
                                        <ul>
                                            <li><Link to="/best-orthopedic-hospitals-andheri">Orthopedic</Link></li>
                                            <li><Link to="/best-gynecology-hospitals-andheri">Gynecology</Link></li>
                                            <li><Link to="/best-cardiac-hospital-andheri">Cardiology</Link></li>
                                            <li><Link to="/pediatric-hospital-andheri">Pediatric</Link></li>
                                            <li><Link to="/top-neurosurgery-hospital-andheri">Neurology</Link></li>
                                            <li><Link to="/nephrologists-andheri-kidney-care">Nephrology</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='footer-head'>
                                        <h3>Contact US</h3>
                                    </div>
                                    <div className='footer-add'>
                                        <ul>
                                            <li><a href="https://goo.gl/maps/kWwvaWUiQbZ3qMYPA" target='blank'><p>G-Abhishek Apartment, Juhu Versova Link Road, Dutta Marg Road, Near Kapaswadi, Andheri (W), Mumbai-400058</p></a></li>
                                        </ul>
                                    </div>
                                    <div className="footer-icon">
                                        <ul>
                                            <li><i><MdAddIcCall /></i><span>Call us : </span> <a href="tel:+91 8104560800">+91 8104560800</a></li>
                                            <li><i><GoMail /></i><span>Email : </span><a href="mailto: info@thungasthhospitals.com">info@thungasthhospitals.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row p-3'>
                        <div className='col-md-6'>
                            <div className="copyright-text">
                                <p>Copyright &copy; 2024, All Right Reserved <a href="index.html">
                                    <strong>Thunga STH Hospital</strong></a></p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="footer-menu">
                                <div className="copyright-text text-center">
                                    <p>Developed by<a href="https://skdm.in/" target='blank'>
                                        <strong> Shree Krishna Digital Marketing</strong></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
