import React, { useState } from 'react';
import "../Css/ContactUs.css";
import Footer from "../Components/Footer";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { GrMapLocation } from 'react-icons/gr';
import { IoIosCall } from 'react-icons/io';
import { HiMail } from 'react-icons/hi';
import Fade from 'react-reveal/Fade';
import { TbLocationPin } from 'react-icons/tb';
import { IoHomeOutline, IoMailOpenOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import $ from "jquery";

const ContactUs = () => {
    // State variables
    const [formData, setFormData] = useState({
        custName: '',
        custMail: '',
        custContact: '',
        custDisease: '',
        custMassage: '',
    });

    // Validation state variables
    const [errors, setErrors] = useState({
        custName: '',
        custMail: '',
        custContact: '',
        custDisease: '',
    });

    // Destructuring form data
    const { custName, custMail, custContact, custDisease, custMassage } = formData;

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic form validation
        const newErrors = {};

        if (!custName.trim()) {
            newErrors.custName = 'Please enter the Name.';
        }

        if (!custMail.trim()) {
            newErrors.custMail = 'Please enter the Mail ID';
        }

        if (!custContact.trim()) {
            newErrors.custContact = 'Please enter the phone number.';
        }

        if (!custDisease.trim()) {
            newErrors.custDisease = 'Please enter the Disease.';
        }

        // Update validation errors
        setErrors(newErrors);

        // If there are no errors, you can proceed with form submission logic
        if (Object.keys(newErrors).length === 0) {
            // Your form submission logic here

            const body =
                '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:red">THUNGA STH HOSPITAL ENQUIRY</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#2c4893"><strong>Name:</strong></td><td style="text-align:left">' +
                custName +
                '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Contact:</strong></td><td style="text-align:left">' +
                custContact +
                '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Email:</strong></td><td style="text-align:left">' +
                custMail +
                '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Disease:</strong></td><td style="text-align:left">' +
                custDisease +
                '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Massage:</strong></td><td style="text-align:left">' +
                custMassage +
                '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at THUNGA STH HOSPITAL</p></div></body></html>';

            // Send the POST request
            $.post(
                'https://skdm.in/server/v1/send_lead_mail.php',
                {
                    toEmail: 'info@thungasthhospitals.com',
                    fromEmail: 'skdmlead@gmail.com',
                    bccMail: 'skdmlead@gmail.com',
                    mailSubject: 'New Customer Lead',
                    mailBody: body,
                    accountName: 'Sai Tunga',
                    accountLeadSource: 'https://thungasthhospital.com/',
                    accountLeadName: custName,
                },
                function (dataa, status) {
                    console.log('data :' + dataa);
                }
            );

            // Display success message
            alert("Your Form has Submitted. Our team will contact you soon.");
        }
    }

    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='Contact-Section-1'>
                <div className='Contact-Section-con'>
                    <div className='Contact-Section-Text'>
                        <h2>
                            Contact
                        </h2>
                        <div className='Contact-Section-NavPage'>
                            <span><Link to="/"><AiFillHome />Home</Link></span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>Contact</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////// Section 2 /////////////////// */}

            <section className="ttm-row contact-detail">
                <div className="container res-1199-pl-15 res-1199-pr-15">
                    <div className="">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <Fade left cascade>
                                    <h2 className="mb-20 pt-55 res-1199-fs-24 res-991-pt-30 res-991-mb-0 contacth2">
                                        Our Hospital Inforamtion
                                    </h2>
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style8">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon-location" >
                                                    <TbLocationPin />
                                                </i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h5>Location</h5>
                                            </div>
                                            <div className="featured-desc">
                                                <p>G-Abhishek Apartment, Juhu Versova Link Road, Dutta Marg Road, Near Kapaswadi, Andheri (W), Mumbai-400058</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style8">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon-house" >
                                                    <IoHomeOutline />
                                                </i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h5>Call Us</h5>
                                            </div>
                                            <div className="featured-desc">
                                                <p>
                                                    24/7 Help Enquirary <br />
                                                    <a href="tel:+91 8104560800">+91 8104560800</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content icon-ver_align-top style8">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                <i className="flaticon-message" >
                                                    <IoMailOpenOutline />
                                                </i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h5>Email</h5>
                                            </div>
                                            <div className="featured-desc">
                                                <p><a href="mailto: info@thungasthhospitals.com">info@thungasthhospitals.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-md-8 col-sm-12">
                                <Fade right cascade>
                                    <div className="contact-section-wrapper ttm-bgcolor-grey res-767-mb-15">
                                        <div className="spacing-10">
                                            {/* section title */}
                                            <div className="section-title with-desc clearfix">
                                                <div className="title-header">
                                                    <h5 className="ttm-textcolor-skincolor">ENQUIRY</h5>
                                                    <h2 className="title">DO YOU HAVE ANY QUESTIONS?</h2>
                                                </div>
                                            </div>
                                            {/* section title end */}
                                            <form
                                                id="ttm-quote-form"
                                                className="row ttm-quote-form clearfix"
                                                method="post"
                                                action="#"
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            name="custName"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Your Name*"
                                                            required="required"
                                                            value={custName}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.custName && <p className="error-message">{errors.custName}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            name="custContact"
                                                            type="text"
                                                            placeholder="Your Phone*"
                                                            required="required"
                                                            className="form-control"
                                                            value={custContact}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.custContact && <p className="error-message">{errors.custContact}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            name="custMail"
                                                            type="email"
                                                            placeholder="Email Address*"
                                                            required="required"
                                                            className="form-control"
                                                            value={custMail}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.custMail && <p className="error-message">{errors.custMail}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            name="custDisease"
                                                            type="text"
                                                            placeholder="Disease"
                                                            required="required"
                                                            className="form-control"
                                                            value={custDisease}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.custDisease && <p className="error-message">{errors.custDisease}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            name="custMassage"
                                                            rows={4}
                                                            placeholder="Massage"
                                                            required="required"
                                                            className="form-control"
                                                            value={custMassage}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-50 res-767-mb-0">
                                                    <button type="submit" className="btn-title contactbtn">
                                                        Send Message
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 2 ---------------------- */}
            <section className='Contact-Section-2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='Contact-location-name'>
                                <h2>Malad</h2>
                            </div>
                            <div className='Contact-card-Con'>
                                <div className='Contact-Card-Body'>
                                    <div className='Contact-Map-con'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15071.98922545683!2d72.84612!3d19.19532!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6e0c9135f4f%3A0x9173bc908d0ce4e6!2sThunga%20Hospital%2C%20Malad!5e0!3m2!1sen!2sin!4v1698208826694!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <IoIosCall />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>Contact</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            <span>Tel: </span>+91 22 6908 4200
                                        </a>
                                        <a>
                                            <span>Fax: </span>(022) 2809 0128
                                        </a>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <HiMail />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>E-Mail</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            info@thungahospital.com
                                        </a>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <GrMapLocation />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>Address</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            Opp. Nirman Hitech Diagnostics, Goraswadi Lane, Near PVR Milap theatre, Off S.V. Road, Malad (W),Mumbai - 400064.
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='Contact-location-name'>
                                <h2>Mira Road</h2>
                            </div>
                            <div className='Contact-card-Con'>
                                <div className='Contact-Card-Body'>
                                    <div className='Contact-Map-con'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15063.090745008334!2d72.862368!3d19.292249!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b03793dc7d1b%3A0x7ef027b7d3317cf1!2sThunga%20Hospital!5e0!3m2!1sen!2sin!4v1698209671271!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <IoIosCall />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>Contact</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            <span>Tel: </span>+91 22 6905 6300
                                        </a>
                                        <a>
                                            <span>Fax: </span>(022) 2812 5641
                                        </a>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <HiMail />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>E-Mail</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            info@thungahospital.com
                                        </a>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <GrMapLocation />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>Address</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            Mira Bhayander Road, Mira Road (E), Dist. Thane - 401107.
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='Contact-location-name'>
                                <h2>Boisar</h2>
                            </div>
                            <div className='Contact-card-Con'>
                                <div className='Contact-Card-Body'>
                                    <div className='Contact-Map-con'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15071.98922545683!2d72.84612!3d19.19532!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6e0c9135f4f%3A0x9173bc908d0ce4e6!2sThunga%20Hospital%2C%20Malad!5e0!3m2!1sen!2sin!4v1698208826694!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <IoIosCall />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>Contact</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            <span>Tel: </span>02525 270310/11/270308/09
                                        </a>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <HiMail />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>E-Mail</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            info@thungahospital.com
                                        </a>
                                    </div>
                                    <div className='Contact-Card-name-icon'>
                                        <div className='contact-card-icons'>
                                            <GrMapLocation />
                                        </div>
                                        <div className='contact-card-Name'>
                                            <h3>Address</h3>
                                        </div>
                                    </div>
                                    <div className='contact-card-Para'>
                                        <a>
                                            AM-32, Tarapur MIDC, Boisar - 401506.
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 3 ---------------------- */}

            {/* <section className='Contactpage-Section-3'>
                <div className='container'>
                    <div className='ContactPage-Head'>
                        <h2>ENQUIRY</h2>
                        <div className='ContactPage-Head-Para'>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, iure. Error quisquam debitis beatae? Quo iste nemo porro sit. Provident reiciendis ut dolores non at sint similique modi accusantium tempora?</p>
                        </div>
                    </div>

                    <div className='Contact-Page-Section-2-form'>


                    </div>

                </div>

            </section> */}
            <Footer />
        </>
    )
}

export default ContactUs