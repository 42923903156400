import React, { useState, useLayoutEffect } from 'react';
import "../Css/Gallery.css";
import GalleryData from '../Components/GalleryData';
import Model from '../Components/Model';
import Zoom from 'react-reveal/Zoom';
import Footer from '../Components/Footer';
import Bounce from 'react-reveal/Bounce';
import { MetaTags } from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { AiFillHome, AiOutlineSafety } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
const Gallery = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [clickImage, setClickImage] = useState(null)
    const [cureentIndex, setCureentIndex] = useState(null)

    const handelClick = (item, index) => {
        setCureentIndex(index);
        setClickImage(item.gImage);

    };

    const handelRotationRight = () => {
        const totallength = GalleryData.length;

        if (cureentIndex + 1 >= totallength) {
            setCureentIndex(0);
            const newurl = GalleryData[0].gImage;
            setClickImage(newurl)
            return;
        };
        const newIndex = cureentIndex + 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].gImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totallength = GalleryData.length;
        if (cureentIndex === 0) {
            setCureentIndex(totallength - 1);
            const newUrl = GalleryData[totallength - 1].gImage;
            setClickImage(newUrl);
        }
        const newIndex = cureentIndex - 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].gImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };



    return (
        <>
            <MetaTags>
                <title>Multi Special Hospital in Mira Bhayandar | Chirayu Hospital</title>
                <meta title="Multi Special Hospital in Mira Bhayandar | Chirayu Hospital" />
                <meta name="description" content="Experience top-tier healthcare at the Multi-Special Hospital in Mira Bhayandar. Our expert medical team provides comprehensive care for all your health needs." />
                <meta name="keywords" content="best hospital in mira Bhayandar,multi special hospital in mira Bhayandar,hospital in mira Bhayandar,best hospital in mira Bhayandar,top multi specialty hospital in Mira Bhayandar,multispeciality hospital in mira Bhayandar,2d echo specialist in mira bhayandar,Ophthalmologist in mira bhayandar,Continuous Renal Replacement Therapy Specialist ,cashless mediclaim in mira bhayandar,cashless dialysis treatment in mira bhayandar,Critical Care And Emergency Service In Mira Bhayandar,Diabetes Specialist In Mira Bhayandar,best geriatric care in mira bhayandar,Hospice Care in mira bhayandar,EECP treatment  in mira bhayandar,How to avoid bypass surgery,infectious disease specialist in mira bhayandar ,kidney specialist  In Mira bhayandar,best nephrologist Hospital in mira bhayandar,Health checkup in mira bhayandar,Orthopaedic surgeon in mira bhayandar,Urologist and Nephrologist in mira bhayandar,Primary Health Care in mira bhayandar,General Health Care in Mira Bhaynadar,Vascular Surgeon in mira bhayandar,Orthopedic in Mira Bhayandar,Emergency Hospital in Mira Bhayandar,Ambulance in Mira Bhayandar,Diagnostic Centres in Mira Bhayandar,Inpatient Hospital Service In Mira Bhayndar,Top ICU Care Service in Mira Bhayandar,Affordable Hospital in Mira Bhayandar" />
                <link rel="canonical" href="https://chirayuh.com/multi-specialty-hospital-mira-bhayandar" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Multi Special Hospital in Mira Bhayandar | Chirayu Hospital" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://chirayuh.com/" />
                <meta property="og:description" content="Experience top-tier healthcare at the Multi-Special Hospital in Mira Bhayandar. Our expert medical team provides comprehensive care for all your health needs." />
                <meta property="og:image" content="https://chirayuh.com/static/media/Chirayu%20Hospital.76d644a69afa6613d6c1.png" />
            </MetaTags>
            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='About-Section-1'>
                <div className='About-Section-con'>
                    <div className='About-Section-Text'>
                        <h1>
                            Hospital in Andheri West
                        </h1>
                        <div className='About-Section-NavPage'>
                            <span><Link to="/"><AiFillHome />Home</Link></span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>Gallery</h2>
                        </div>
                    </div>
                </div>
            </section>
            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className='Image-Gallery'>
                <div className='Image-Gallery-title'>
                    <h4>Image gallery</h4>
                </div>
                <div className='container'>
                    <div className='row'>
                        {
                            GalleryData.map((item, index) => {
                                return (
                                    <>
                                        <div className='col-md-4'>
                                            <Zoom>
                                                {/* <div className='G-Images' key={index}>
                                                    <img src={item.gImage} alt="Multi Special Hospital in Mira Bhayandar" onClick={() => handelClick(item, index)} />
                                                </div> */}
                                                <div className="Hotel-image-wrapper G-Images" key={index}>
                                                    <img src={item.gImage} alt="Hotel Radisson" onClick={() => handelClick(item, index)} />
                                                    <div className="Hotel-img-overlay">
                                                        <h3>{item.gimagename}</h3>
                                                    </div>
                                                </div>
                                            </Zoom>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {clickImage && (
                <Model
                    clickImage={clickImage}
                    handelRotationRight={handelRotationRight}
                    setClickImage={setClickImage}
                    handelRotationLeft={handelRotationLeft}
                />
            )}


            {/* //////////// */}

            <Footer />

        </>
    )
}

export default Gallery