import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import NavBar from "./Components/NavBar";
import About from "./Pages/About";
import SpecialitiesData from "./Components/SpecialitiesData";
import ServiceDetail from "./Pages/ServiceDetail";
import Doctor from "./Pages/Doctor";
import DoctorInfo from "./Pages/DoctorInfo";
import Patientcare from "./Pages/Patientcare";
import Internationalpt from "./Pages/Internationalpt";
import Media from "./Pages/Media";
import Facilities from "./Pages/Facilities";
import ContactUs from "./Pages/ContactUs";
import Corporatesandtpas from "./Pages/Corporatesandtpas";
import HealthCheckup from "./Pages/HealthCheckup";
import FacilitiesData from "./Components/FacilitiesData";
import FacilitiesDetails from "./Pages/FacilitiesDetails";
import DoctorData from "./Components/DoctorData";
import Gallery from "./Pages/Gallery"
import Liposuction from "./Pages/Liposuction";
import GynecomastiaSurgery from "./Components/Gynecomastia/GynecomastiaSurgery.jsx";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hospital-in-andheri-west" element={<About />} />
          {
            SpecialitiesData.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.ServiceSlug}
                    element={<ServiceDetail />} />
                </>
              )
            })
          }

          {
            DoctorData.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.DoctorSlug}
                    element={<DoctorInfo />}
                  />
                </>
              )
            })
          }
          <Route path="/Doctor" element={<Doctor />} />
          <Route path="/PatientCare" element={<Patientcare />} />
          <Route path="/Internationalpt" element={<Internationalpt />} />
          <Route path="/Media" element={<Media />} />
          <Route path="/Facilities" element={<Facilities />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Corporatesandtpas" element={<Corporatesandtpas />} />
          <Route path="/healthcheckup" element={<HealthCheckup />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/liposuction" element={<Liposuction />} />
          <Route path="/gynecomastia-surgery-in-mumbai" element={<GynecomastiaSurgery />} />
          {
            FacilitiesData.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.FacilitiesSlug}
                    element={<FacilitiesDetails />} />
                </>
              )
            })
          }
          <Route path="/FacilitesDetails" element={<FacilitiesDetails />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
