import React, { useRef, useState } from 'react';
import Footer from "../Components/Footer";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

// import required modules
import { Autoplay } from 'swiper/modules';
import "../Css/Corporatesandtpas.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';

// Corporates

import airindia from "../img/CORPORATES/air-india.jpg";
import airportsauthorityofindia from "../img/CORPORATES/airports-authority-of-india.jpg";
import axisbank from "../img/CORPORATES/axis-bank.jpg";
import camlin from "../img/CORPORATES/camlin.jpg";
import canarabank from "../img/CORPORATES/canara-bank.jpg";
import decor from '../img/CORPORATES/decor.jpg';
import emcure from "../img/CORPORATES/emcure.jpg";
import esic from "../img/CORPORATES/esic.jpg";
import indian from "../img/CORPORATES/indian.jpg";
import jswsteel from "../img/CORPORATES/jsw-steel.jpg";
import mazagondock from "../img/CORPORATES/mazagon-dock.jpg";
import mtnl from "../img/CORPORATES/mtnl.jpg";
import nabard from "../img/CORPORATES/nabard.jpg";
import rajivgandi from "../img/CORPORATES/rajiv-gandi.jpg";
import rbi from "../img/CORPORATES/rbi.jpg";
import tatasteel from "../img/CORPORATES/tata-steel.jpg";
import Tata from "../img/CORPORATES/tata.jpg";
import vijayabank from "../img/CORPORATES/vijaya-bank.jpg";
import viraj from "../img/CORPORATES/viraj.jpg";

// TPAs

import alankit from "../img/TPAs/alankit.png";
import anmolmedicare from "../img/TPAs/anmol-medicare.png";
import apollomunichinsurance from "../img/TPAs/apollo-munich-insurance.png";
import bajajallianzgeneralinsurance from "../img/TPAs/bajaj-allianz-general-insurance.png";
import cholamanadalainsurancegroup from "../img/TPAs/cholamanadala-insurance-group.png";
import dedicatedhealthcareservices from "../img/TPAs/dedicated-healthcare-services.png";
import emeditakesolutions from "../img/TPAs/e-meditake-solutions.png";
import familyhealthplan from "../img/TPAs/family-health-plan.png";
import futuregeneraliindia from "../img/TPAs/future-generali-india.png";
import genisindia from "../img/TPAs/genis-india.png";
import goodhealthplan from "../img/TPAs/good-health-plan.png";
import healthindia from "../img/TPAs/health-india.png";
import heritage from "../img/TPAs/heritage.png";
import icanhealthcareservices from "../img/TPAs/ican-healthcare-services.png";
import icare from "../img/TPAs/icare.png";
import icicilombard from "../img/TPAs/icici-lombard.png";
import iciciprudential from "../img/TPAs/icici-prudential.png";
import maxbupa from "../img/TPAs/max-bupa.png";
import mdindiahealthcare from "../img/TPAs/md-india-healthcare.png";
import medsave from "../img/TPAs/med-save.png";
import mediassist from "../img/TPAs/medi-assist.png";
import medicare from "../img/TPAs/medicare.png";
import paramount from "../img/TPAs/paramount.png";
import parkmediclaim from "../img/TPAs/park-mediclaim.png";
import rakshatpa from "../img/TPAs/raksha-tpa.png";
import rothshield from "../img/TPAs/rothshield.png";
import safeway from "../img/TPAs/safeway.png";
import srigokulam from "../img/TPAs/sri-gokulam.png";
import starhealth from "../img/TPAs/star-health.png";
import unitedhealthare from "../img/TPAs/united-healthare.png";
import vidalhealth from "../img/TPAs/vidal-health.png";
import vipulmedicorp from "../img/TPAs/vipul-medicorp.png";

const Corporatesandtpas = () => {
    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='Corporatesandtpas-Section-1'>
                <div className='Corporatesandtpas-Section-con'>
                    <div className='Corporatesandtpas-Section-Text'>
                        <h2>
                            Corporates & TPAs
                        </h2>
                        <div className='Corporatesandtpas-Section-NavPage'>
                            <span><AiFillHome />Home</span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>Corporates & TPAs</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////// Section 3 ////////////// */}

            <section className='Corporatesandtpas-Section-3'>
                <div className='container'>
                    <div className='Corporatesandtpas-Section-3-head'>
                        <h2>CORPORATES</h2>
                    </div>
                    <div className='Corporatesandtpas-Section'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={airindia} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={airportsauthorityofindia} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={axisbank} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={camlin} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={canarabank} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={decor} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={emcure} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={esic} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={indian} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={jswsteel} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={mazagondock} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={mtnl} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={nabard} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={rajivgandi} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={rbi} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={tatasteel} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={Tata} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={vijayabank} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={viraj} alt="" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            {/* /////////////// Section 3 ////////////// */}

            <section className='Corporatesandtpas-Section-3'>
                <div className='container'>
                    <div className='Corporatesandtpas-Section-3-head'>
                        <h2>TPAs</h2>
                    </div>
                    <div className='Corporatesandtpas-Section'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={alankit} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={anmolmedicare} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={apollomunichinsurance} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={bajajallianzgeneralinsurance} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={cholamanadalainsurancegroup} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={dedicatedhealthcareservices} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={emeditakesolutions} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={familyhealthplan} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={futuregeneraliindia} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={genisindia} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={goodhealthplan} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={healthindia} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={heritage} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={icanhealthcareservices} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={icare} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={icicilombard} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={iciciprudential} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={maxbupa} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={mdindiahealthcare} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={medsave} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={mediassist} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={medicare} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={paramount} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={parkmediclaim} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={rakshatpa} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={rothshield} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={safeway} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={srigokulam} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={starhealth} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={unitedhealthare} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={vidalhealth} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Corporatesandtpas-Swiper'>
                                    <img src={vipulmedicorp} alt="" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Corporatesandtpas;
