import React, { useRef, useState } from 'react';
import "../Css/About.css";
import { AiFillHome, AiOutlineSafety } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { BsPeople, BsGraphUpArrow } from 'react-icons/bs';
import { CiMedal } from 'react-icons/ci';
import { GiTechnoHeart } from 'react-icons/gi';
import { TbFileCertificate } from 'react-icons/tb';
import Footer from "../Components/Footer";
import Mission from "../img/mission.webp";
import Vision from "../img/vision.jpg";
import TungaHospital from "../img/Hospital/entrance/entrance 08.JPG";
import About3 from "../img/Other/IMG_5620.JPG";
import NABH from '../img/Hospital/IMG_8803.JPG';

import ivusffr from "../img/ivus-ffr.png";
import pneumatic from "../img/pneumatic.png";
import navigation from "../img/navigation.jpg";
import urology from '../img/urology.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useLayoutEffect } from 'react';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { MetaTags } from 'react-meta-tags';
import CSR1 from "../img/CSR/csr1.jpg";
import CSR2 from "../img/CSR/csr2.jpg";
import CSR3 from "../img/CSR/csr3.jpg";
import CSR4 from "../img/CSR/csr4.jpg";
import CSR5 from "../img/CSR/csr5.png";
import CSR6 from "../img/CSR/csr6.jpg";
import CSR7 from "../img/CSR/csr7.jpg";
import { Link } from 'react-router-dom';

import HospitalImage from "../img/Other/Thunga Hospital.JPG"


const About = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <MetaTags>
                <title>Hospital in Andheri West | Thunga Hospital - Advanced Care 24/7</title>
                <meta title="Hospital in Andheri West | Thunga Hospital - Advanced Care 24/7" />
                <meta name="description" content="Top-rated Andheri West Hospital, Thunga offers 24/7 care, 20+ specialties, & expert doctors. Find your treatment near you. Book online!" />
                <meta name="keywords" content="Multi speciality hospital in Andheri West,Hospital in Andheri West,Emergency Ambulance Services in Andheri West,accident and emergency service in andheri,diagnostic centres In andheri,pathology and diagnostic centre in andheri,Digital X-Ray / Portable X-Ray In andheri,Sonography test in andheri,doppler test in andheri, mumbai,hemodialysis/ kidney dialysis in andheri,special health checkup packages in andheri,Endoscopy Centres in andheri,Blood bank Hospital in andheri,Best Cardiac Hospital in Andheri,Best Orthopedic Hospitals in Andheri,Best Gynecology Hospitals in Andheri,Pediatric hospital in Andheri,Top Neurosurgery Hospital in Andheri,Nephrologists in Andheri,Haematology Hospital in Andheri,General Physicians in Andheri,Best Pulmonology Hospitals in Andheri,Vascular Surgery Hospitals in Andheri,Laparoscopic Surgery in Andheri,Best Gastroenterology Hospitals in Andheri,Best Hospital for Cancer Surgery in Andheri,Best ENT Hospitals in Andheri,Diabetic Foot Care Hospital in Andheri,Cosmetic Surgery Clinics in Andheri,Best Dermatology Hospital in Andheri," />
                <link rel="canonical" href="https://thungasthhospital.com/hospital-in-andheri-west" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Hospital in Andheri West | Thunga Hospital - Advanced Care 24/7" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://thungasthhospital.com/" />
                <meta property="og:description" content="Top-rated Andheri West Hospital, Thunga offers 24/7 care, 20+ specialties, & expert doctors. Find your treatment near you. Book online!" />
                <meta property="og:image" content="" />
            </MetaTags>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='About-Section-1'>
                <div className='About-Section-con'>
                    <div className='About-Section-Text'>
                        <h1>
                            Hospital in Andheri West
                        </h1>
                        <div className='About-Section-NavPage'>
                            <span><Link to="/"><AiFillHome />Home</Link></span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>About</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 2 ---------------------- */}

            <section className="missionSection">
                <div className="container">
                    <div className="sec-title text-center">
                        <span className="sub-title">OUR MISSION OUR VISSION</span>
                        <h2> Our Compassionate Mission, Our Vision for Empowered Healthcare at Thunga
                            Hospital.</h2>
                        <span className="divider">
                            <svg viewBox="0 0 300.08 300.08">
                                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                            </svg>
                        </span>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="missionCard">
                                <div className="missionCardIcon">
                                    <img src={Mission} alt="Mission" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="missionCard">
                                <div className="missionCardText">
                                    <h4>Mission</h4>
                                    <p>
                                        We at Thunga STH hospital in Andheri West, understand that delivery of quality health care is not an automated process but one that needs compassionate well trained Doctors and staff striving the best to ensure that every patient is given the utmost care and dealt with expertise.                                    </p>
                                    <p>
                                        Hence we train each member of our team to have the self discipline to be a better version of themselves each passing day, both in their professional and personal capacity.                                    </p>
                                    <p>
                                        We truly believe that if each team member achieves this we will be able to provide excellent healthcare to our community.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 order-md-1 order-2">
                            <div className="missionCard">
                                <div className="missionCardText">
                                    <h4>Vision</h4>
                                    <p>
                                        We have a vision of raising the benchmark in quality of healthcare and serving as a role model to all small and medium sized hospitals, in how a dedicated skilled team can deliver precise medical treatments with highly predictable outcomes.
                                    </p>
                                    <p>
                                        At the end of the day we believe that medical, machines don’t run themselves .It is a compassionate healthcare provider with a skilled hand that can make all the difference.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 order-md-2 order-1">
                            <div className="missionCard">
                                <div className="missionCardIcon">
                                    <img src={Vision} alt="Vision" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* //////////////////////// Section //////////////// */}

            < section className="services-section" >
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">QUALITY POLICY</span>
                        <h2>On a journey to create healthier lives</h2>
                        <span className="divider">
                            <svg viewBox="0 0 300.08 300.08">
                                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                            </svg>
                        </span>
                    </div>
                    <div className="row">
                        {/* Service Block */}
                        <div className="service-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <span className="icon flaticon-heartbeat">
                                    <BsPeople />
                                </span>
                                <h5>
                                    <Link to="/healthcheckup">Customer Satisfaction</Link>
                                </h5>
                                <div className="text">
                                    We prioritize your well-being and comfort, tailoring our services to meet your unique needs and preferences.
                                </div>
                            </div>
                        </div>
                        {/* Service Block */}
                        <div className="service-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <span className="icon flaticon-surgery-room">
                                    <AiOutlineSafety />
                                </span>
                                <h5>
                                    <Link to="/healthcheckup">Safety</Link>
                                </h5>
                                <div className="text">
                                    We offer extensive medical procedures to outbound & inbound patients and we are very proud achievement staff.
                                </div>
                            </div>
                        </div>
                        {/* Service Block */}
                        <div className="service-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <span className="icon flaticon-pharmacy">
                                    <CiMedal />
                                </span>
                                <h5>
                                    <Link to="/healthcheckup">Excellence</Link>
                                </h5>
                                <div className="text">
                                    Our medical staff is composed of top-tier experts in their respective fields, using the latest medical advancements to guarantee the best outcomes.                                </div>
                            </div>
                        </div>
                        {/* Service Block */}
                        <div className="service-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <span className="icon flaticon-transport">
                                    <BsGraphUpArrow />
                                </span>
                                <h5>
                                    <Link to="/healthcheckup">Efficiency</Link>
                                </h5>
                                <div className="text">
                                    We constantly strive to enhance our services, systems, and patient experiences through feedback and innovation.                                </div>
                            </div>
                        </div>
                        {/* Service Block */}
                        <div className="service-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <span className="icon flaticon-lab">
                                    <GiTechnoHeart />
                                </span>
                                <h5>
                                    <Link to="/healthcheckup">Latest Technology</Link>
                                </h5>
                                <div className="text">
                                    Our state-of-the-art facility is equipped with the most advanced medical equipment and digital health systems to ensure accurate diagnosis and effective treatment.
                                </div>
                            </div>
                        </div>
                        {/* Service Block */}
                        <div className="service-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <span className="icon flaticon-first-aid">
                                    <TbFileCertificate />
                                </span>
                                <h5>
                                    <Link to="/healthcheckup">NABH Accredited</Link>
                                </h5>
                                <div className="text">
                                    In addition to our NABH accreditation, Thunga STH Hospital has received numerous accolades and recognition for our excellence in healthcare, further validating our commitment to quality and patient care.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* -------------------- About ---------------------------- */}

            < div className="about_wrapper" >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="abt_img abt_box">
                                <img src={HospitalImage} alt="Tunga STH Hospital" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 abt_section">
                            <div className="abt_heading_wrapper">
                                <h1 className="med_bottompadder20">THUNGA STH HOSPITALS</h1>
                            </div>
                            <div className="abt_txt">
                                <h3>
                                    The Hospital You Trust To Care For Those You Love
                                </h3>
                                <p>Founded in 2001, Thunga STH Hospital is a private multi-specialty hospital</p>
                                <p>One of the best healthcare centers in Mumbai</p>
                                <p>State-of-the-art diagnostic, therapeutic and intensive care facilities</p>
                                <p className="med_toppadder20">
                                    Located in Mira Road, Malad and Boisar, these hospitals are well planned and designed, featuring some of the best and most advanced technology equipment for diagnostics and healthcare treatments.
                                </p>
                            </div>
                            <div className="abt_chk med_toppadder30">
                                <div className="content">
                                    <ul>
                                        <li>
                                            <i className="fa fa-check-square-o" aria-hidden="true" />
                                            <span>Multiple Operation Theatre</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-square-o" aria-hidden="true" />
                                            <span>Sophisticated ICU</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-square-o" aria-hidden="true" />
                                            <span>Blood Storage Centre</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-square-o" aria-hidden="true" />
                                            <span>Digital Radiology Unit</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-square-o" aria-hidden="true" />
                                            <span>Kidney Transplant Unit</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-square-o" aria-hidden="true" />
                                            <span>Many Other Such Facilities</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* -------------------- section ---------------------------- */}

            < section className='About-Section5-Info' >
                <div className='container'>
                    <div className='about-section-with-back'>
                        <div className="sec-title text-center">
                            <span className="sub-title">NABH ACCREDITED</span>
                            <h2>Cleanest Hospital As Reported By Times</h2>
                            <span className="divider">
                                <svg viewBox="0 0 300.08 300.08">
                                    <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                                </svg>
                            </span>
                        </div>
                        <div className="AboutUs-Founder">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="AboutUs-Founder-Image-con">
                                        <div className="AboutUs-Founder-Image">
                                            <img
                                                src={NABH}
                                                alt="nabh certified hospital"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="Founder-Details">
                                        <div className="Founder-Info">
                                            <p>
                                                Today, the hospital stands to be a renowned medical center in the city, offering quality healthcare care solutions. With an ambitious goal of expanding to a 150 bed hospital, it continues its journey of success with great dedication and commitment.
                                                The hospital today prides in its number of happy customers who serve as a true testimony of the quality of services they offer. The hospital has received accolades for its services and has hence become the preferred healthcare choice for people in the city. It has been enriching lives of people with not just their treatment, but also through various social activities. The Thunga Medical Trust organizes a number of socio-medical camps and other social initiatives on a regular basis to benefit the poor and needy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="AboutUs-Founder second-con">
                    <div className='container'>
                        <h2>Our Journey</h2>
                        <div className="row">
                            <div className="col-md-6  order-md-1 order-2">
                                <div className="Founder-Details">
                                    <div className="Founder-Info">
                                        <p>
                                            Thunga STH Hospital began its progressive journey towards offering quality healthcare services with great commitment and compassion. Beginning with a relatively small but caring healthcare unit in Mira Road, the hospital has today grown into a state-of-the-art high quality healthcare hospital in Mumbai.
                                            <br></br>
                                            Initially with just a few beds, the hospital soon grew to being one of the significant and professionally run private hospitals in the western suburbs. In the year 2012 Thunga STH hospital entered into a joint venture with Tarapur Medical Research Charitable Trust (TMRCT), a trust founded by Tata Steel to build a day care centre in the Industrial area of Boisar to facilitate healthcare services for industrial employees. With the funding of Tata Steel, the hospital set up a centre with IPD 12 beds and an ICU which was the first of its kind in the area of Boisar.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-2 order-1">
                                <div className="AboutUs-Founder-Image-con">
                                    <div className="AboutUs-Founder-Image">
                                        <img
                                            src={TungaHospital}
                                            alt="Best hospital in Adheri"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="AboutUs-Founder second-con">
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="AboutUs-Founder-Image-con">
                                    <div className="AboutUs-Founder-Image">
                                        <img
                                            src={About3}
                                            alt="pathology and diagnostic centre in andheri"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Founder-Details">
                                    <div className="Founder-Info">
                                        <p>

                                            Later in the years, as the healthcare demand grew so did the number of beds and facilities. With an aim to provide the most up-dated diagnostic and treatment facilities in the field of healthcare, we accelerated expansion of the hospital for better patient care services.
                                            <br></br>
                                            The hospital soon created a blue print to build the city’s first multi-specialty private sector hospital. Today, equipped with the latest technology modern equipment, the hospital provides the best patient care service to people in the city. The hospital holds a lot of trust and respect of the people it serves. Here, people are not just patients with file number, but ailing people with medical needs to whom we treat and care like our own.
                                            <br></br>
                                            Today, we are a multispecialty healthcare hospital renowned for our medical excellence, dedication of its consultants and care and compassion of its staff towards its patients. The hospital has set a reputable name in the industry of healthcare and established itself as one of the foremost hospitals in the city.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >


            {/* -------------------- section ---------------------------- */}

            < section className='About-Section5-Info' >
                <div className='container'>
                    < div className='about-section-with-back' >
                        <div className="sec-title text-center difsection">
                            <span className="sub-title">USPs OF THUNGA</span>
                            <h2>On a journey to create healthier lives</h2>
                            <span className="divider">
                                <svg viewBox="0 0 300.08 300.08">
                                    <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                                </svg>
                            </span>
                        </div>
                        <div className="AboutUs-Founder">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="AboutUs-Founder-Image-con">
                                        <div className="AboutUs-Founder-Image">
                                            <img
                                                src={ivusffr}
                                                alt="IVUS"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="Founder-Details difsection">
                                        <div className="Founder-Info">
                                            <h2>IVUS / FFR GUIDED ANGIOPLASTY – <br></br>FIRST TIME IN WESTERN MUMBAI</h2>
                                            <p>
                                                Our Cathlab has state-of-the-art Volcano IVUS console (First time in Western Mumbai) for carrying out ADHOC IVUS Imaging during Angioplasty for ensuring optimal results in Cardiac procedures.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <div className="AboutUs-Founder second-con">
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6 order-md-1 order-2">
                                <div className="Founder-Details">
                                    <div className="Founder-Info">
                                        <h2>PNEUMATIC SHOOT TECHNOLOGY DRIVEN MEDICINE DELIVERY</h2>
                                        <p>
                                            For improving turnaround times to deliver pharmaceuticals, specimens and blood products thus reducing human error and product loss associated with material delivery for efficient patient care. Thunga STH Hospital is one of the few hospitals to implement pneumatic systems in Mumbai.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-2 order-1">
                                <div className="AboutUs-Founder-Image-con">
                                    <div className="AboutUs-Founder-Image">
                                        <img
                                            src={pneumatic}
                                            alt="pneumatic"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="AboutUs-Founder second-con">
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="AboutUs-Founder-Image-con">
                                    <div className="AboutUs-Founder-Image">
                                        <img
                                            src={navigation}
                                            alt="navigation"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Founder-Details">
                                    <div className="Founder-Info">
                                        <h2>NAVIGATION SYSTEM FOR JOINT REPLACEMENT</h2>
                                        <p>
                                            The Department of Orthopedics and Joint Replacement is equipped with Brain Lab Navigation System which is one of the best machines installed Globally in Multiple leading centers to assist and aid Joint Replacement Surgeons during Joint Replacement Procedures.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="AboutUs-Founder second-con">
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6 order-md-1 order-2">
                                <div className="Founder-Details">
                                    <div className="Founder-Info">
                                        <h2>UROLOGY – LITHOTRIPSY</h2>
                                        <p>
                                            Advanced urology centre has Siemens make latest generation electromagnetic Lithotripter machine to carry out day care stone treatment/ ambulatory renal stone treatment without anaesthesia                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-2 order-1">
                                <div className="AboutUs-Founder-Image-con">
                                    <div className="AboutUs-Founder-Image">
                                        <img
                                            src={urology}
                                            alt="urology"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* --------------------- Section CSR ACTIVITIES  --------------- */}

            < section className='AboutUs-Section-5' >
                <div className='container'>
                    <div className="sec-title text-center difsection">
                        <span className="sub-title">CSR ACTIVITIES</span>
                        <h2>On a journey to create healthier lives</h2>
                        <span className="divider">
                            <svg viewBox="0 0 300.08 300.08">
                                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                            </svg>
                        </span>
                    </div>
                    <div className="Section-CSR-con">
                        <div className="row">
                            <div className='col-md-6'>
                                <div className='Section-CSR-Image-Swaiper-con'>
                                    <Swiper
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <div className='Section-CSR-Image-con'>
                                                <img src={CSR1} alt="Digital X-Ray / Portable X-Ray In andheri" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='Section-CSR-Image-con'>
                                                <img src={CSR2} alt="Sonography test in andheri" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='Section-CSR-Image-con'>
                                                <img src={CSR3} alt="doppler test in andheri, mumbai" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='Section-CSR-Image-con'>
                                                <img src={CSR4} alt="hemodialysis/ kidney dialysis in andheri" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='Section-CSR-Image-con'>
                                                <img src={CSR5} alt="special health checkup packages in andheri" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='Section-CSR-Image-con'>
                                                <img src={CSR6} alt="Endoscopy Centres in andheri" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='Section-CSR-Image-con'>
                                                <img src={CSR7} alt="Blood bank Hospital in andheri" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='Section-CSR-Details-con'>
                                    <h2>Conducted free health check up camps for almost 950 Police personnel of Mira-Bhayander Road, Boisar & Palghar</h2>
                                    <ul>
                                        <li>
                                            <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                            <span>Donated more than 1200 spectacles to the senior citizens who were non affordable in the rural areas.</span>
                                        </li>
                                        <li>
                                            <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                            <span>Carried out almost 120 surgeries (Cardiac MVR/AVR, Orthopedic etc) to the poor patients.</span>
                                        </li>
                                        <li>
                                            <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                            <span>Associating with TATA STEEL LIMITED we have Carried out free health check up camps for more than 1500 families and distributed free medicines.</span>
                                        </li>
                                        <li>
                                            <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                            <span>Reservation of 3 Beds for the non affordable patients at Boisar centre.</span>
                                        </li>
                                        <li>
                                            <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                            <span>Free surgeries for 92 underprivileged & medically deprived childrens in association with Rotary Club & Dr. Arun Shourie</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />

        </>
    )
}

export default About;