import React from 'react';
import "../Css/Patientcare.css";
import { AiFillHome } from 'react-icons/ai';
import { FaHospital } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { GiAmbulance } from 'react-icons/gi';
import chooseusimg from "../img/Other/IMG_5624.JPG";
import icons2 from "../img/icon-2.svg";
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import { useLayoutEffect } from 'react';


const Patientcare = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='PatientCare-Section-1'>
                <div className='PatientCare-Section-con'>
                    <div className='PatientCare-Section-Text'>
                        <h2>
                            PatientCare
                        </h2>
                        <div className='PatientCare-Section-NavPage'>
                            <span><Link to="/"><AiFillHome />Home</Link></span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>PatientCare</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* ----------------- Section 2 ------------------------ */}

            <div className="choose-us-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-md-1 order-2">
                            <div className="choose-us-content">
                                <span className="top-title">OUR PATIENT-FRIENDLY APPROACH</span>
                                <p>
                                    At Thunga STH Hospital, we go the extra mile to ensure that a patient is always at ease.
                                </p>
                                <ul>
                                    <li>
                                        <span className='midline1'>1</span>
                                        <h3>Infection control</h3>
                                        At Thunga STH Hospital in Andheri West emphasis is given on simple practices of regularly sanitizing hands to implementing epidemiological surveillance and infection control. An infection control program and protocol has been put in place to ensure the well-being of both the patients and the hospital staff. These include engineering controls, state of the art designs of operation theaters with positive pressure, ultra clean air filters with 30 air changes an hour, appropriately designed intensive care units, central sterile supply department and a well planned segregation and disposal system of biomedical waste.
                                    </li>
                                    <li className="active">
                                        <span className='midline2'>2</span>
                                        <h3>Easy Processes</h3>
                                        All processes, at Thunga STH Hospital in Andheri West , have been simplified to deliver an experience of ease. This includes admission procedures, insurance claims, payment procedure, decentralized billing and others.
                                    </li>
                                    <li>
                                        <span>3</span>
                                        <h3>The building block</h3>
                                        The Thunga STH hospital building design is an innovative H-shape which gives immense advantages to the functioning of the hospital. It translates to the fact that all path-flows are separate, implying absolute infection control and safety. Also, the hospital’s two skylight zones have significant benefits as all wards and rooms, including the OTs and ICUs get good natural sunlight through the day.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 order-md-2 order-1">
                            <div className="choose-us-img ml-86">
                                <img src={chooseusimg} alt="Multi speciality hospital in Andheri West" />
                                <div className="ambulance-services d-flex">
                                    <img src={icons2} alt="Hospital in Andheri West" />
                                    <div className="ambulance-info">
                                        <span>24/7 Hours Service</span>
                                        <a href="tel:+91 8104560800">8104560800</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------- Section 3 ------------------------ */}

            <section className='Patientcare-Section-3'>
                <div className='container'>
                    <div className="sec-title text-center">
                        <span className="sub-title">INSURANCE POLICY</span>
                        <span className="divider">
                            <svg viewBox="0 0 300.08 300.08">
                                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                            </svg>
                        </span>
                    </div>
                    <div className='Patientcare-Section-3-con'>
                        <div className='Patientcare-Section-3-con-text'>
                            <h2>Cashless Hospitalization</h2>
                            <p>The facility of cashless hospitalization offers great advantages to patients. Patients who are insured can receive hospitalization and  necessary treatment without worrying about paying out of pocket. The designated TPA acts as the main point of contact between the Medical health care Hospitals in Andheri West and the insurance companies for payment of bills on behalf of the insured patients. (Insurance is available upon request as per  IRDA guidelines)</p>
                        </div>
                        <div className='Patientcare-Section-3-con-text'>
                            <h2>Network Hospitals</h2>
                            <p>Every TPA has agreements with several healthcare service providers, those are called Network Hospitals. In the case of hospitalization, you will be eligible for cashless hospitalization at those Network Hospitals, subject to the terms & conditions mentioned in your specific insurance policy.</p>
                            <p>For convenience and hassle free hospital experience at Thunga STH Hospital, we have tied up with the following recognized and Third Party Administrators and Insurance companies.</p>
                        </div>
                    </div>

                    <div className='Patientcare-Section-3-Policy'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='Patientcare-Section-3-Policy-head'>
                                    <h2>list of insurance companies</h2>
                                    <div className='listofinsurancecompanies'>
                                        <ul>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Apollo Munich Health Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Aditya Birla Health Insurance Company Limited Bajaj Allianz General Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Bharti AXA General Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Cholamandalam MS General Insurance Company Limited Future Generali India Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>HDFC ERGO General Insurance Company Limited ICICI Lombard General Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Iffco Tokio General Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Liberty General Insurance Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Magma HDI General Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Manipal Cigna Health Insurance Company Limited MAX Bupa Health Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>National Insurance Company Limited Reliance General Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Reliance Health Insurance Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Religare Health Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Royal Sundaram Alliance Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>SBI General Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Star Health and Allled insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Tata AIG General Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>The New India Assurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>The Oriental Insurance Company Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>United India Insurance Company Limited Universal Sompo General Insurance Company Limited</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='Patientcare-Section-3-Policy-head'>
                                    <h2>list of TPA companies</h2>
                                    <div className='listofinsurancecompanies'>
                                        <ul>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Medi Assist Insurance TPA Private Limited United Health Care Parekh Insurance TPA Private</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>MD India Healthcare (TPA) Services (Pvt.) Ltd</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Family Health Plan Insurance TPA Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Vidal Health Insurance TPA Private Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Rothshield Insurance TPA Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Spurthi Meditech TPA Solutions Pvt. Ltd. Ericson Insurance TPA Private Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Safeway Insurance TPA Private Limited Dedicated Healthcare Services TPA (India) Private Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Raksha Health Insurance TPA Private Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Heritage Health Insurance TPA Private Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Park Mediclaim Insurance TPA Private Limited Health Insurance TPA of India Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Paramount Health Services & Insurance TPA Private Limited East West Assist Insurance TPA Private Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Medsave Health Insurance TPA Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Genins India Insurance TPA Limited Alankit Insurance TPA Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Good Health Insurance TPA Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Vipul Medcorp Insurance TPA Private Limited</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check-square-o" aria-hidden="true" />
                                                <span>Health India Insurance TPA Services Private Limited</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ------------- section 4 ------------------ */}

            <section className='patientcare-Section-4'>
                <div className='container'>
                    <div className="sec-title text-center">
                        <span className="sub-title">Emergency Service</span>
                        <h2>Your Health, Our Priority - Click for Emergency</h2>
                        <span className="divider">
                            <svg viewBox="0 0 300.08 300.08">
                                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                            </svg>
                        </span>
                    </div>
                    <div className='Patientcare-section-4-details'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='Patientcare-section-4-Emergency-Service'>
                                    <h2>Emergency Service</h2>
                                    <p>We are proud to offer cutting-edge medical facilities and equipment to ensure that you receive the best care possible. Our emergency department is equipped to handle all types of emergencies, from minor injuries to life-threatening conditions.</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='Emergency-Service-two-btn-con'>
                                    <h2>Instant Care, One Click Away</h2>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='Emergency-Service-btn-con-main'>
                                                <div className='Emergency-Service-btn-con'>
                                                    <a>
                                                        <div className='Emergency-Service-btn'>
                                                            <GiAmbulance />
                                                            <div className='Emergency-contact'>
                                                                <h4 className='blinker'>Emergency Service</h4>
                                                                <p className='blinker'>022-35244700/704</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='Emergency-Service-btn-con-main'>
                                                <div className='Emergency-Service-btn-con'>
                                                    <a>
                                                        <div className='Emergency-Service-btn'>
                                                            <FaHospital />
                                                            <div className='Emergency-contact'>
                                                                <h4 className='blinker'>Hospital</h4>
                                                                <p className='blinker'>022-35244700</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Patientcare;