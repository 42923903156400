import React, { useRef, useState } from "react";
import "../Css/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import ServiceData from "../Components/ServiceData";
import CountUp from 'react-countup';
import Doctor01 from "../img/Doctor/Demo Doctor 1.jpg";
import Doctor02 from "../img/Doctor/Demo Doctor 2.jpg";
import Doctor03 from "../img/Doctor/Demo Doctor 3.jpg";
import Doctor04 from "../img/Doctor/Demo Doctor 4.jpg";
import $ from "jquery";

/////// Testimonial ////////

import ShaziaAmin from "../img/Hospital/user.png";
import NikhilPadhiyar from "../img/Testimonial/Nikhil Padhiyar.png";
import AjinkyaRahate from "../img/Testimonial/Ajinkya Rahate.png";
import RG from "../img/Testimonial/R G.png";

/////////// icons ///////////////

import { IoIosCall } from 'react-icons/io';
import { FaMedal } from 'react-icons/fa';
import { TiGroup } from 'react-icons/ti';
import { GiMedicines } from 'react-icons/gi';

////////////////////////////////////////////

import BookAnAppointment from "../img/Icons/calendar.png";
import FindADoctor from "../img/Icons/doctor.png";
import ViewSpecialities from "../img/Icons/perks.png";
import ePortal from "../img/Icons/medical-report.png";

// ----------- Home About Us ------------------

import icon01 from "../img/Home/icon-01.png";
import icon02 from "../img/Home/maternity-care.png";
import icon03 from "../img/Home/icon-03.png";
import icon04 from "../img/Home/icon-04.png";
import SpecialitiesData from "../Components/SpecialitiesData";

import counterimage from "../img/vector_image-removebg-preview.png";
import Footer from "../Components/Footer";
import { MetaTags } from 'react-meta-tags';
// ------------- Service Icons ---------------

import Allergy from "../img/Service Icons/Allergy.png";
import Boneandjointdisorders from "../img/Service Icons/Bone and joint disorders.png";
import FertilityProblems from "../img/Service Icons/Fertility Problems.png";
import Gastrointestinaldisorder from "../img/Service Icons/Gastrointestinal disorder.png";
import HormonalDisorder from "../img/Service Icons/Hormonal Disorder.png";
import RespiratoryDisorder from '../img/Service Icons/Respiratory Disorder.png';
import Skinhairdisorder from "../img/Service Icons/Skin & hair disorder.png";
import VascularDisorders from "../img/Service Icons/Vascular Disorders.png";
import DoctorData from "../Components/DoctorData";

// Images 

import resorption from "../img/Other/resorption 1.JPG";

import About01 from "../img/Hospital/About 01.jpeg";
import About02 from "../img/Hospital/About 02.jpeg";
import About03 from "../img/Hospital/About 03.jpeg";


const Home = () => {
  function toggleSpecialities() {
    const specialitiesDiv = document.getElementById("showSpecialities");
    if (specialitiesDiv.style.display === "none") {
      specialitiesDiv.style.display = "block";
    } else {
      specialitiesDiv.style.display = "none";
    }
  }

  const [custName, setcustName] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [departmentsName, setdepartmentsName] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [custContact, setCustContact] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");


  // Validation state variables
  const [errors, setErrors] = useState({
    custName: '',
    doctorName: '',
    custContact: '',
    symptoms: '',
    appointmentDate: '',
  });

  // Handle Doctor selection
  const handleDoctorChange = (e) => {
    const selectedDoctor = e.target.value;
    setDoctorName(selectedDoctor);

    // Find the selected doctor in the DoctorData array
    const selectedDoctorData = DoctorData.find((doc) => doc.DoctorName === selectedDoctor);

    // Update the Department's Name based on the selected doctor
    if (selectedDoctorData) {
      setdepartmentsName(selectedDoctorData.Speciality);
    }

    // Clear validation error for doctorName when a new doctor is selected
    setErrors((prevErrors) => ({ ...prevErrors, doctorName: '' }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic form validation
    const newErrors = {};

    if (!custName.trim()) {
      newErrors.custName = 'Please enter the patient name.';
    }

    if (!doctorName.trim()) {
      newErrors.doctorName = 'Please select a doctor.';
    }

    if (!custContact.trim()) {
      newErrors.custContact = 'Please enter the phone number.';
    }

    if (!symptoms.trim()) {
      newErrors.symptoms = 'Please enter the symptoms.';
    }

    if (!appointmentDate) {
      newErrors.appointmentDate = 'Please choose a date for the appointment.';
    }

    // Update validation errors
    setErrors(newErrors);

    // If there are no errors, you can proceed with form submission logic
    if (Object.keys(newErrors).length === 0) {
      // Your form submission logic here

      const body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:red">THUNGA STH HOSPITAL ENQUIRY</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#2c4893"><strong>Name:</strong></td><td style="text-align:left">' +
        custName +
        '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Doctor Name:</strong></td><td style="text-align:left">' +
        doctorName +
        '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Department:</strong></td><td style="text-align:left">' +
        departmentsName +
        '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Symptoms:</strong></td><td style="text-align:left">' +
        symptoms +
        '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Contact:</strong></td><td style="text-align:left">' +
        custContact +
        '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Appointment Date:</strong></td><td style="text-align:left">' +
        appointmentDate +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at THUNGA STH HOSPITAL</p></div></body></html>';

      // Send the POST request
      $.post(
        'https://skdm.in/server/v1/send_lead_mail.php',
        {
          toEmail: 'info@thungasthhospitals.com',
          fromEmail: 'skdmlead@gmail.com',
          bccMail: 'skdmlead@gmail.com',
          mailSubject: 'New Customer Lead',
          mailBody: body,
          accountName: 'Sai Tunga',
          accountLeadSource: 'https://thungasthhospital.com/',
          accountLeadName: custName,
        },
        function (dataa, status) {
          console.log('data :' + dataa);
        }
      );

      // Display success message
      alert("Your Form has Submitted. Our team will contact you soon.");
    }
  }



  return (
    <>

      <MetaTags>
        <title>Thunga Hospital | Multi Speciality Hospital in Andheri West</title>
        <meta title="Thunga Hospital | Multi Speciality Hospital in Andheri West" />
        <meta name="description" content="Andheri West's leading multispeciality hospital. Thunga Hospital- Personalized care, 24/7 emergency, advanced tech. Book online today!" />
        <meta name="keywords" content="Multi speciality hospital in Andheri West,Hospital in Andheri West,Emergency Ambulance Services in Andheri West,accident and emergency service in andheri,diagnostic centres In andheri,pathology and diagnostic centre in andheri,Digital X-Ray / Portable X-Ray In andheri,Sonography test in andheri,doppler test in andheri, mumbai,hemodialysis/ kidney dialysis in andheri,special health checkup packages in andheri,Endoscopy Centres in andheri,Blood bank Hospital in andheri,Best Cardiac Hospital in Andheri,Best Orthopedic Hospitals in Andheri,Best Gynecology Hospitals in Andheri,Pediatric hospital in Andheri,Top Neurosurgery Hospital in Andheri,Nephrologists in Andheri,Haematology Hospital in Andheri,General Physicians in Andheri,Best Pulmonology Hospitals in Andheri,Vascular Surgery Hospitals in Andheri,Laparoscopic Surgery in Andheri,Best Gastroenterology Hospitals in Andheri,Best Hospital for Cancer Surgery in Andheri,Best ENT Hospitals in Andheri,Diabetic Foot Care Hospital in Andheri,Cosmetic Surgery Clinics in Andheri,Best Dermatology Hospital in Andheri," />
        <link rel="canonical" href="https://thungasthhospital.com/multi-speciality-hospital-in-andheri-west" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Thunga Hospital | Multi Speciality Hospital in Andheri West" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://thungasthhospital.com/" />
        <meta property="og:description" content="Andheri West's leading multispeciality hospital. Thunga Hospital- Personalized care, 24/7 emergency, advanced tech. Book online today!" />
        <meta property="og:image" content="" />
      </MetaTags>

      <div className="HomeBanner">
        <Swiper navigation={true} modules={[Navigation]} className="Homebanner">
          <SwiperSlide>
            <div className="homebanner1">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="bannerDesc">
                      <small>Empowering Health, Enriching Lives: Your Journey to Wellness Starts Here.</small>
                      <h1>Multispeciality hospital<br></br>in Andheri West</h1>
                      <p>
                        Your health is our priority, and we strive to exceed expectations in delivering comprehensive and personalized healthcare services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homebanner2">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="bannerDesc">
                      <small>Where Expertise Meets Empathy: Your Trusted Healthcare Partner.</small>
                      <h2>We Take Care Our <br></br>Patients Health</h2>
                      <p>
                        With a team of dedicated and experienced healthcare professionals, we are committed to providing personalized and compassionate care to our patients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div class="appointment-sec">
        <div class="container">
          <form class="slider_form" method="get">
            <div class="container">
              <div class="row">
                <div className="row row-cols-lg-4 row-cols-sm-2 row-cols-1">
                  <div className="col">
                    <div className="banner-cards">
                      <div className="row">
                        <div className="col-3">
                          <div className="doctor-holder doctor-holder-first">
                            <div className="doctor-holder-Image-con">
                              <img src={BookAnAppointment} alt="Book Appointment" />
                            </div>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="cards-content">
                            <p>
                              Book An <br />
                              Appointment
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="banner-cards">
                      <div className="row">
                        <div className="col-3">
                          <div className="doctor-holder doctor-holder-second">
                            <div className="doctor-holder-Image-con">
                              <img src={FindADoctor} alt="Best Doctor in Andheri" />
                            </div>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="cards-content">
                            <p>
                              Find <br />A Doctor
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="banner-cards">
                      <div className="row">
                        <div className="col-3">
                          <div className="doctor-holder doctor-holder-third">
                            <div className="doctor-holder-Image-con">
                              <img src={ViewSpecialities} alt="diagnostic centres In andheri" />
                            </div>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="cards-content">
                            <p>
                              View <br />
                              Specialities
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="banner-cards">
                      <div className="row">
                        <div className="col-3">
                          <div className="doctor-holder doctor-holder-fourth">
                            <div className="doctor-holder-Image-con">
                              <img src={ePortal} alt="Emergency Ambulance Services in Andheri West" />
                            </div>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="cards-content">
                            <p>
                              ePortal <br />
                              (Self-Help)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* =========== Home About ========== */}
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="sub-title">OUR MEDICAL</span>
                  <h2>
                    We're setting Standards in Research. What's more, Clinical Care.
                  </h2>
                  <span className="divider">
                    <svg viewBox="0 0 300.08 300.08">
                      <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                    </svg>
                  </span>
                  <p>
                    Thunga STH hospital, a leading multi-specialty hospital in Andheri West dedicated to providing comprehensive healthcare services with a commitment to excellence. With years of experience we have established ourselves as a trusted healthcare institution.
                  </p>
                  <p>
                    What sets us apart is our patient-centric approach. We believe in treating not just illnesses but individuals, and we prioritize the well-being and comfort of our patients at every step of their healthcare journey. Our commitment to quality, safety, and innovation has earned us a reputation for excellence in healthcare.
                  </p>
                </div>
                <div className="link-box">
                  <Link to="/About" className="theme-btn btn-style-one">
                    <span className="btn-title">More About</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="images-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="video-link">
                  <a
                    href="https://www.youtube.com/watch?v=4UvS3k8D4rs"
                    className="play-btn lightbox-image"
                    data-fancybox="images"
                  >
                    <span className="fa fa-play" />
                  </a>
                </div>
                <figure className="image-1">
                  <img
                    src="https://skyethemes.com/html/2022/medicoz/images/resource/image-1.png"
                    alt="Multi speciality hospital in Andheri West"
                  />
                </figure>
                <figure className="image-2">
                  <img
                    src="https://skyethemes.com/html/2022/medicoz/images/resource/image-2.png"
                    alt="Hospital in Andheri West"
                  />
                </figure>
                <figure className="image-3">
                  <span className="hex" />
                  <img
                    src="https://skyethemes.com/html/2022/medicoz/images/resource/image-3.png"
                    alt="Emergency Ambulance Services in Andheri West"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -------- Section why choose us -------------- */}

      <section id="service" className="service padding-100">
        <div className="container-fluid px-0">
          {/* Side form icons */}
          <div
            id="service_info_item"
            className="side-form-icons"
            style={{ display: "inline-block" }}
          >
            <i className="icon flaticon-phone-call" id="open-opening-popup" />
            <i className="icon flaticon-placeholder" id="open-location-popup" />
            <i
              className="icon flaticon-clock-circular-outline"
              id="open-form-popup"
            />
          </div>
          <div className="container">
            <div className="row mx-0">
              <div className="col-lg-4 col-md-12">
                <div className="service-left">
                  <h3 className="heading-3">Why Do Patients Choose Our Hospital ?</h3>
                  <div className="padding-bottom-35 padding-top-30">
                    <p>
                      With a team of highly skilled healthcare professionals, cutting-edge technology, and a wide range of medical specialties, we offer a holistic approach to healthcare.                    </p>
                  </div>
                  <div className="main-btn-wrap">
                    <Link to="/About" className="theme-btn btn-style-one">
                      <span className="btn-title">More About</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="service-right">
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <div className="right-column-one">
                        <div className="service-item-whyus">
                          <img src={icon02} alt="dental" />
                          <h6 className="heading-6">Maternity Care</h6>
                        </div>
                        <div className="service-item-whyus">
                          <img src={icon01} alt="cardiovascular" />
                          <h6 className="heading-6">cardiovascular</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="right-column-two">
                        <div className="service-item-whyus">
                          <img src={icon03} alt="neurology" />
                          <h6 className="heading-6">neurology</h6>
                        </div>
                        <div className="service-item-whyus">
                          <img src={icon04} alt="ophtalmologue" />
                          <h6 className="heading-6">ophtalmologue</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Service Right Column End*/}
            </div>
          </div>
        </div>
      </section>
      {/* ============= Home Speaciality ========== */}
      <section className="specialities">
        <div className="container">
          <div className="sec-title text-center">
            <span className="sub-title">OUR SERVICES</span>
            <h2>We Care Our Patients.</h2>
            <span className="divider">
              <svg viewBox="0 0 300.08 300.08">
                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
              </svg>
            </span>
          </div>
          <div className="Home-Section-Service-Section-2">
            <div className="row">
              <div className="col-md-3 col-6">
                <div className="Home-Page-Section-Service-Icon-con">
                  <div className="Home-Service-Icon-con">
                    <div className="Home-Service-Section-Icon-Image">
                      <img src={HormonalDisorder} alt="Hormonal Disorder" />
                    </div>
                  </div>
                  <h4>Hormonal Disorder</h4>
                </div>
              </div>


              <div className="col-md-3 col-6">
                <div className="Home-Page-Section-Service-Icon-con">
                  <div className="Home-Service-Icon-con">
                    <div className="Home-Service-Section-Icon-Image">
                      <img src={RespiratoryDisorder} alt="Respiratory Disorder" />
                    </div>
                  </div>
                  <h4>Respiratory Disorder</h4>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="Home-Page-Section-Service-Icon-con">
                  <div className="Home-Service-Icon-con">
                    <div className="Home-Service-Section-Icon-Image">
                      <img src={FertilityProblems} alt="Fertility Problems" />
                    </div>
                  </div>
                  <h4>Fertility Problems</h4>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="Home-Page-Section-Service-Icon-con">
                  <div className="Home-Service-Icon-con">
                    <div className="Home-Service-Section-Icon-Image">
                      <img src={Boneandjointdisorders} alt="Bone and joint disorders" />
                    </div>
                  </div>
                  <h4>Bone and joint disorders</h4>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="Home-Page-Section-Service-Icon-con">
                  <div className="Home-Service-Icon-con">
                    <div className="Home-Service-Section-Icon-Image">
                      <img src={Allergy} alt="Allergy" />
                    </div>
                  </div>
                  <h4>Allergy</h4>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="Home-Page-Section-Service-Icon-con">
                  <div className="Home-Service-Icon-con">
                    <div className="Home-Service-Section-Icon-Image">
                      <img src={Gastrointestinaldisorder} alt="Gastrointestinal disorder" />
                    </div>
                  </div>
                  <h4>Gastrointestinal disorder</h4>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="Home-Page-Section-Service-Icon-con">
                  <div className="Home-Service-Icon-con">
                    <div className="Home-Service-Section-Icon-Image">
                      <img src={Skinhairdisorder} alt="Skin & hair disorder" />
                    </div>
                  </div>
                  <h4>Skin & hair disorder</h4>
                </div>
              </div>

              <div className="col-md-3 col-6">
                <div className="Home-Page-Section-Service-Icon-con">
                  <div className="Home-Service-Icon-con">
                    <div className="Home-Service-Section-Icon-Image">
                      <img src={VascularDisorders} alt="Vascular Disorders" />
                    </div>
                  </div>
                  <h4>Vascular Disorders</h4>
                </div>
              </div>
            </div>

            <div className="MoreServices-Btn-Home">
              <Link to="/Facilities">
                <button>
                  More
                </button>
              </Link>
            </div>

          </div>
        </div>
      </section>

      {/* -------------- Section  form ////////////////////////// */}

      <section className="book_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col">
              <form>
                <h4>
                  BOOK APPOINTMENT
                </h4>
                <div className="row form-row">
                  <div className="form-group col-lg-4">
                    <label htmlFor="inputPatientName">Patient Name </label>
                    <input
                      type="text"
                      className={`form-control ${errors.custName ? 'is-invalid' : ''}`}
                      id="inputPatientName"
                      placeholder=""
                      onChange={(e) => setcustName(e.target.value)}
                      value={custName}
                    />
                    {errors.custName && <div className="invalid-feedback">{errors.custName}</div>}
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="inputDoctorName">Doctor's Name</label>
                    <select
                      name=""
                      className={`form-control wide ${errors.doctorName ? 'is-invalid' : ''}`}
                      id="inputDoctorName"
                      onChange={handleDoctorChange}
                      value={doctorName}
                    >
                      <option value="">Select a Doctor</option>
                      {DoctorData.map((ele) => (
                        <option key={ele.DoctorName} value={ele.DoctorName}>
                          {ele.DoctorName}
                        </option>
                      ))}
                    </select>
                    {errors.doctorName && <div className="invalid-feedback">{errors.doctorName}</div>}
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="inputDepartmentName">Department's Name</label>
                    <input
                      type="text"
                      className="form-control wide"
                      id="inputDepartmentName"
                      value={departmentsName}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row form-row">
                  <div className="form-group col-lg-4">
                    <label htmlFor="inputPhone">Phone Number</label>
                    <input
                      type="text"
                      className={`form-control ${errors.custContact ? 'is-invalid' : ''}`}
                      id="inputPatientName"
                      placeholder=""
                      onChange={(e) => setCustContact(e.target.value)}
                      value={custContact}
                    />
                    {errors.custContact && <div className="invalid-feedback">{errors.custContact}</div>}
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="inputSymptoms">Symptoms</label>
                    <input
                      type="text"
                      className={`form-control ${errors.symptoms ? 'is-invalid' : ''}`}
                      id="inputPatientName"
                      placeholder=""
                      onChange={(e) => setSymptoms(e.target.value)}
                      value={symptoms}
                    />
                    {errors.symptoms && <div className="invalid-feedback">{errors.symptoms}</div>}
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="inputDate">Choose Date </label>
                    <div
                      className="input-group date"
                      id="inputDate"
                      data-date-format="mm-dd-yyyy"
                    >
                      <input
                        type="date"
                        className={`form-control ${errors.appointmentDate ? 'is-invalid' : ''}`}
                        id="inputPatientName"
                        placeholder=""
                        onChange={(e) => setAppointmentDate(e.target.value)}
                        value={appointmentDate}
                      />
                      {errors.appointmentDate && <div className="invalid-feedback">{errors.appointmentDate}</div>}
                    </div>
                  </div>
                </div>
                <div className="btn-box">
                  <button type="submit" className="btn" onClick={handleSubmit} >
                    Submit Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* ============= Home Doctor Section =========== */}

      <section className="team-area">
        <div className="container">
          <div className="sec-title text-center">
            <span className="sub-title">OUR TEAM</span>
            <h2>Team of Consultants</h2>
            <span className="divider">
              <svg viewBox="0 0 300.08 300.08">
                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
              </svg>
            </span>
          </div>
          <div className="HomePage-Doctor-Section">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {
                DoctorData.map((elem) => {

                  const { DoctorName, DoctorImage, DoctorSpecialist, DoctorEducation, DoctorExperience, DoctorSlug } = elem
                  return (
                    <>
                      <SwiperSlide>
                        <div className="doctorPage">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="doctor_detail">
                                <div className="doctor-img">
                                  <img
                                    src={DoctorImage}
                                    alt="best doctor in andheri west"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 border-start">
                              <div className="doctor_desc">
                                <h2>{DoctorName}</h2>
                                <h5>
                                  <span>Qualifaction :</span> {DoctorEducation}
                                </h5>
                                <h6>
                                  <span>Department :</span> {DoctorSpecialist}
                                </h6>
                                <h6>
                                  <span>Experience :</span> {DoctorExperience}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="ServEnquiry">
                            <Link to={DoctorSlug}>
                              <button type="button" className="btn btn-primary">
                                Enquire Now
                              </button>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
      </section>


      {/* --------------- Counter Section------------ */}

      <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img11 clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row row-cols-lg-5 row-cols-md-3 row-cols-2 ttm-fid-row-wrapper">
            <div className="col">
              {/*ttm-fid*/}
              <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                <div className="ttm-fid-left">
                  <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                    <i className="flaticon-haircut YearsOfExperience" >
                      <FaMedal />
                    </i>
                  </div>
                  <h4 className="ttm-fid-inner">
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={15}
                      data-interval={1}
                      data-before=""
                      data-before-style="sup"
                      data-after=""
                      data-after-style="sub"
                      className="numinate completed"
                    >
                      15
                    </span>
                  </h4>
                </div>
                <div className="ttm-fid-contents text-left">
                  <h3 className="ttm-fid-title">Years Of Experience</h3>
                </div>
              </div>
            </div>
            <div className="col">
              {/*ttm-fid*/}
              <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                <div className="ttm-fid-left">
                  <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                    <i className="flaticon-haircut MedicalSpesialities" >
                      <TiGroup />
                    </i>
                  </div>
                  <h4 className="ttm-fid-inner">
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={320}
                      data-interval={10}
                      data-before=""
                      data-before-style="sup"
                      data-after=""
                      data-after-style="sub"
                      className="numinate completed"
                    >
                      320
                    </span>
                  </h4>
                </div>
                <div className="ttm-fid-contents text-left">
                  <h3 className="ttm-fid-title">Medical Specialties</h3>
                </div>
              </div>
            </div>
            <div className="col">
              {/*ttm-fid*/}
              <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                <div className="ttm-fid-left">
                  <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                    <i className="flaticon-salon MedicalSpesialities">
                      <GiMedicines />
                    </i>
                  </div>
                  <h4 className="ttm-fid-inner">
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={19}
                      data-interval={5}
                      data-before=""
                      data-before-style="sup"
                      data-after=""
                      data-after-style="sub"
                      className="numinate completed"
                    >
                      19
                    </span>
                  </h4>
                </div>
                <div className="ttm-fid-contents text-left">
                  <h3 className="ttm-fid-title">Insurance Companies</h3>
                </div>
              </div>
            </div>
            <div className="col">
              {/*ttm-fid*/}
              <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                <div className="ttm-fid-left">
                  <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                    <i className="flaticon-barber">
                      <TiGroup />
                    </i>
                  </div>
                  <h4 className="ttm-fid-inner">
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={40}
                      data-interval={100}
                      data-before=""
                      data-before-style="sup"
                      data-after=""
                      data-after-style="sub"
                      className="numinate completed"
                    >
                      40
                    </span>
                    <span>&nbsp;+</span>
                  </h4>
                </div>
                <div className="ttm-fid-contents text-left">
                  <h3 className="ttm-fid-title">Doctor's</h3>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                <div className="ttm-fid-left">
                  <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                    <i className="flaticon-barber">
                      <TiGroup />
                    </i>
                  </div>
                  <h4 className="ttm-fid-inner">
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={2555}
                      data-interval={100}
                      data-before=""
                      data-before-style="sup"
                      data-after=""
                      data-after-style="sub"
                      className="numinate completed"
                    >
                      2555
                    </span>
                    <span>&nbsp;+</span>
                  </h4>
                </div>
                <div className="ttm-fid-contents text-left">
                  <h3 className="ttm-fid-title">Happy Patients</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="featured-icon-box icon-align-before-content style7 mt-50 ml-15 res-991-mt-30 res-767-mt-10">
              <div className="featured-icon">
                <div className="ttm-icon ttm-icon_element-color-skincolor ttm-bgcolor-white ttm-icon_element-style-rounded ttm-icon_element-size-sm featured-content-call">
                  <IoIosCall />
                </div>
              </div>
              <div className="featured-content featured-content-homecounter">
                <div className="featured-title">
                  <h3 className="ttm-textcolor-white">
                    First Step Call Now !
                  </h3>
                </div>
                <div className="featured-desc">
                  <p><a href="">Phone :(+91)8104560800</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* ///////////////////////// Section testimonial ///////////////////// */}
      <section className="testimonial-area">
        <div className="container">
          <div className="sec-title text-center">
            <span className="sub-title">REVIEWS</span>
            <h2>What Our Clients Say</h2>
            <span className="divider">
              <svg viewBox="0 0 300.08 300.08">
                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
              </svg>
            </span>
          </div>
          <div className="testimonial-con">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={ShaziaAmin} alt="Shazia Amin" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          Treatment here is excellent.
                          Dr Ushma Patel mam is an excellent doctor very kind n caring, she explains everything very patiently and in a friendly manner.<br />
                          Staff they were helpful, and always smiling specially Dr Sushant Yadav, dr akshata, dr nasreen and nurses
                          Archna, rutuja, Rashmi, gaytri, Stella and pooja.<br />
                          Last but not least and importantly the cleaning staff for the hard work in keeping the hospital neat, clean, tidy and comfortable.<br />
                          Deeply appreciate the doctors and all the staff's who took care of my husband highly appreciated.<br />
                          I was sceptical since it’s a newly opened hospital. All service is very good, it's a best hospital in juhu. Highly recommended!
                        </p>
                      </div>
                      <div className="name">
                        <h3>Shazia Amin</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={NikhilPadhiyar} alt="Nikhil Padhiyar" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          Thunga Hospital exceeded my expectations in every aspect! The impeccable service, compassionate staff, and state-of-the-art facilities truly make it a standout healthcare provider. <br />
                          A special shoutout to Nurse Yamini for her exceptional help and outstanding behavior. Her dedication and kindness made my experience even more positive. I wholeheartedly recommend Thunga Hospital for their top-notch care. Five stars all the way!
                        </p>
                      </div>
                      <div className="name">
                        <h3>Nikhil Padhiyar</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={AjinkyaRahate} alt="Ajinkya Rahate" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          I had a wonderful experience. I was greeted by a care practitioner at the clinic who explained me about the causes , treatment options & its implications. Answered all my queries. Post which , I met Dr.Satish Shetty, he was very kind and caring. He..😊😊😊
                        </p>
                      </div>
                      <div className="name">
                        <h3>Ajinkya Rahate</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={ShaziaAmin} alt="Rupesh P" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          I would really recommend Thunga Hospital at Andheri West to patients looking for top class care & service.  I was admitted at Andheri Hospital for 5 days and I want to really thank all the Doctors, Staff, Nurses & Sisters, Pantry Staff and other staff working day & night to take care of patients.  A special thanks to Yamini & stella for taking good care of me.  Keep up the good work.
                          Doctors - Excellent,
                          Care - Excellent,
                          Cleaniness & Hygiene - Excellent,
                          Nurse & other staff - Excellent,
                          Food & Beverages - Excellent,
                          Highly recommended!,
                        </p>
                      </div>
                      <div className="name">
                        <h3>Rupesh P</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={RG} alt="R G" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          1. A very special thank you to Dr Ashwini Nabar who sucessfully operated on me with her team. She is very kind & compassionate. Very experienced in the field, is down to earth & made me feel comfortable & answered all my quieries patiently. Rare to find such doctors in today's times indeed.<br />
                          2. Dr Akshata & Dr Nasreen , thank you for answering all my queries patiently and for always being there & comforting me.<br />
                          3. Yamini nurse is genuinely very caring and served from the heart with love. Hastika , pranali & all the nurses , thank you for your care & patience.<br />
                          4. To all the cleaning staff , thank you for your personal & selfless help.<br />
                          5. Thank you Tejal for  expediting my discharge formalities efficiently.
                        </p>
                      </div>
                      <div className="name">
                        <h3>R G</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={ShaziaAmin} alt="Dinesh Waghela" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          My brother got operated in Thunga STH Hospital juhu. The staff is extremely friendly and helpful. The hospital maintains cleanliness and hygiene to ensure patients health. Overall our experience was excellent. Highly recommended.
                        </p>
                      </div>
                      <div className="name">
                        <h3>Dinesh Waghela</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={ShaziaAmin} alt="Deepashri Parulekar" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          Excellent infrastructure, good clean rooms. Polite and friendly staff. Thank you Dr. Anuj Tiwari, Dr.Advika and sister Pranali and Rutuja for patiently treating our patient. The entire staff of Thunga STH Andheri hospital including the security guards made our stay comfortable and stress-free. A special mention to good and clean quality of food served to the patient.
                        </p>
                      </div>
                      <div className="name">
                        <h3>Deepashri Parulekar</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
