import React from 'react';
import "../Gynecomastia/GynecomastiaSurgery.css";
import { Button, Form, Input } from 'antd';
import mengynecomastia from "../../img/men-gynecomastia.png";
import GynecomastiaBA from "../../img/Gynecomastia BA.jpg";
import GynaecomastiaProcedure from "../../img/Gynaecomastia-Procedure.jpg";
import Footer from "../../Components/Footer";
import $ from "jquery";

const GynecomastiaSurgery = () => {
    const handleSubmit = (values) => {
        console.log("values", values)
        const body = '<!doctypehtml><title>Enquiry Lead</title><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style=color:red>THUNGA STH HOSPITAL ENQUIRY</h2><p>Hello<p>Thank you for your interest in our products/services.<p>Please check your enquiry which generated from website:<table cellpadding=5 style=margin:0><tr><td style=text-align:left;color:#2c4893><strong>Name:</strong><td style=text-align:left>' + values.Name + '<tr><td style=text-align:left;color:#2c4893><strong>Contact:</strong><td style=text-align:left>' + values.Contact + '</table><p style=font-weight:700>Best regards,<br>Your Team at THUNGA STH HOSPITAL</div>';

        // Send the POST request
        $.post(
            'https://skdm.in/server/v1/send_lead_mail.php',
            {
                toEmail: 'info@thungasthhospitals.com',
                fromEmail: 'skdmlead@gmail.com',
                bccMail: 'skdmlead@gmail.com',
                mailSubject: 'New Customer Lead',
                mailBody: body,
                accountName: 'Sai Tunga',
                accountLeadSource: 'https://thungasthhospital.com/',
                accountLeadName: values.Name,
            },
            function (dataa, status) {
                console.log('data :' + dataa);
            }
        );
        alert("Your Form has Submitted. Our team will contact you soon.");
    }
    return (
        <>
            <section className='Gynecomastia-Surgery-Section-1-con'>
                <div className='container'>
                    <div className='Gynecomastia-Surgery-Section1-Con'>
                        <div className='row'>
                            <div className="col-lg-5 col-md-6">
                                <div className='Gynecomastia-Surgery-section1-info'>
                                    <h1>Permanent Surgery for Gynecomastia in mumbai</h1>
                                    <p>
                                        Are you struggling with gynecomastia and seeking a permanent solution in Mumbai? Look no further than Thinga STH Hospital, where we specialize in transformative surgical interventions for gynecomastia. Our expert surgeons understand the emotional and physical impact of this condition, and we are committed to helping you regain confidence and live your life to the fullest.
                                    </p>
                                    <p>
                                        At Thinga STH Hospital, we offer state-of-the-art permanent surgery for gynecomastia in Mumbai. With years of experience and a dedication to excellence, our surgeons utilize the latest techniques and technologies to deliver outstanding results. Whether you're dealing with mild or severe gynecomastia, our personalized approach ensures that you receive the most effective treatment tailored to your unique needs.
                                    </p>
                                    <div className='Gynecomastia-Surgery-Section1-btn'>
                                        <a href="tel:+91 8104560800">
                                            Call in Complete Privacy
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 Image-section">
                                <div className='Gynecomastia-Surgery-Section1-Image-con'>
                                    <div className='Gynecomastia-Surgery-Section1-Image'>
                                        <img src={mengynecomastia} alt='' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className='Gynecomastia-Surgery-Section1-form-con'>
                                    <div className='Gynecomastia-Surgery-Section1-form'>
                                        <div className='Gynecomastia-Surgery-Section1-form-heading'>
                                            <h3>Get Rid of <br></br> Gynecomastia</h3>
                                        </div>
                                        <div className='Gynecomastia-Surgery-Section1-form-in'>
                                            <Form onFinish={handleSubmit}>
                                                <Form.Item name="Name">
                                                    <Input
                                                        placeholder='Your Name'
                                                        className="form-control inputfields"
                                                    />
                                                </Form.Item>
                                                <Form.Item name="Contact">
                                                    <Input
                                                        placeholder='Mobile Number'
                                                        className="form-control inputfields"
                                                    />
                                                </Form.Item>
                                                <Form.Item className='Add-task-btn'>
                                                    <Button htmlType="submit">Get a Call Back</Button>
                                                </Form.Item>
                                            </Form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Liposuction-Surgery-Section-3-con'>
                <div className='container'>
                    <div className='Liposuction-Surgery-Section-3-Head'>
                        <h2> What is Gynecomastia? </h2>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='Liposuction-Surgery-Section-3-Image-con'>
                                <div className='Liposuction-Surgery-Section-3-Image'>
                                    <img src={GynaecomastiaProcedure} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='Liposuction-Surgery-Section-3-Info'>
                                <p>
                                    Gynecomastia is a medical condition characterized by the enlargement of glandular breast tissue in males. It can result in the appearance of swollen or enlarged breasts, often causing discomfort and self-consciousness. Gynecomastia can occur at any age but is most common during infancy, puberty, and older age due to hormonal changes. While it is typically benign and not a serious health risk, it can have a significant impact on a person's confidence and self-esteem. Gynecomastia can be caused by various factors, including hormonal imbalances, certain medications, obesity, and underlying medical conditions. Treatment options for gynecomastia may include medication, lifestyle changes, or surgical intervention, depending on the severity and underlying cause of the condition.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Liposuction-Surgery-Section-4-con'>
                <div className='container'>
                    <div className='Liposuction-Surgery-Section-4-Head'>
                        <h2 className='Head-Style-way'>Why Choose Thunga STH Hospital for Liposuction Surgery in Andheri? </h2>
                    </div>

                    <div className='Liposuction-Surgery-Section-4-Details'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='Liposuction-Surgery-Section-4-Info-con'>
                                    <div className='Liposuction-Surgery-Section-4-Info'>
                                        <p>Why choose permanent surgery for gynecomastia in Mumbai at Thinga STH Hospital? Here are just a few reasons:</p>
                                        <ul>
                                            <li>Our team of highly skilled surgeons has extensive experience in performing gynecomastia surgery. You can trust us to deliver safe and effective results.</li>
                                            <li>We stay at the forefront of medical innovation, utilizing advanced surgical techniques to achieve optimal outcomes with minimal scarring and downtime.</li>
                                            <li>At Thinga STH Hospital, we understand that each patient is unique. We take the time to listen to your concerns and goals, tailoring our approach to ensure your satisfaction.</li>
                                            <li> From your initial consultation to post-operative care, our dedicated staff is here to support you every step of the way. We provide comprehensive guidance and assistance to ensure a smooth and successful recovery.</li>
                                            <li> Thinga STH Hospital is renowned for its commitment to excellence and patient satisfaction. Our track record of success speaks for itself, making us the premier choice for gynecomastia surgery in Mumbai.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-5'>
                                <div className='Liposuction-Surgery-Section-4-Image-con'>
                                    <img src={GynecomastiaBA} alt='Factors Affecting' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Liposuction-Surgery-Section-4-con'>
                <div className='container'>
                    <div className='Liposuction-Surgery-Section-4-Head'>
                        <h2 className='Head-Style-way'>Permanent Surgery for Gynecomastia in Mumbai </h2>
                    </div>

                    <div className='Liposuction-Surgery-Section-4-con'>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is the right age for Gynecomastia surgery?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Usually 18 years is the recommended age for surgery but in some cases patient can go for surgery before this age also.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        What is the success rate for Gynecomastia surgery?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        The result of Gynecomastia surgery are permanent. Taking care of self with diet and exercise will help sustain the results achieved.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How long does the surgery take?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Generally, the procedure takes 45 mins to 1 hr and once you are comfortable then we will discharge on the same day.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Do I have to stay in the hospital?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        It is a day care procedure so no admission or stay is required. Within few hours of the surgery you can yourself go back to your home comfortably spend the night in your home.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        How much time should I take off from work or school?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        This depends on the type of work you do and the extent of the male breast reduction procedure. Usually it's between 1 to 2 days, but healing time varies for each individual and should be discussed with your surgeon.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='direct-call-Liposuction-con'>
                <a href="tel:+91 8104560800">
                    Book Appointment
                </a>
            </div>

            <Footer />

        </>
    )
}

export default GynecomastiaSurgery