import React from 'react';
import "../Css/Doctor.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Footer from "../Components/Footer";
import Doctor01 from "../img/Doctor/doctor-1.jpg";
import Doctor02 from "../img/Doctor/doctor-2.jpg";
import Doctor03 from "../img/Doctor/doctor-3.jpg";
import Doctor04 from "../img/Doctor/doctor-4.jpg";
import { useLayoutEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

/////// Testimonial ////////
import { Link } from 'react-router-dom';
import DoctorData from '../Components/DoctorData';

/////// Testimonial ////////

import ShaziaAmin from "../img/Hospital/user.png";
import NikhilPadhiyar from "../img/Testimonial/Nikhil Padhiyar.png";
import AjinkyaRahate from "../img/Testimonial/Ajinkya Rahate.png";
import RG from "../img/Testimonial/R G.png";


const Doctor = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>
      {/* ---------------- Section 1 ---------------------- */}
      <section className='Doctor-Section-1'>
        <div className='Doctor-Section-con'>
          <div className='Doctor-Section-Text'>
            <h2>
              Doctor
            </h2>
            <div className='Doctor-Section-NavPage'>
              <span><Link to="/"><AiFillHome />Home</Link></span>
              <h3><MdKeyboardArrowRight /></h3>
              <h2>Doctor</h2>
            </div>
          </div>
        </div>
      </section>


      {/* ---------------- Section 2 ----------------------- */}

      <section
        id="doctors-3"
        className="bg-lightgrey wide-60 doctors-section division"
      >
        <div className="container">
          <div className="row">
            {
              DoctorData.map((elem) => {
                const { DoctorName, DoctorImage, DoctorSlug } = elem
                return (
                  <>
                    <div className="col-md-6 col-lg-3">
                      <div className="doctor-2">
                        <div className='DoctorImage-con'>
                          <div className="hover-overlay doctor-image-con">
                            <img
                              className="img-fluid"
                              src={DoctorImage}
                              alt={DoctorName}
                            />
                          </div>
                        </div>
                        <div className="doctor-meta">
                          <h5 className="h5-xs blue-color">{DoctorName}</h5>
                          {/* <span>Chief Medical Officer</span> */}
                          <Link
                            className="btn btn-sm btn-blue blue-hover mt-15"
                            to={DoctorSlug}
                          >
                            View More Info
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>
      </section>


      {/* ///////////////////////// Section testimonial ///////////////////// */}
      <section className="testimonial-area">
        <div className="container">
          <div className="sec-title text-center">
            <span className="sub-title">REVIEWS</span>
            <h2>What Our Clients Say</h2>
            <span className="divider">
              <svg viewBox="0 0 300.08 300.08">
                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
              </svg>
            </span>
          </div>
          <div className="testimonial-con">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={ShaziaAmin} alt="Shazia Amin" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          Treatment here is excellent.
                          Dr Ushma Patel mam is an excellent doctor very kind n caring, she explains everything very patiently and in a friendly manner.<br />
                          Staff they were helpful, and always smiling specially Dr Sushant Yadav, dr akshata, dr nasreen and nurses
                          Archna, rutuja, Rashmi, gaytri, Stella and pooja.<br />
                          Last but not least and importantly the cleaning staff for the hard work in keeping the hospital neat, clean, tidy and comfortable.<br />
                          Deeply appreciate the doctors and all the staff's who took care of my husband highly appreciated.<br />
                          I was sceptical since it’s a newly opened hospital. All service is very good, it's a best hospital in juhu. Highly recommended!
                        </p>
                      </div>
                      <div className="name">
                        <h3>Shazia Amin</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={NikhilPadhiyar} alt="Nikhil Padhiyar" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          Thunga Hospital exceeded my expectations in every aspect! The impeccable service, compassionate staff, and state-of-the-art facilities truly make it a standout healthcare provider. <br />
                          A special shoutout to Nurse Yamini for her exceptional help and outstanding behavior. Her dedication and kindness made my experience even more positive. I wholeheartedly recommend Thunga Hospital for their top-notch care. Five stars all the way!
                        </p>
                      </div>
                      <div className="name">
                        <h3>Nikhil Padhiyar</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={AjinkyaRahate} alt="Ajinkya Rahate" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          I had a wonderful experience. I was greeted by a care practitioner at the clinic who explained me about the causes , treatment options & its implications. Answered all my queries. Post which , I met Dr.Satish Shetty, he was very kind and caring. He..😊😊😊
                        </p>
                      </div>
                      <div className="name">
                        <h3>Ajinkya Rahate</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={ShaziaAmin} alt="Rupesh P" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          I would really recommend Thunga Hospital at Andheri West to patients looking for top class care & service.  I was admitted at Andheri Hospital for 5 days and I want to really thank all the Doctors, Staff, Nurses & Sisters, Pantry Staff and other staff working day & night to take care of patients.  A special thanks to Yamini & stella for taking good care of me.  Keep up the good work.
                          Doctors - Excellent,
                          Care - Excellent,
                          Cleaniness & Hygiene - Excellent,
                          Nurse & other staff - Excellent,
                          Food & Beverages - Excellent,
                          Highly recommended!,
                        </p>
                      </div>
                      <div className="name">
                        <h3>Rupesh P</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={RG} alt="R G" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          1. A very special thank you to Dr Ashwini Nabar who sucessfully operated on me with her team. She is very kind & compassionate. Very experienced in the field, is down to earth & made me feel comfortable & answered all my quieries patiently. Rare to find such doctors in today's times indeed.<br />
                          2. Dr Akshata & Dr Nasreen , thank you for answering all my queries patiently and for always being there & comforting me.<br />
                          3. Yamini nurse is genuinely very caring and served from the heart with love. Hastika , pranali & all the nurses , thank you for your care & patience.<br />
                          4. To all the cleaning staff , thank you for your personal & selfless help.<br />
                          5. Thank you Tejal for  expediting my discharge formalities efficiently.
                        </p>
                      </div>
                      <div className="name">
                        <h3>R G</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={ShaziaAmin} alt="Dinesh Waghela" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          My brother got operated in Thunga STH Hospital juhu. The staff is extremely friendly and helpful. The hospital maintains cleanliness and hygiene to ensure patients health. Overall our experience was excellent. Highly recommended.
                        </p>
                      </div>
                      <div className="name">
                        <h3>Dinesh Waghela</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-body">
                  <div className="owl-item">
                    <div className="single-testimonial-item text-center">
                      <div className="img-box">
                        <div className="img-holder">
                          <img src={ShaziaAmin} alt="Deepashri Parulekar" />
                        </div>
                        <div className="quote-box">
                          <i className="fa fa-quote-left" aria-hidden="true" />
                        </div>
                      </div>
                      <div className="text-holder">
                        <p id="style-1">
                          Excellent infrastructure, good clean rooms. Polite and friendly staff. Thank you Dr. Anuj Tiwari, Dr.Advika and sister Pranali and Rutuja for patiently treating our patient. The entire staff of Thunga STH Andheri hospital including the security guards made our stay comfortable and stress-free. A special mention to good and clean quality of food served to the patient.
                        </p>
                      </div>
                      <div className="name">
                        <h3>Deepashri Parulekar</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

          </div>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default Doctor;