import { React, useState } from "react";
import "../Css/NavBar.css";
import { Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import SpecialitiesData from "./SpecialitiesData";
import Logo from "../img/logo.png";
import { IoIosCall } from 'react-icons/io';
import { BiSolidAmbulance } from 'react-icons/bi';
const NavBar = () => {
  // Change Nav color  when scrolling
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  return (
    <>
      <header className="header-area">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3">
                <Navbar.Brand href="#home">
                  <Link to="/">
                    <div className="Logo-Container">
                      <img
                        src={Logo}
                        alt=""
                      />
                    </div>
                  </Link>
                </Navbar.Brand>
              </div>
              <div className="col-lg-4 col-md-8 d-none  d-md-block">
                <div className="header-cta">
                  <ul>
                    <li>
                      <i className="icon dripicons-mail" />
                      {/* <span>info@example.com</span> */}
                    </li>
                    <li>
                      <i className="icon dripicons-phone" />
                      {/* <span>+8 12 3456897</span> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-3 d-none d-lg-block">
                <div className="header-social text-right">
                  <div className="Navbar-Head">
                    <div className="Navbar-Head-btn-con tollfreebtn">
                      <a href="tel:02235244700">
                        <IoIosCall />
                        <div className="NavBar-head-icon-text">
                          <h4>Toll Free Number</h4>
                          <h5>022-35244700</h5>
                        </div>
                      </a>
                    </div>
                    <div className="Navbar-Head-btn-con Emergencybtn">
                      <a href="tel:02235244700">
                        <BiSolidAmbulance />
                        <div className="NavBar-head-icon-text">
                          <h4>Emergency</h4>
                          <h5>022-35244700/704</h5>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-bar-component">
          <div className="NavSection">
            <Navbar
              collapseOnSelect
              expand="lg"
              className={color ? "NavBar NavBar-bg" : "NavBar"}
            >
              <div className="container-fluid">
                <Navbar.Brand href="#home" className="NavLogo2">
                  <Link to="/">
                    <div className="Logo-Container">
                      <img
                        src={Logo}
                        alt=""
                      />
                    </div>
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="justify-content-center"
                >
                  <Nav>
                    <Nav.Link href="/"><Link to="/">Home</Link></Nav.Link>
                    <Nav.Link href="/hospital-in-andheri-west"><Link to="/hospital-in-andheri-west">About Us</Link></Nav.Link>
                    <button className="dropdownShow">
                      Specialities
                      <div className="dorpdown" id="style-1">
                        <div className="dropdown_list">
                          {
                            SpecialitiesData.map((ele) => {
                              const { ServiceName, ServiceSlug } = ele
                              return (
                                <>
                                  <Nav.Link href={ServiceSlug}>
                                    <Link to={ServiceSlug}>{ServiceName}</Link>
                                  </Nav.Link>
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    </button>
                    {/* <Link to="/Services">Centres and Specialities</Link> */}
                    <Nav.Link href="/Doctor"><Link to="/Doctor">Our Doctor</Link></Nav.Link>
                    <Nav.Link href="/PatientCare"><Link to="/PatientCare">Patient Care</Link></Nav.Link>
                    <Nav.Link href="/Internationalpt"><Link to="/Internationalpt">International Patients</Link></Nav.Link>
                    <Nav.Link href="/Facilities"><Link to="/Facilities">Facilities</Link></Nav.Link>
                    <Nav.Link href="/healthcheckup"><Link to="/healthcheckup">health checkup</Link></Nav.Link>
                    <Nav.Link href="/Gallery"><Link to="/Gallery">Gallery</Link></Nav.Link>
                    <button className="dropdownShow2">
                      Branches
                      <div className="dorpdown dorpdown2" id="style-1">
                        <div className="dropdown_list2">
                          <a href="https://www.thungahospital.com/mira-road.html" target="blank">Mira Road</a>
                          <a href="https://www.thungahospital.com/malad.html" target="blank">Malad</a>
                          <a href="https://www.thungahospital.com/boisar.html" target="blank">Boisar</a>
                        </div>
                      </div>
                    </button>
                    <Nav.Link href="/ContactUs"><Link to="/ContactUs">Contact Us</Link></Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
          </div>
        </div>

      </header>
    </>
  );
};

export default NavBar;
