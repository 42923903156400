import React from 'react';
import "../Css/Internationalpt.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import TungaHospital from "../img/Other/IMG_5622.JPG";
import TungaHospital2 from "../img/Hospital/entrance/entrance 06.JPG";
import TungaHospital3 from "../img/Hospital/entrance/entrance 11.JPG";
import TungaHospital4 from "../img/Hospital/entrance/entrance 05.JPG";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useLayoutEffect } from 'react';


/////// Testimonial ////////

import ShaziaAmin from "../img/Hospital/user.png";
import NikhilPadhiyar from "../img/Testimonial/Nikhil Padhiyar.png";
import AjinkyaRahate from "../img/Testimonial/Ajinkya Rahate.png";
import RG from "../img/Testimonial/R G.png";

///////////////////////////////////////////////////////////
import PRIVATEROOMS from "../img/Hospital/IMG_8909.JPG";
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';

import room01 from "../img/Other/IMG_5621.JPG";
import room02 from "../img/Hospital/Deluxe Room/Deluxe Room 01.JPG";
import PRIVATEDELUXESUITE from "../img/Hospital/Deluxe Room/Deluxe Room 05.JPG"
const Internationalpt = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='InternationalPt-Section-1'>
                <div className='InternationalPt-Section-con'>
                    <div className='InternationalPt-Section-Text'>
                        <h2>
                            International Patient
                        </h2>
                        <div className='InternationalPt-Section-NavPage'>
                            <span><Link to="/"><AiFillHome />Home</Link></span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>International Patient</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* ////////////////////////////////////////////////////////////// */}

            <section className="about section-padding bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="sub-title border-bot-light">Welcome to Tunga Hospital</div>
                            <div className='Hospital-Images-AboutUs-Section'>
                                <img src={TungaHospital} />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="section-title section-title-Why-India">Why India</div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className='section-title-Why-India-Data'>
                                        <p>
                                            <span>Internationally Recognized Expertise:</span> Our hospital Andheri West medical checkup boasts a team of highly skilled healthcare professionals who are internationally acclaimed for their expertise in various medical specialties.                                    </p>
                                        <p>
                                            <span>Cutting-edge Technology:</span> We invest in state-of-the-art medical technology and equipment, ensuring that our patients receive the most advanced and effective treatments available.                                    </p>
                                        <p>
                                            <span>Customized Care:</span> We understand the unique needs of our international patients. Our dedicated international patient services team is here to assist you with every aspect of your journey, from pre-arrival planning to post-treatment care.
                                        </p>
                                        <p>
                                            <span>Multi-lingual Support:</span> Communication is key, and we offer multi-lingual support to ensure that you feel at home while receiving treatment in a foreign land.
                                        </p>
                                        <p>
                                            <span>Cultural Sensitivity:</span> We respect and embrace the cultural diversity of our patients. Our hospital environment is designed to be culturally sensitive, making your experience comfortable and stress-free.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row g-3">
                                        <div className="col-6 text-end">

                                            <img
                                                className="img-fluid rounded w-75 wow zoomIn"
                                                alt="Best Hospital In Andheri"
                                                data-wow-delay="0.1s"
                                                src={TungaHospital4}
                                                style={{
                                                    marginTop: "25%",
                                                    visibility: "visible",
                                                    animationDelay: "0.1s",
                                                    animationName: "zoomIn"
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 text-start">

                                            <img
                                                className="img-fluid rounded w-100 wow zoomIn"
                                                alt="Multi speciality hospital in Andheri West"
                                                data-wow-delay="0.3s"
                                                src={TungaHospital2}
                                                style={{
                                                    visibility: "visible",
                                                    animationDelay: "0.3s",
                                                    animationName: "zoomIn"
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 text-end">

                                            <img
                                                className="img-fluid rounded w-50 wow zoomIn"
                                                alt="Hospital in Andheri West"
                                                data-wow-delay="0.5s"
                                                src={TungaHospital3}
                                                style={{
                                                    visibility: "visible",
                                                    animationDelay: "0.5s",
                                                    animationName: "zoomIn"
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 text-start">

                                            <img
                                                className="img-fluid rounded w-75 wow zoomIn"
                                                alt="Thunga STH Hospiatal in Andheri"
                                                data-wow-delay="0.7s"
                                                src={TungaHospital4}
                                                style={{
                                                    visibility: "visible",
                                                    animationDelay: "0.7s",
                                                    animationName: "zoomIn"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ----------------------- Section accommodation ---------------- */}

            <section className="feature-section">
                <div className="sec-title text-center">
                    <span className="sub-title">Accommodation</span>
                    <h2>Accommodation & Hotels for international patients</h2>
                    <span className="divider">
                        <svg viewBox="0 0 300.08 300.08">
                            <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                        </svg>
                    </span>
                </div>
                <div className="auto-container">
                    <div
                        className="row feature-row g-0 wow slideInUp animated"
                        data-wow-delay="100ms"
                        style={{
                            visibility: "visible",
                            animationDelay: "100ms",
                            animationName: "slideInUp"
                        }}
                    >
                        <div className="image-column col-lg-4">
                            <div className="inner-column">
                                <div className="image-box">
                                    <figure className="image overlay-anim">
                                        <img src={room01} alt="doppler test in andheri, mumbai" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="content-column col-lg-8">
                            <div className="inner-column">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="sub-title">For inpatients</span>
                                        <h2>Amenities</h2>
                                        <div className="text-con">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>3-bedded room</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>F& B Services</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Visitor’s chairs</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>24 hours nurses on duty</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Shared bathroom</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <figure className="image-2">
                                        <img src="images/resource/icon.png" alt="hemodialysis/ kidney dialysis in andheriha " />
                                    </figure> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row feature-row g-0 wow slideInUp animated"
                        data-wow-delay="200ms"
                        style={{
                            visibility: "visible",
                            animationDelay: "200ms",
                            animationName: "slideInUp"
                        }}
                    >
                        <div className="content-column col-lg-8">
                            <div className="inner-column">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="sub-title">Overview of Semi-Private room</span>
                                        <h2>Amenities</h2>
                                        <div className="text-con">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>2-bedded spacious room</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Air conditioned room</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Telephone</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Flat Screen TV</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>24 hours nurses on duty</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>F& B Services</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Couch next to each bed for the companion comfort</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Shared bathroom</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <figure className="image-2">
                                        <img src="images/resource/icon-2.png" alt="special health checkup packages in andheri" />
                                    </figure> */}
                                </div>
                            </div>
                        </div>
                        <div className="image-column col-lg-4">
                            <div className="inner-column">
                                <div className="image-box">
                                    <figure className="image overlay-anim">
                                        <img src={room02} alt="Semi private Room" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row feature-row g-0 wow slideInUp animated"
                        data-wow-delay="300ms"
                        style={{
                            visibility: "visible",
                            animationDelay: "300ms",
                            animationName: "slideInUp"
                        }}
                    >
                        <div className="image-column col-lg-4">
                            <div className="inner-column">
                                <div className="image-box">
                                    <figure className="image overlay-anim">
                                        <img src={PRIVATEROOMS} alt="PRIVATE ROOMS" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="content-column col-lg-8">
                            <div className="inner-column">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="sub-title">Overview of Private-rooms</span>
                                        <h2>Amenities</h2>
                                        <div className="text-con">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Flat Screen TV</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Wardrobe and good storage area</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Towel & bed sheets</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Air conditioned room</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Attached bathroom</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <figure className="image-2">
                                        <img src="images/resource/icon-3.png" alt="Endoscopy Centres in andheri" />
                                    </figure> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row feature-row g-0 wow slideInUp animated"
                        data-wow-delay="200ms"
                        style={{
                            visibility: "visible",
                            animationDelay: "200ms",
                            animationName: "slideInUp"
                        }}
                    >
                        <div className="content-column col-lg-8">
                            <div className="inner-column">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="sub-title">Private Deluxe Suite</span>
                                        <h2>Amenities</h2>
                                        <div className="text-con">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Flat screen TV</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Special housekeeping facilities</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Air conditioned room</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Separate bed for the companion</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>24 hours nurses on duty</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Telephone access</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Attached bathroom</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-square-o" aria-hidden="true" />
                                                    <span>Towels and bed sheets</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <figure className="image-2">
                                        <img src="images/resource/icon-2.png" alt="Private Deluxe Suite hospital in andheri" />
                                    </figure> */}
                                </div>
                            </div>
                        </div>
                        <div className="image-column col-lg-4">
                            <div className="inner-column">
                                <div className="image-box">
                                    <figure className="image overlay-anim">
                                        <img src={PRIVATEDELUXESUITE} alt="Private Deluxe Suite hospital" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ///////////////////////// Section testimonial ///////////////////// */}
            <section className="testimonial-area">
                <div className="container">
                    <div className="sec-title text-center">
                        <span className="sub-title">REVIEWS</span>
                        <h2>What Our Clients Say</h2>
                        <span className="divider">
                            <svg viewBox="0 0 300.08 300.08">
                                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                            </svg>
                        </span>
                    </div>
                    <div className="testimonial-con">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="testimonial-body">
                                    <div className="owl-item">
                                        <div className="single-testimonial-item text-center">
                                            <div className="img-box">
                                                <div className="img-holder">
                                                    <img src={ShaziaAmin} alt="Shazia Amin" />
                                                </div>
                                                <div className="quote-box">
                                                    <i className="fa fa-quote-left" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <p id="style-1">
                                                    Treatment here is excellent.
                                                    Dr Ushma Patel mam is an excellent doctor very kind n caring, she explains everything very patiently and in a friendly manner.<br />
                                                    Staff they were helpful, and always smiling specially Dr Sushant Yadav, dr akshata, dr nasreen and nurses
                                                    Archna, rutuja, Rashmi, gaytri, Stella and pooja.<br />
                                                    Last but not least and importantly the cleaning staff for the hard work in keeping the hospital neat, clean, tidy and comfortable.<br />
                                                    Deeply appreciate the doctors and all the staff's who took care of my husband highly appreciated.<br />
                                                    I was sceptical since it’s a newly opened hospital. All service is very good, it's a best hospital in juhu. Highly recommended!
                                                </p>
                                            </div>
                                            <div className="name">
                                                <h3>Shazia Amin</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-body">
                                    <div className="owl-item">
                                        <div className="single-testimonial-item text-center">
                                            <div className="img-box">
                                                <div className="img-holder">
                                                    <img src={NikhilPadhiyar} alt="Nikhil Padhiyar" />
                                                </div>
                                                <div className="quote-box">
                                                    <i className="fa fa-quote-left" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <p id="style-1">
                                                    Thunga Hospital exceeded my expectations in every aspect! The impeccable service, compassionate staff, and state-of-the-art facilities truly make it a standout healthcare provider. <br />
                                                    A special shoutout to Nurse Yamini for her exceptional help and outstanding behavior. Her dedication and kindness made my experience even more positive. I wholeheartedly recommend Thunga Hospital for their top-notch care. Five stars all the way!
                                                </p>
                                            </div>
                                            <div className="name">
                                                <h3>Nikhil Padhiyar</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-body">
                                    <div className="owl-item">
                                        <div className="single-testimonial-item text-center">
                                            <div className="img-box">
                                                <div className="img-holder">
                                                    <img src={AjinkyaRahate} alt="Ajinkya Rahate" />
                                                </div>
                                                <div className="quote-box">
                                                    <i className="fa fa-quote-left" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <p id="style-1">
                                                    I had a wonderful experience. I was greeted by a care practitioner at the clinic who explained me about the causes , treatment options & its implications. Answered all my queries. Post which , I met Dr.Satish Shetty, he was very kind and caring. He..😊😊😊
                                                </p>
                                            </div>
                                            <div className="name">
                                                <h3>Ajinkya Rahate</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-body">
                                    <div className="owl-item">
                                        <div className="single-testimonial-item text-center">
                                            <div className="img-box">
                                                <div className="img-holder">
                                                    <img src={ShaziaAmin} alt="Rupesh P" />
                                                </div>
                                                <div className="quote-box">
                                                    <i className="fa fa-quote-left" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <p id="style-1">
                                                    I would really recommend Thunga Hospital at Andheri West to patients looking for top class care & service.  I was admitted at Andheri Hospital for 5 days and I want to really thank all the Doctors, Staff, Nurses & Sisters, Pantry Staff and other staff working day & night to take care of patients.  A special thanks to Yamini & stella for taking good care of me.  Keep up the good work.
                                                    Doctors - Excellent,
                                                    Care - Excellent,
                                                    Cleaniness & Hygiene - Excellent,
                                                    Nurse & other staff - Excellent,
                                                    Food & Beverages - Excellent,
                                                    Highly recommended!,
                                                </p>
                                            </div>
                                            <div className="name">
                                                <h3>Rupesh P</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-body">
                                    <div className="owl-item">
                                        <div className="single-testimonial-item text-center">
                                            <div className="img-box">
                                                <div className="img-holder">
                                                    <img src={RG} alt="R G" />
                                                </div>
                                                <div className="quote-box">
                                                    <i className="fa fa-quote-left" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <p id="style-1">
                                                    1. A very special thank you to Dr Ashwini Nabar who sucessfully operated on me with her team. She is very kind & compassionate. Very experienced in the field, is down to earth & made me feel comfortable & answered all my quieries patiently. Rare to find such doctors in today's times indeed.<br />
                                                    2. Dr Akshata & Dr Nasreen , thank you for answering all my queries patiently and for always being there & comforting me.<br />
                                                    3. Yamini nurse is genuinely very caring and served from the heart with love. Hastika , pranali & all the nurses , thank you for your care & patience.<br />
                                                    4. To all the cleaning staff , thank you for your personal & selfless help.<br />
                                                    5. Thank you Tejal for  expediting my discharge formalities efficiently.
                                                </p>
                                            </div>
                                            <div className="name">
                                                <h3>R G</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-body">
                                    <div className="owl-item">
                                        <div className="single-testimonial-item text-center">
                                            <div className="img-box">
                                                <div className="img-holder">
                                                    <img src={ShaziaAmin} alt="Dinesh Waghela" />
                                                </div>
                                                <div className="quote-box">
                                                    <i className="fa fa-quote-left" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <p id="style-1">
                                                    My brother got operated in Thunga STH Hospital juhu. The staff is extremely friendly and helpful. The hospital maintains cleanliness and hygiene to ensure patients health. Overall our experience was excellent. Highly recommended.
                                                </p>
                                            </div>
                                            <div className="name">
                                                <h3>Dinesh Waghela</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-body">
                                    <div className="owl-item">
                                        <div className="single-testimonial-item text-center">
                                            <div className="img-box">
                                                <div className="img-holder">
                                                    <img src={ShaziaAmin} alt="Deepashri Parulekar" />
                                                </div>
                                                <div className="quote-box">
                                                    <i className="fa fa-quote-left" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <p id="style-1">
                                                    Excellent infrastructure, good clean rooms. Polite and friendly staff. Thank you Dr. Anuj Tiwari, Dr.Advika and sister Pranali and Rutuja for patiently treating our patient. The entire staff of Thunga STH Andheri hospital including the security guards made our stay comfortable and stress-free. A special mention to good and clean quality of food served to the patient.
                                                </p>
                                            </div>
                                            <div className="name">
                                                <h3>Deepashri Parulekar</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}

export default Internationalpt