import React, { useState } from 'react';
import "../Css/Liposuction.css";
import Logo from "../img/logo.png";
import LiposuctionTreatment from "../img/Liposuction-Treatment.png";
import $ from "jquery";
import Fade from 'react-reveal/Fade';
import { LiaTruckMovingSolid, LiaHeadphonesSolid } from 'react-icons/lia';
import { PiCurrencyDollarLight } from 'react-icons/pi';
import { CiDiscount1 } from 'react-icons/ci';
import liposuctionimages from '../img/liposuction images.jpeg';
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";

import FactorsAffecting from "../img/lipo-10things-feature.jpg"
import Footer from '../Components/Footer';
const Liposuction = () => {

    // State variables
    const [formData, setFormData] = useState({
        custName: '',
        custMail: '',
        custContact: '',
        custDisease: '',
        custMassage: '',
    });

    // Validation state variables
    const [errors, setErrors] = useState({
        custName: '',
        custMail: '',
        custContact: '',
        custDisease: '',
    });

    // Destructuring form data
    const { custName, custMail, custContact, custDisease, custMassage } = formData;

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic form validation
        const newErrors = {};

        if (!custName.trim()) {
            newErrors.custName = 'Please enter the Name.';
        }

        if (!custMail.trim()) {
            newErrors.custMail = 'Please enter the Mail ID';
        }

        if (!custContact.trim()) {
            newErrors.custContact = 'Please enter the phone number.';
        }

        if (!custDisease.trim()) {
            newErrors.custDisease = 'Please enter the Disease.';
        }

        // Update validation errors
        setErrors(newErrors);

        // If there are no errors, you can proceed with form submission logic
        if (Object.keys(newErrors).length === 0) {
            // Your form submission logic here

            const body =
                '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:red">THUNGA STH HOSPITAL ENQUIRY</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#2c4893"><strong>Name:</strong></td><td style="text-align:left">' +
                custName +
                '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Contact:</strong></td><td style="text-align:left">' +
                custContact +
                '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Email:</strong></td><td style="text-align:left">' +
                custMail +
                '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Disease:</strong></td><td style="text-align:left">' +
                custDisease +
                '</td></tr><tr><td style="text-align:left;color:#2c4893"><strong>Massage:</strong></td><td style="text-align:left">' +
                custMassage +
                '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at THUNGA STH HOSPITAL</p></div></body></html>';

            // Send the POST request
            $.post(
                'https://skdm.in/server/v1/send_lead_mail.php',
                {
                    toEmail: 'kalyanipandirkar85@gmail.com',
                    fromEmail: 'skdmlead@gmail.com',
                    bccMail: 'skdmlead@gmail.com',
                    mailSubject: 'New Customer Lead',
                    mailBody: body,
                    accountName: 'Sai Tunga',
                    accountLeadSource: 'https://mmmak.in/',
                    accountLeadName: custName,
                },
                function (dataa, status) {
                    console.log('data :' + dataa);
                }
            );

            // Display success message
            alert("Your Form has Submitted. Our team will contact you soon.");
        }
    }


    return (
        <>
            <section className='Liposuction-Section-1'>
                <div className='container'>
                    <div className='Liposuction-Head-con'>
                        <div className='Liposuction-head-image-con'>
                            <img src={Logo} alt='' />
                        </div>
                        <div>
                            <h1>Liposuction Surgery in Andheri</h1>
                        </div>
                    </div>
                    <div className='Liposuction-Surgery-Image-and-form-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='Liposuction-Surgery-Form-Main-con'>
                                    <Fade right cascade>
                                        <div className="contact-section-wrapper ttm-bgcolor-grey res-767-mb-15">
                                            <div className="spacing-10">
                                                {/* section title */}
                                                <div className="section-title with-desc clearfix">
                                                    <div className="title-header">
                                                        <h5 className="ttm-textcolor-skincolor">ENQUIRY</h5>
                                                        <h2 className="title">DO YOU HAVE ANY QUESTIONS?</h2>
                                                    </div>
                                                </div>
                                                {/* section title end */}
                                                <form
                                                    id="ttm-quote-form"
                                                    className="row ttm-quote-form clearfix"
                                                    method="post"
                                                    action="#"
                                                    onSubmit={handleSubmit}
                                                >
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                name="custName"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Your Name*"
                                                                required="required"
                                                                value={custName}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.custName && <p className="error-message">{errors.custName}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                name="custContact"
                                                                type="text"
                                                                placeholder="Your Phone*"
                                                                required="required"
                                                                className="form-control"
                                                                value={custContact}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.custContact && <p className="error-message">{errors.custContact}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                name="custMail"
                                                                type="email"
                                                                placeholder="Email Address*"
                                                                required="required"
                                                                className="form-control"
                                                                value={custMail}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.custMail && <p className="error-message">{errors.custMail}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                name="custDisease"
                                                                type="text"
                                                                placeholder="Disease"
                                                                required="required"
                                                                className="form-control"
                                                                value={custDisease}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.custDisease && <p className="error-message">{errors.custDisease}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <textarea
                                                                name="custMassage"
                                                                rows={4}
                                                                placeholder="Massage"
                                                                required="required"
                                                                className="form-control"
                                                                value={custMassage}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-50 res-767-mb-0">
                                                        <button type="submit" className="btn-title contactbtn">
                                                            Send Message
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='Liposuction-Surgery-Form-Image-con'>
                                    <div className='Liposuction-Surgery-Form-Image'>
                                        <img src={LiposuctionTreatment} alt='' />
                                    </div>
                                </div>
                                <div className='Liposuction-Surgery-Form-Info'>
                                    <p>
                                        Welcome to Thunga STH Hospital, your trusted destination for premium healthcare services in Andheri. Are you struggling to achieve your desired body shape despite rigorous dieting and exercise? Our advanced liposuction surgery in Andheri offers a solution to eliminate stubborn fat deposits and sculpt your body contours effectively.
                                    </p>
                                    <p>
                                        At Thunga STH Hospital, our liposuction surgery in Andheri is performed by skilled surgeons using state-of-the-art techniques and equipment. Whether you're aiming to refine your waistline, slim down your thighs, or sculpt your abdomen, our personalized approach ensures optimal results tailored to your unique needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ------------------- Section 6 -------------------- */}

            <section className='Home-Section-6'>
                <div className='container'>
                    <div className='Home-Section-6-con'>
                        <div className='row'>
                            <div className='col-md-3 col-sm-6 col-12 HomeSection6col'>
                                <div className='Home-Section-6-card-body'>
                                    <div className='Home-Section-6-card-icons'>
                                        <h2>40+</h2>
                                    </div>
                                    <div className='Home-Section-6-card-details'>
                                        <h2>Satisfied Patients</h2>
                                        {/* <h3>Orders from all item</h3> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-12 HomeSection6col'>
                                <div className='Home-Section-6-card-body'>
                                    <div className='Home-Section-6-card-icons'>
                                        <h2>40+</h2>
                                    </div>
                                    <div className='Home-Section-6-card-details'>
                                        <h2>Specialist Doctors</h2>
                                        {/* <h3>Money back guarantee</h3> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-12 HomeSection6col'>
                                <div className='Home-Section-6-card-body'>
                                    <div className='Home-Section-6-card-icons'>
                                        <h2>20+</h2>
                                    </div>
                                    <div className='Home-Section-6-card-details'>
                                        <h2>Surgery Centers</h2>
                                        {/* <h3>Onevery order over $140.00</h3> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-12 HomeSection6col'>
                                <div className='Home-Section-6-card-body'>
                                    <div className='Home-Section-6-card-icons'>
                                        <h2>5+</h2>
                                    </div>
                                    <div className='Home-Section-6-card-details'>
                                        <h2>Cities</h2>
                                        {/* <h3>Contact us 24 hours a day</h3> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Liposuction-Surgery-Section-3-con'>
                <div className='container'>
                    <div className='Liposuction-Surgery-Section-3-Head'>
                        <h2>Benefits of Liposuction Surgery in Andheri:</h2>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='Liposuction-Surgery-Section-3-Image-con'>
                                <div className='Liposuction-Surgery-Section-3-Image'>
                                    <img src={liposuctionimages} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='Liposuction-Surgery-Section-3-Info'>
                                <p>
                                    <strong>1. Targeted Fat Removal: </strong> Say goodbye to unwanted fat in specific areas such as the abdomen, hips, thighs, arms, and more with liposuction surgery in Andheri.
                                </p>
                                <p>
                                    <strong>2. Enhanced Body Contours :</strong> Achieve a smoother, more defined silhouette that complements your natural body shape through liposuction surgery in Andheri.
                                </p>
                                <p>
                                    <strong>3. Minimal Downtime: </strong> Our advanced techniques minimize post-surgery discomfort and recovery time, allowing you to resume your daily activities sooner after liposuction surgery in Andheri.
                                </p>
                                <p>
                                    <strong>4. Boosted Confidence:</strong> Enjoy renewed self-assurance and pride in your appearance with the transformative results of liposuction surgery in Andheri.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Liposuction-Surgery-Section-4-con'>
                <div className='container'>
                    <div className='Liposuction-Surgery-Section-4-Head'>
                        <h2 className='Head-Style-way'>Why Choose Thunga STH Hospital for Liposuction Surgery in Andheri? </h2>
                    </div>

                    <div className='Liposuction-Surgery-Section-4-Details'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='Liposuction-Surgery-Section-4-Info-con'>
                                    <div className='Liposuction-Surgery-Section-4-Info'>
                                        <p>Here are the factors that affect the cost of liposuction surgery in Andheri -</p>
                                        <ul>
                                            <li>Our experienced surgeons specialize in cosmetic procedures and are dedicated to delivering safe, effective results with liposuction surgery in Andheri.</li>
                                            <li>We prioritize patient safety and comfort, with cutting-edge technology and modern amenities to support your journey to a better you with liposuction surgery in Andheri.</li>
                                            <li> From initial consultation to post-operative follow-up, our team provides personalized attention and support at every step of your liposuction journey in Andheri.</li>
                                            <li>Thunga STH Hospital is renowned for its commitment to excellence in healthcare, earning the trust of patients across Andheri and beyond with liposuction surgery in Andheri.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-5'>
                                <div className='Liposuction-Surgery-Section-4-Image-con'>
                                    <img src={FactorsAffecting} alt='Factors Affecting' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Liposuction-Surgery-Section-4-con'>
                <div className='container'>
                    <div className='Liposuction-Surgery-Section-4-Head'>
                        <h2 className='Head-Style-way'>Factors Affecting Liposuction Cost in Andheri </h2>
                    </div>

                    <div className='Liposuction-Surgery-Section-4-con'>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Does health insurance cover liposuction surgery cost in Andheri
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        No, liposuction surgery is performed mainly for cosmetic reasons. Thus, it is not covered by health insurance. You will have to bear the cost of the procedure from your own pockets.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How much does it cost to get fat removed from different body parts?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>The cost of fat removal surgery for different parts varies as below-</strong>
                                        <ul>
                                            <li>Abdomen- Rs. 1,35,000 approx.</li>
                                            <li>Thigh- Rs. 1,20,000 approx.</li>
                                            <li>Hips- Rs. 70,000 approx.</li>
                                            <li>Arm- Rs. 55,000 approx.</li>
                                            <li>Neck- Rs. 40,000 approx.</li>
                                            <li>Chin- Rs. 30,000 approx.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Do I have to pay for post-surgery consultations with Thunga STH Hospital doctors?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        No, post-surgery consultations with Thunga STH Hospital doctors are free of cost. You can take multiple follow-ups and connect with the doctors as many times as you want without worrying about the fee.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What is the cost of different types of liposuction?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>The cost of different types of liposuction techniques is given below-</strong>
                                        <ul>
                                            <li>Tumescent liposuction- Rs. 25,000 to Rs. 1,00,000 approx.</li>
                                            <li>Power-assisted liposuction- Rs. 35,000 to Rs. 1,40,000 approx.</li>
                                            <li>Laser-assisted liposuction- Rs. 50,000 to Rs. 1,80,000 approx.</li>
                                            <li>Ultrasound-assisted liposuction- Rs. 50,000 to Rs. 2,10,000 approx.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Can I avail No-Cost EMI service for liposuction at Thunga STH Hospital?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, you can avail No-Cost EMI service at Thunga STH Hospital for liposuction and other treatments as well. The service will allow you to divide the treatment expense into easily payable installments.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Liposuction-Surgery-Section-5-con'>
                <div className='container'>
                    <div className='Liposuction-Surgery-Section-4-Head'>
                        <h2 className='Head-Style-way'>Liposuction Surgery Cost Break Up</h2>
                    </div>
                    <div className='Liposuction-Surgery-Section-table-Head'>
                        <table>
                            <tr>
                                <td>Pre-Ops Investigations</td>
                                <td>15000</td>
                                <td>22500</td>
                                <td>30,000</td>
                            </tr>
                            <tr>
                                <td>Consumables and Hospital OT fees</td>
                                <td>40,000</td>
                                <td>70000</td>
                                <td>100,000</td>
                            </tr>
                            <tr>
                                <td>Surgeon fees</td>
                                <td>50,000</td>
                                <td>75000</td>
                                <td>100,000</td>
                            </tr>
                            <tr>
                                <td>Customized compression garment</td>
                                <td>10,000</td>
                                <td>15000</td>
                                <td>20,000</td>
                            </tr>
                            <tr>
                                <td>Specialized equipment for Liposuction</td>
                                <td>25,000</td>
                                <td>37500</td>
                                <td>50,000</td>
                            </tr>
                            <tr>
                                <td>ICU charges (If needed)</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </section>

            <section className='Liposuction-Surgery-Section-5-con'>
                <div className='container'>
                    <div className='Liposuction-Surgery-Section-4-Head'>
                        <h2 className='Head-Style-way'>Thunga STH Hospital vs Others</h2>
                    </div>
                    <div className='Liposuction-Surgery-Section-table-Head'>
                        <table>
                            <tr>
                                <th>Benefits</th>
                                <th>Thunga STH Hospital</th>
                                <th>Others</th>
                            </tr>
                            <tr>
                                <td>Free Follow-up Consultation</td>
                                <td className='right-btn'><GiCheckMark /></td>
                                <td className='cross-btn'><RxCross2 /></td>
                            </tr>
                            <tr>
                                <td>24x7 Care Coordinator</td>
                                <td className='right-btn'><GiCheckMark /></td>
                                <td className='cross-btn'><RxCross2 /></td>
                            </tr>
                            <tr>
                                <td>No Cost EMI</td>
                                <td className='right-btn'><GiCheckMark /></td>
                                <td className='cross-btn'><RxCross2 /></td>
                            </tr>
                            <tr>
                                <td>Pickup & Drop Services</td>
                                <td className='right-btn'><GiCheckMark /></td>
                                <td className='cross-btn'><RxCross2 /></td>
                            </tr>
                            <tr>
                                <td>Hospital Duration</td>
                                <td className='right-btn'>Short</td>
                                <td className='cross-btn'>Long</td>
                            </tr>
                            <tr>
                                <td>Minimum Paper Work</td>
                                <td className='right-btn'><GiCheckMark /></td>
                                <td className='cross-btn'><RxCross2 /></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </section>

            <div className='direct-call-Liposuction-con'>
                <a href="tel:+91 8104560800">
                    Book Appointment
                </a>
            </div>

            <Footer />
        </>
    )
}

export default Liposuction