import React from 'react';
import "../Css/Facilities.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaLongArrowAltRight } from 'react-icons/fa';
import Footer from "../Components/Footer";
import { Link } from 'react-router-dom';
import FacilitiesData from '../Components/FacilitiesData';
import { useLayoutEffect } from 'react';

const Facilities = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='Facilities-Section-1'>
                <div className='Facilities-Section-con'>
                    <div className='Facilities-Section-Text'>
                        <h2>
                            Facilities
                        </h2>
                        <div className='Facilities-Section-NavPage'>
                            <span><Link to="/"><AiFillHome />Home</Link></span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>Facilities</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* ------------- Section 2 ----------------- */}
            <section className='Facilities-Section-2'>
                <div className='container'>
                    <div className='row'>
                        {
                            FacilitiesData.map((elem) => {
                                const { FacilitiesName, FacilitiesMaindes, FacilitiesImage, FacilitiesSlug } = elem
                                return (
                                    <>
                                        <div className='col-md-4'>
                                            <div className='Facilities-Card-Body-Con'>
                                                <div className='Facilities-Card-Body'>
                                                    <div className='Facilities-Card-Image-con'>
                                                        <div className='Facilities-Card-Image'>
                                                            <img src={FacilitiesImage} alt='Multi speciality hospital in Andheri West' />
                                                        </div>
                                                    </div>
                                                    <div className='Facilities-Card-Details'>
                                                        <h2>{FacilitiesName}</h2>
                                                        <p>{FacilitiesMaindes}</p>
                                                    </div>
                                                    <div className='Facilities-Card-Btn'>
                                                        <Link to={FacilitiesSlug}>
                                                            <button>
                                                                <FaLongArrowAltRight />
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Facilities