import React from 'react';
import "../Css/ServiceDetail.css";
import { AiFillHome } from 'react-icons/ai';
import { BsHeartPulse } from 'react-icons/bs';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import SpecialitiesData from '../Components/SpecialitiesData';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import DoctorData from "../Components/DoctorData";
import Footer from '../Components/Footer';
import { MetaTags } from 'react-meta-tags';

const ServiceDetail = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const search = useLocation();
    const Path = search.pathname;

    const [serviceInfo, setServiceInfo] = useState(SpecialitiesData);
    const ServiceDataFinal = serviceInfo.find(e => e.ServiceSlug == Path)


    return (
        <>
            <MetaTags>
                <title>{ServiceDataFinal.MetaTitle}</title>
                <meta title={ServiceDataFinal.MetaTitle} />
                <meta name="description" content="Top-rated Andheri West Hospital, Thunga offers 24/7 care, 20+ specialties, & expert doctors. Find your treatment near you. Book online!" />
                <meta name="keywords" content="Multi speciality hospital in Andheri West,Hospital in Andheri West,Emergency Ambulance Services in Andheri West,accident and emergency service in andheri,diagnostic centres In andheri,pathology and diagnostic centre in andheri,Digital X-Ray / Portable X-Ray In andheri,Sonography test in andheri,doppler test in andheri, mumbai,hemodialysis/ kidney dialysis in andheri,special health checkup packages in andheri,Endoscopy Centres in andheri,Blood bank Hospital in andheri,Best Cardiac Hospital in Andheri,Best Orthopedic Hospitals in Andheri,Best Gynecology Hospitals in Andheri,Pediatric hospital in Andheri,Top Neurosurgery Hospital in Andheri,Nephrologists in Andheri,Haematology Hospital in Andheri,General Physicians in Andheri,Best Pulmonology Hospitals in Andheri,Vascular Surgery Hospitals in Andheri,Laparoscopic Surgery in Andheri,Best Gastroenterology Hospitals in Andheri,Best Hospital for Cancer Surgery in Andheri,Best ENT Hospitals in Andheri,Diabetic Foot Care Hospital in Andheri,Cosmetic Surgery Clinics in Andheri,Best Dermatology Hospital in Andheri," />
                <link rel="canonical" href="https://thungasthhospital.com/hospital-in-andheri-west" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content={ServiceDataFinal.MetaTitle} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://thungasthhospital.com/" />
                <meta property="og:description" content="Top-rated Andheri West Hospital, Thunga offers 24/7 care, 20+ specialties, & expert doctors. Find your treatment near you. Book online!" />
                <meta property="og:image" content="" />
            </MetaTags>

            {/* ---------------- Section 1 ---------------------- */}
            <section className='ServiceDetails-Section-1'>
                <div className='ServiceDetails-Section-con'>
                    <div className='ServiceDetails-Section-Text'>
                        <h1>
                            {ServiceDataFinal.HeadTag}
                        </h1>
                        <div className='ServiceDetails-Section-NavPage'>
                            <span><Link to="/"><AiFillHome />Home</Link></span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2> {ServiceDataFinal.ServiceName}</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 2 ---------------------- */}
            <section className="Service-Section-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="Service-Section2-Details-con">
                                <div className="Service-Section2-Details1-con">
                                    <h1>{ServiceDataFinal.ServiceName}</h1>

                                    <p>
                                        {ServiceDataFinal.ServiceHeadPara}
                                    </p>
                                </div>
                                <div className="Service-Section2-Detail2-con">
                                    <h3>{ServiceDataFinal.ServiceHead01}</h3>
                                    <p>
                                        {ServiceDataFinal.ServicePara01}
                                    </p>
                                    <p>
                                        {ServiceDataFinal.ServicePara02}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="Service-Section-Details-right-con">
                                <div className="Service-Section-Details-right-Heading">
                                    <h2>
                                        Exceptional Multispecialty Hospital Services for Comprehensive
                                        Care
                                    </h2>
                                </div>
                                <div className="Department-Section-Details-right" id="style-1">
                                    {
                                        SpecialitiesData.map((ele) => {
                                            const { ServiceName, ServiceSlug } = ele
                                            return (
                                                <>
                                                    <p>
                                                        <Link to={ServiceSlug}>{ServiceName}</Link>
                                                    </p>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* ////////////// Section 3 ////////////////// */}

            <section className='Service-Details-Section-3'>
                <div className='container'>
                    <p>
                        {ServiceDataFinal.ServicePara03}
                    </p>
                    <p>
                        {ServiceDataFinal.ServicePara04}
                    </p>
                    <p>
                        {ServiceDataFinal.ServicePara05}
                    </p>
                </div>
            </section>


            <section className='Service-Details-Section-3'>
                <div className='container'>
                    <div className='service-Details-con'>
                        <h3>{ServiceDataFinal.ServicePointHead}</h3>
                        <h4>{ServiceDataFinal.ServicePointHead01}</h4>
                        <p>{ServiceDataFinal.ServicePointPara01}</p>
                        <h4>{ServiceDataFinal.ServicePointHead02}</h4>
                        <p>{ServiceDataFinal.ServicePointPara02}</p>
                        <h4>{ServiceDataFinal.ServicePointHead03}</h4>
                        <p>{ServiceDataFinal.ServicePointPara03}</p>
                        <h4>{ServiceDataFinal.ServicePointHead04}</h4>
                        <p>{ServiceDataFinal.ServicePointPara04}</p>
                        <h4>{ServiceDataFinal.ServicePointHead05}</h4>
                        <p>{ServiceDataFinal.ServicePointPara05}</p>
                        <h4>{ServiceDataFinal.ServicePointHead06}</h4>
                        <p>{ServiceDataFinal.ServicePointPara06}</p>
                        <h4>{ServiceDataFinal.ServicePointHead07}</h4>
                        <p>{ServiceDataFinal.ServicePointPara07}</p>
                    </div>
                </div>
            </section>

            {/* ////////////// Section 4 ////////////////// */}

            <section className='Service-Details-Section-4'>
                <div className='container'>
                    <div class="section-title col-12">
                        <div className="sec-title text-center">
                            <span className="sub-title">OUR SPECIALIST</span>
                            <h2>We Care Our Patients.</h2>
                            <span className="divider">
                                <svg viewBox="0 0 300.08 300.08">
                                    <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                                </svg>
                            </span>
                        </div>
                    </div>

                    <div className='Service-details-Doctor-com'>

                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            // autoplay={{
                            //   delay: 2500,
                            //   disableOnInteraction: false,
                            // }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 50,
                                },
                            }}
                            // modules={[Autoplay]}
                            className="mySwiper"
                        >
                            {
                                DoctorData.map((elem) => {

                                    const { DoctorName, DoctorImage, DoctorSpecialist, DoctorEducation, DoctorExperience } = elem
                                    return (
                                        <>
                                            <SwiperSlide>
                                                <div className="doctorPage">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="doctor_detail">
                                                                <div className="doctor-img">
                                                                    <img
                                                                        src={DoctorImage}
                                                                        alt={DoctorName}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 border-start">
                                                            <div className="doctor_desc">
                                                                <h2>{DoctorName}</h2>
                                                                <h5>
                                                                    <span>Qualifaction :</span> {DoctorEducation}
                                                                </h5>
                                                                <h6>
                                                                    <span>Department :</span> {DoctorSpecialist}
                                                                </h6>
                                                                <h6>
                                                                    <span>Experience :</span> {DoctorExperience}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ServEnquiry">
                                                        <Link to="/ContactUs">
                                                        <button type="button" className="btn btn-primary">
                                                            Enquire Now
                                                        </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })
                            }
                        </Swiper>
                        <div class="row"><div class="col-md-12 text-center"><div class="all-doctors mb-40"><Link to="/Doctor" class="btn btn-blue blue-hover" href="/themes/html/reactjs/medservices/all-doctors">Meet All Doctors</Link></div></div></div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ServiceDetail