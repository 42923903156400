import React from 'react';
import "../Css/HealthCheckup.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Footer from "../Components/Footer";
import { Link } from 'react-router-dom';
import { useLayoutEffect } from 'react';

const HealthCheckup = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='HealthCheckup-Section-1'>
                <div className='HealthCheckup-Section-con'>
                    <div className='HealthCheckup-Section-Text'>
                        <h2>
                            Health Checkup
                        </h2>
                        <div className='HealthCheckup-Section-NavPage'>
                            <span><Link to="/"><AiFillHome />Home</Link></span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>Health Checkup</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 2 ---------------------- */}

            <section className='HealthCheckup-Section-2'>
                <div className='container'>
                    <div className="sec-title text-center">
                        <span className="sub-title">Health Checkup</span>
                        <h2>Healthcare & Wellness Program at Thunga STH Hospital</h2>
                        <span className="divider">
                            <svg viewBox="0 0 300.08 300.08">
                                <path d="m293.26 184.14h-82.877l-12.692-76.138c-.546-3.287-3.396-5.701-6.718-5.701-.034 0-.061 0-.089 0-3.369.027-6.199 2.523-6.677 5.845l-12.507 87.602-14.874-148.69c-.355-3.43-3.205-6.056-6.643-6.138-.048 0-.096 0-.143 0-3.39 0-6.274 2.489-6.752 5.852l-19.621 137.368h-9.405l-12.221-42.782c-.866-3.028-3.812-5.149-6.8-4.944-3.13.109-5.777 2.332-6.431 5.395l-8.941 42.332h-73.049c-3.771 0-6.82 3.049-6.82 6.82 0 3.778 3.049 6.82 6.82 6.82h78.566c3.219 0 6.002-2.251 6.67-5.408l4.406-20.856 6.09 21.313c.839 2.939 3.526 4.951 6.568 4.951h20.46c3.396 0 6.274-2.489 6.752-5.845l12.508-87.596 14.874 148.683c.355 3.437 3.205 6.056 6.643 6.138h.143c3.39 0 6.274-2.489 6.752-5.845l14.227-99.599 6.397 38.362c.546 3.287 3.396 5.702 6.725 5.702h88.66c3.771 0 6.82-3.049 6.82-6.82-.001-3.772-3.05-6.821-6.821-6.821z" />
                            </svg>
                        </span>
                    </div>
                    <div className='HealthCheckup-First-contain'>
                        <h3>
                            Your Health is our concern
                        </h3>
                        <p>
                            Welcome to Thunga STH hospitals Andheri West medical checkup, where your health and well-being are at the forefront of everything we do. Our dedicated team of medical professionals is committed to providing comprehensive health checkup services to help you maintain and improve your overall health.
                        </p>
                        <p>
                            We, at Thunga STH Hospital Andheri West believes in "Prevention is better than cure" and in our hospital we have developed some health check programmes which have been designed by our expert panel of doctors to address the physiological systems most commonly affected by stress, lack of physical activities or due to unhealthy food habits. It is our aim to diagnose the irregularities at the earliest and render valuable medical advice which will keep you healthy.
                        </p>
                        <p>
                            Your health is our top priority in the Andheri West medical checkup centre. We are dedicated to helping you take proactive steps to safeguard your well-being and enjoy a healthy, happy life. Trust us to be your partner on your journey to better health.Schedule your health checkup with Andheri West medical checkup centre today and take the first step towards a healthier future. Your well-being is in safe hands.
                        </p>
                    </div>
                    <div className='HealthCheckup-Section-2-accordion'>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Basic Health Check ( 4,000/-)
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Creatinine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ECG</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Electrolytes</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Bilirubin</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Chest X-ray</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Glucose Fasting & PP </h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Lipid Profile</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Vitamin B12</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HBA1C</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Calcium</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Vitamin D3</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGOT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGPT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Comprehensive Health Check ( 7,000/-)
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Lipid Profile</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ECG</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ESR</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Liver Function Test</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Chest X-ray</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Peripheral Smear</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Thyroid Panel I</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Stress Test or 2D Echo</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Group</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Sugar Fasting & Post Prandial</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>USG Abdomen Pelvis</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HIV, HBsAg, HCV</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Creatinine</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Stool Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Electrolytes</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>PAP Smear for Female</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>PSA for Male</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Physician</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Gynaecologist for Female</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Senior Citizen Male ( 4,000/-)
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Creatinine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ECG</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ESR</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood glucose fasting & Post Prandial</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Lipid Profile</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Stool Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>X Ray Chest</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGOT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGPT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>PSA Total & Free</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Physician</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Senior Citizen Female ( Rs. 5250/-) (4,000/-)
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Creatinine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ECG</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ESR</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood glucose fasting & Post Prandial</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Lipid Profile</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Stool Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>X Ray Chest</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGOT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGPT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>USG Abdomen & Pelvis</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Physician</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Executive Health Check Women ( 11,000/-)
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC, ESR, Peripheral Smear</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Lipid Profile</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ECG</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Group</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Liver Function Test</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Chest X-ray</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Renal Function Test</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HBA1C</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Stool Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Thyroid Function Test</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HIV, HBsAg, HCV</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Sugar Fasting & Post Prandial</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>G6PD</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>TMT or 2D Echo</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Vitamin B12</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>PAP Smear</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>USG Abdomen & Pelvis</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Vitamin D3</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Sono Mammography</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Pulmonary Function Test</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Physician</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by ENT Specialist</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Ophthalmologist</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Gynaecologist</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSix">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        Executive Health Check Men ( Rs. 15000/- )
                                    </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC, ESR, Peripheral Smear</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Lipid Profile</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ECG</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Group</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Liver Function Test</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Chest X-ray</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Renal Function Test</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HBA1C</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Stool Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Thyroid Function Test</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HIV, HBsAg, HCV</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Sugar Fasting & Post Prandial</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>G6PD</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>TMT or 2D Echo</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Vitamin B12</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>PSA Total & Free</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>USG Abdomen & Pelvis</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Vitamin D3</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Pulmonary Function Test</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Surgeon</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Physician</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by ENT Specialist</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Consultation by Ophthalmologist</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSeven">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        Diabetes Profile ( 1,850/-)
                                    </button>
                                </h2>
                                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HBA1C</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Sugar Fasting & Post Prandial</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Creatinine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Lipid Profile</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGOT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGPT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Microalbumin</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingEight">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                        Hypertension Profile ( Rs. 2250/- )
                                    </button>
                                </h2>
                                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HBA1C</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Sugar Fasting & Post Prandial</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Creatinine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Lipid Profile</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urea</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>X ray Chest</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ECG</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Electrolytes</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingNine">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                        Arthritis Profile ( Rs. 8250/- ) ( Rs. 6000/- )
                                    </button>
                                </h2>
                                <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Sugar Random</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urinc Acid</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ESR</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CRP</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>RA</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Creatinine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Calcium</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Phosphorous</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Alkaline Phosphatase</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Anti CCP</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Vitamin B12</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Vitamin D3</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGOT</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>SGPT</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTen">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                        Pre Employment Health Check ( Rs. 2500/- ) ( Rs. 1800/- )
                                    </button>
                                </h2>
                                <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>CBC</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>HIV, HBsAg, HCV</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>ECG</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood group</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Routine</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Creatinine</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Blood Glucose Random</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Chest X-ray</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Urine Pregrancy Test (Female)</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingEleven">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                        Microbiology Test
                                    </button>
                                </h2>
                                <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div className='HealthCheckup-accordion-body-con'>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Aerobic & Anaerobic Blood  Culture (automated)</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Fungal Blood Culture (automated)</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Aerobic Culture & Sensitivity</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Bacterial Identification Sensitivity</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Fungal Culture</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Fungal Identification & Sensitivity</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body HealthCheckup-accordion-body-color'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Afb Smear</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Grams Stain</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Fungal Stain-koh Mount</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='HealthCheckup-accordion-body'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>India Ink Preparation</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Hanging Drop</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='HealthCheckup-accordion-body-item'>
                                                            <h3>Stool For Ova & Cyst</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------------- Section 3 ---------------------------- */}

            <section className='HealthCheckup-Section3'>
                <div className='container'>
                    <div className='HealthCheckup-Section3-details'>
                        <h2>Microbiology Test Guideline for Health Check Up</h2>
                    </div>
                    <div className='HealthCheckup-Section3-data'>
                        <ul>
                            <li> You are requested to enquire about your health check-up on the previous day by calling on Tel. No. 022 69084200/8291198462 between 9.00a.m. to 5.00p.m.</li>

                            <li>  For Lipid Profile / Cholesterol / Triglyceride Testing - compulsory 10 - 12 hours
                                fasting post dinner is required. Please do not consume morning tea /
                                coffee. Plain water can be taken.</li>

                            <li>   Please do not bring the urine sample from home as it needs to be deposited
                                as close to the blood collection as possible for accuracy of test results.</li>
                            <li>     Females kindly ensure that you do not do your Health Check-up on the days
                                of Menstrual flow (Periods) as PAP Smear Test and Urine Routine will not be
                                carried out.</li>
                            <li> Alcohol consumption to be avoided 72 hours prior to the Health Check-up.</li>
                            <li> Abstinence for 3 days from Non-Veg food for Uric Acid Test is preferred.</li>
                            <li> Kindly carry your past medical record and current medications along with you.</li>

                            <li> If you are a known Hypertensive or Diabetic, please take your medication as
                                per the usual routine.</li>
                            <li> If you require a Stress Test, carry your sport shoes.</li>
                            <li> If you require Ophthalmic Consultation, kindly remove your contact lens a
                                day prior to the Health Check-up.</li>
                            <li>  All investigation reports wiII be available on the next day except PAP Smear
                                (after 5 days)</li>
                            <li>  Any additional tests / investigations / procedures / consultations can be
                                provided at request or if required. These will be billed separately from the
                                package.</li>
                        </ul>
                    </div>
                </div>
            </section >
            <Footer />


        </>
    )
}

export default HealthCheckup