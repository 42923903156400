import AmbulanceService from "../img/Facilities/emergency-services.png";
import EmergencyUnit from "../img/Facilities/emergency.png";
import Pharmacy from "../img/Facilities/pharmacy.png";
import Pathology from "../img/Facilities/Pathology.png";
import DigitalXRay from "../img/Facilities/Digital X-Ray.png";
import Sonography from "../img/Facilities/sonography.png";
import HaemoDialysis from "../img/Facilities/dialysis.png";
import healthcheck from "../img/Facilities/health-check.png";
import XRay from "../img/Facilities/x-ray.png";
import Ultrasound from "../img/Facilities/ultrasound.png";
import Endoscopy from "../img/Facilities/endoscopy.png";
import Laboratories from "../img/Facilities/Laboratories.png";
import Bloodstoragecentre from "../img/Facilities/blood-bank.png";
import Doppler from "../img/Facilities/intensive-care-unit.png";

const FacilitiesData = [
    {
        FacilitiesName:"Ambulance Service",
        HeadTag:"Emergency Ambulance Services in Andheri West",
        MetaTitle:"Emergency Ambulance Service in Andheri West | Thunga Hospital",
        MetaDes:"Don't delay, call Thunga! Andheri West's reliable emergency ambulance service. 24/7 care, expert crews, advanced tech. Save lives.",
        canonical:"https://thungasthhospital.com/emergency-ambulance-services-in-andheri-west",
        FacilitiesSlug:"/emergency-ambulance-services-in-andheri-west",
        FacilitiesMaindes:"At Thunga hospital our Emergency Ambulance services in Andheri west, we understand that every second counts when it comes to healthcare emergencies. That's why we take pride in our dedicated Ambulance Service. With a commitment to saving lives and providing top-notch medical transportation, our team of experienced paramedics and EMTs is at your service 24/7, 365 days a year.",
        FacilitiesMissionTitle:"Our Mission",
        FacilitiesMissionDes:"To deliver rapid, compassionate, and professional emergency medical care, ensuring the safety and well-being of our patients during their journey to and from emergency ambulance services in Andheri West.",
        FacilitiesImage:AmbulanceService,
        FacilitiesPointsHead:"What Sets Us Apart:",
        FacilitiesPointsHead01:"Skilled Team",
        FacilitiesPointsDes01:"Our ambulance service is staffed with highly trained and certified professionals who are well-equipped to handle a wide range of medical emergencies.",
        FacilitiesPointsHead02:"State-of-the-Art Equipment",
        FacilitiesPointsDes02:"We invest in cutting-edge medical equipment and vehicles to ensure the best possible care for our patients.",
        FacilitiesPointsHead03:"Timely Response",
        FacilitiesPointsDes03:"We understand the urgency of your situation and are dedicated to arriving promptly to provide immediate care and transport.",
        FacilitiesPointsHead04:"Compassion",
        FacilitiesPointsDes04:"We treat every patient with respect and empathy, ensuring their comfort and well-being throughout the journey.",
        FacilitiesPointsHead05:"Community Involvement",
        FacilitiesPointsDes05:"We are deeply rooted in the local community and actively participate in health and safety awareness programs.",
        FacilitiesEndPara01:"At Thunga hospitals , emergency Ambulance Services in Andheri west, we are the bridge between critical moments and expert medical care, dedicated to being there when you need us the most. Your health and safety are our top priorities.",
    },
    {
        FacilitiesName:"Casualty & Emergency Unit",
        HeadTag:"accident and emergency service in andheri",
        MetaTitle:"Urgent Medical Help? Accident & Emergency Service in Andheri.",
        MetaDes:"24/7 Accident & Emergency in Andheri? Our expert team is ready. Fast response, advanced care & personalized attention. Don't delay, call now!",
        canonical:"https://thungasthhospital.com/accident-and-emergency-service-in-andheri",
        FacilitiesSlug:"/accident-and-emergency-service-in-andheri",
        FacilitiesMaindes:"Welcome to our accident and Emergency service in Andheri, where compassionate care and rapid response come together to serve our community in times of urgent medical need. We understand that emergencies can happen at any time, and that's why we are here 24/7, 365 days a year, to provide expert medical attention when you need it most.",
        FacilitiesMissionTitle:"Our Mission",
        FacilitiesMissionDes:"Our mission is to deliver exceptional emergency medical care, treating every patient with the highest level of professionalism, empathy, and skill. We are dedicated to saving lives, alleviating pain, and ensuring the well-being of our patients.",
        FacilitiesImage:EmergencyUnit,
        FacilitiesPoints01Head:"Services We Offer:",
        FacilitiesPoints01des01:"Immediate assessment and triage",
        FacilitiesPoints01des02:"Rapid treatment of injuries, illnesses, and critical conditions",
        FacilitiesPoints01des03:"Diagnostic services, including X-rays, CT scans, and laboratory testing ",
        FacilitiesPoints01des04:"Surgical interventions when necessary",
        FacilitiesPoints01des05:"Expertise in managing trauma cases, cardiac emergencies, respiratory issues, and more",
        FacilitiesPoints01des06:"Coordination with other hospital departments for specialized care ",
        FacilitiesPointsHead:"Emotional support for patients and their families during times of crisis ",
        FacilitiesPointsHead01:"State-of-the-Art Facilities",
        FacilitiesPointsDes01:": Our Casualty and Emergency Unit is equipped with the latest medical technology and resources to ensure that our patients receive the best care possible. We maintain a clean and safe environment to promote healing and recovery.",
        FacilitiesPointsHead02:"Community Commitment",
        FacilitiesPointsDes02:"We are proud to be an integral part of our community, dedicated to serving our neighbors and beyond.We are committed to being a reliable resource for emergency care, and we continually strive to improve our services to meet the evolving needs of the community.",
        FacilitiesEndPara01:"In times of crisis, you can trust us to provide the highest standard of care. Your health and well-being are our top priorities, and we are here to support you with unwavering dedication and expertise.",
        FacilitiesEndPara02:"Thank you for choosing our accident and Emergency service in Andheri for your emergency medical needs.",
    },
    {
        FacilitiesName:"Pharmacy",
        HeadTag:"",
        MetaTitle:"",
        MetaDes:"",
        canonical:"https://thungasthhospital.com/",
        FacilitiesSlug:"/Pharmacy",
        FacilitiesMaindes:"At Thunga hospitals our pharmacy is an integral part of the healthcare journey. We are dedicated to providing exceptional pharmaceutical care to our patients, ensuring their well-being and recovery.",
        FacilitiesMissionTitle:"Our Mission",
        FacilitiesMissionDes:"Our mission is to deliver safe, effective, and timely medication management to all patients. We are committed to upholding the highest standards of pharmaceutical practice and contributing to the overall health and healing process.",
        FacilitiesImage:Pharmacy,
        FacilitiesPointsHead:"Our Services",
        FacilitiesPointsHead01:"Medication Dispensing",
        FacilitiesPointsDes01:"Our experienced pharmacists ensure accurate and prompt dispensing of prescribed medications, offering clear instructions for proper usage.",   
        FacilitiesPointsHead02:"Medication Counseling",
        FacilitiesPointsDes02:"We provide personalized consultations to address any questions or concerns about medications, helping patients understand their treatment plans.",
        FacilitiesPointsHead03:"Medication Safety",
        FacilitiesPointsDes03:"Patient safety is our top priority. We employ rigorous quality control measures to prevent errors and ensure medication safety.",
        FacilitiesPointsHead04:"Collaborative Care",
        FacilitiesPointsDes04:"We work closely with healthcare professionals to optimize medication therapy and contribute to patient well-being.",
        FacilitiesEndPara01:"Our Team Our pharmacy team comprises skilled and compassionate pharmacists and technicians who are dedicated to your health and recovery. We are always available to support and guide you through your medication journey.Contact Us Visit us at Thunga hospitals in Andheri West to experience the highest level of pharmaceutical care.",
        FacilitiesEndPara02:"At Thunga hospital Pharmacy, your well-being is our priority. We are here to serve you with excellence and care.",
    },
    {
        FacilitiesName:"Pathology & Diagnostic Center",
        HeadTag:"pathology and diagnostic centre in andheri",
        MetaTitle:"Accurate Pathology & Diagnostics in Andheri | Thunga Hospital",
        MetaDes:"Your trusted partner for accurate pathology & diagnostics in Andheri. Thunga Hospital comprehensive tests, personalized care, comfortable environment.",
        canonical:"https://thungasthhospital.com/pathology-and-diagnostic-centre-in-andheri",
        FacilitiesSlug:"/pathology-and-diagnostic-centre-in-andheri",
        FacilitiesMaindes:"At Thunga hospitals pathology and diagnostic centre in Andheri, we are dedicated to providing comprehensive and state-of-the-art diagnostic services to our community. With a commitment to accuracy, efficiency, and patient-centric care, we have become a trusted name in the field of pathology and diagnostics.",
        FacilitiesMissionTitle:"Our Mission",
        FacilitiesMissionDes:" Our mission is to empower healthcare through precise and timely diagnostics, ultimately improving the well-being of our patients. We strive to be at the forefront of medical technology and knowledge to offer the highest quality services.",
        FacilitiesImage:Pathology,
        FacilitiesPointsHead:"What Sets Us Apart ",
        FacilitiesPointsHead01:"Expertise",
        FacilitiesPointsDes01:"Our team of experienced pathologists and technicians is at the heart of what we do. They ensure the accuracy and reliability of every test we conduct.",
        FacilitiesPointsHead02:"Cutting-Edge Technology",
        FacilitiesPointsDes02:"We invest in the latest diagnostic equipment and technology to deliver results with precision and efficiency.",
        FacilitiesPointsHead03:"Patient-Centric Approach",
        FacilitiesPointsDes03:"We understand the anxiety that can come with medical tests. That's why we focus on creating a comfortable and welcoming environment for our patients.",
        FacilitiesPointsHead04:"Timely Reports",
        FacilitiesPointsDes04:"We value your time and deliver test results promptly, helping you and your healthcare provider make informed decisions.",
        FacilitiesEndPara01:" Your Health, Our Priority At pathology and diagnostic centre in Andheri, your health is our top priority. Whether you're here for routine check-ups, specific tests, or seeking answers to medical concerns, we are here to support you every step of the way.We look forward to serving you and being a vital part of your healthcare journey. Please feel free to contact us for any questions or to schedule an appointment.",
    },
    {
        FacilitiesName:"Digital X-Ray / Portable X-Ray",
        HeadTag:"Digital X-Ray / Portable X-Ray In andheri",
        MetaTitle:"Digital X-Ray & Portable X-Ray Service in Andheri | Thunga ",
        MetaDes:"Don't leave home for your X-ray! Thunga Hospital offers convenient portable X-ray services in Andheri. Fast results, comfortable experience.",
        canonical:"https://thungasthhospital.com/digital-x-ray-portable-x-ray-in-andheri",
        FacilitiesSlug:"/digital-x-ray-portable-x-ray-in-andheri",
        FacilitiesMaindes:"At Thunga hospital Digital x-ray and portable x-ray in Andheri, we are proud to offer state-of-the-art digital portable X-ray services to meet the evolving needs of modern healthcare. With a commitment to excellence and patient care, our digital X-ray department is at the forefront of medical imaging technology.Our digital X-ray equipment is cutting-edge, allowing for swift and precise image acquisition with minimal radiation exposure. This technology ensures that our patients receive the highest quality diagnostic images while prioritizing their safety.",
        FacilitiesImage:DigitalXRay,
        FacilitiesPointsHead:"Key Features:",
        FacilitiesPointsHead01:"Portability",
        FacilitiesPointsDes01:"Our portable X-ray units are designed for maximum flexibility, enabling us to provide diagnostic imaging services at the patient's bedside or in different areas of the hospital, ensuring timely and convenient care.",
        FacilitiesPointsHead02:"High Resolution",
        FacilitiesPointsDes02:"Our digital X-ray system provides exceptional image clarity, enhancing diagnostic accuracy for our healthcare professionals.",
        FacilitiesPointsHead03:"Radiation Safety",
        FacilitiesPointsDes03:"We are dedicated to minimizing radiation exposure while maintaining image quality, prioritizing patient well-being.",
        FacilitiesPointsHead04:"Efficiency",
        FacilitiesPointsDes04:"Our digital X-ray technology enables rapid image processing and sharing with healthcare providers, leading to faster diagnoses and treatment decisions.",
        FacilitiesPointsHead05:"Patient-Centric Approach",
        FacilitiesPointsDes05:"We understand the importance of patient comfort and convenience. Our experienced staff is trained to ensure a stress-free and seamless experience during the X-ray process.",
        FacilitiesPointsHead06:"Cutting-Edge Technology",
        FacilitiesPointsDes06:"We invest in the latest advancements in medical imaging technology to offer the most accurate and reliable diagnostic services.",
        FacilitiesEndPara01:"At Thunga hospital our digital x-ray and portable x-ray in Andheri, we believe that providing advanced digital X-ray services is integral to our commitment to delivering the highest standard of healthcare. Our skilled team of radiologists and technicians work diligently to support our mission of promoting health and well-being in our community.",
        FacilitiesEndPara02:"We are dedicated to serving our patients and healthcare providers with excellence, and we invite you to experience the difference that our digital X-ray services make in delivering exceptional healthcare.",
        FacilitiesEndPara03:"Your health is our priority, and we are here to provide you with the most advanced and compassionate care possible.",
    },
    {
        FacilitiesName:"Sonography / 2D Echo",
        HeadTag:"Sonography test in andheri",
        MetaTitle:"Advanced Sonography & 2D Echo in Andheri | Thunga Hospital",
        MetaDes:"Worried about your health? Thunga Hospital's advanced Sonography & 2D Echo can help. Schedule online for clear answers.",
        canonical:"https://thungasthhospital.com/sonography-test-in-andheri",
        FacilitiesSlug:"/sonography-test-in-andheri",
        FacilitiesMaindes:"Welcome to Thunga hospital sonography test in Andheri, where we pride ourselves on offering exceptional Sonography and 2D Echo services. We understand that your health and well-being are of utmost importance, and our dedicated team of skilled professionals is committed to providing you with the highest quality care.",
        FacilitiesImage:Sonography,
        FacilitiesPointsHead:"Why Choose for a sonography test in Andheri?",
        FacilitiesPointsHead01:"Compassionate Care",
        FacilitiesPointsDes01:"Our team values your comfort and strives to make your visit as comfortable as possible.",
        FacilitiesPointsHead02:"Accuracy and Precision",
        FacilitiesPointsDes02:"We maintain the highest standards of accuracy in diagnostic imaging, ensuring reliable results.",
        FacilitiesPointsHead03:"Timely Reports",
        FacilitiesPointsDes03:"We understand the importance of timely diagnoses and provide prompt reporting.",
        FacilitiesPointsHead04:"Patient-Centric Approach",
        FacilitiesPointsDes04:"Your health is our priority, and we are dedicated to answering your questions and addressing your concerns.",
        FacilitiesPointsHead05:"State-of-the-Art Facility",
        FacilitiesPointsDes05:"Our hospital is equipped with the latest technology, ensuring that you receive the best possible care. We prioritize patient safety and follow strict protocols to guarantee accurate results.",
        FacilitiesPointsHead06:"Meet Our Team",
        FacilitiesPointsDes06:"Our dedicated and experienced team of sonographers and technicians are here to provide you with the care and attention you deserve. We continually update our knowledge to stay at the forefront of medical advancements.",
        FacilitiesPointsHead07:"Convenient Location",
        FacilitiesPointsDes07:"Located in the heart of Andheri west, our hospital is easily accessible, making it convenient for patients to receive the care they need.",
        FacilitiesEndPara01:"We are committed to your health and well-being. Whether you require routine scans or specialized cardiac assessments, our Sonography and 2D Echo services are here to support you on your journey to better health.Your health is our priority.",
        FacilitiesEndPara02:"Contact us today to schedule an appointment or inquire about our services.",
    },
    {
        FacilitiesName:"Doppler",
        HeadTag:"doppler test in andheri mumbai",
        MetaTitle:"Doppler Test in Andheri, Mumbai - Thunga Hospital",
        MetaDes:"Book your painless Doppler test in Andheri at Thunga Hospital! Detailed blood flow imaging, expert care, & fast results.",
        canonical:"https://thungasthhospital.com/doppler-test-in-andheri-mumbai",
        FacilitiesSlug:"/doppler-test-in-andheri-mumbai",
        FacilitiesMaindes:"Welcome to the Thunga hospital for  Doppler test in Andheri Mumbai, where precision meets compassion in the realm of medical diagnostics.Our state-of-the-art Doppler unit is committed to providing patients with the highest quality vascular and fetal Doppler services.",
        FacilitiesMissionTitle:"Our Mission",
        FacilitiesMissionDes:"At Thunga hospital, our mission is to deliver accurate, timely, and non-invasive Doppler examinations, ensuring early detection and expert evaluation of various medical conditions. We prioritize patient well-being and comfort, striving for excellence in every aspect of our service.",
        FacilitiesImage:Doppler,
        FacilitiesPointsHead:"Why Choose Our Doppler Unit for Doppler test in Andheri Mumbai",
        FacilitiesPointsHead01:"Experienced Team",
        FacilitiesPointsDes01:"Our skilled team of specialized radiologists and technologists are experts in Doppler ultrasound, providing the highest standard of care.",
        FacilitiesPointsHead02:"Cutting-Edge Technology",
        FacilitiesPointsDes02:"We utilize the latest Doppler equipment to ensure accurate and reliable results.",
        FacilitiesPointsHead03:"Patient-Centric Care",
        FacilitiesPointsDes03:"Your well-being is our top priority. We ensure a comfortable and stress-free environment during your Doppler examination.",
        FacilitiesPointsHead04:"Comprehensive Services",
        FacilitiesPointsDes04:"We offer a wide range of Doppler exams, including vascular, fetal, and more, tailored to your specific medical needs.",
        FacilitiesPointsHead05:"Quick Turnaround",
        FacilitiesPointsDes05:"We understand the urgency of healthcare. Our team strives for rapid reporting to help with timely medical decisions.",
        FacilitiesPointsHead06:"Collaborative Approach",
        FacilitiesPointsDes06:"We work closely with your referring healthcare providers to ensure seamless coordination of your care.",
        FacilitiesPointsHead07:"Contact Us",
        FacilitiesPointsDes07:"We are here to assist you and answer any questions you may have.",
        FacilitiesEndPara01:"At Thunga hospital, we are dedicated to your health and peace of mind. Trust us for accurate and compassionate Doppler test services in Andheri Mumbai ",
    },
    {
        FacilitiesName:"HaemoDialysis",
        HeadTag:"hemodialysis/ kidney dialysis in andheri",
        MetaTitle:"Expert Kidney Dialysis Care in Andheri | Thunga Hospital",
        MetaDes:"Book your comfortable Kidney Dialysis in Andheri at Thunga Hospital! Expert care, advanced technology, flexible scheduling. Contact Now!",
        canonical:"https://thungasthhospital.com/hemodialysis-kidney-dialysis-in-andheri",
        FacilitiesSlug:"/hemodialysis-kidney-dialysis-in-andheri",
        FacilitiesMaindes:"At Thunga hospital our hemodialysis and kidney dialysis in Andheri, we take pride in providing top-quality hemodialysis services to our patients. Our Hemodialysis Department is dedicated to delivering expert care to individuals dealing with kidney-related issues.",
        FacilitiesMissionTitle:"Our Commitment",
        FacilitiesMissionDes:"We are committed to improving the quality of life for our patients by offering advanced hemodialysis treatments in a safe and comfortable environment. Our team of experienced nephrologists, nurses, and support staff work together to ensure that every patient receives personalized and comprehensive care.",
        FacilitiesImage:HaemoDialysis,
        FacilitiesPointsHead01:"State-of-the-Art Facilities",
        FacilitiesPointsDes01:"Our department is equipped with state-of-the-art hemodialysis machines and facilities to guarantee the best possible treatment outcomes. We maintain strict standards for cleanliness and infection control to provide a safe and hygienic environment for our patients.",
        FacilitiesPointsHead02:"Compassionate Care",
        FacilitiesPointsDes02:"We understand that living with kidney disease can be challenging, and we are here to provide not only medical support but also emotional support. Our staff is compassionate and dedicated to helping patients and their families through their journey to better health.",
        FacilitiesPointsHead03:"Patient-Centered Approach",
        FacilitiesPointsDes03:"We prioritize the individual needs and preferences of our patients. Our care plans are tailored to each patient's unique condition, ensuring the best possible outcomes and quality of life.",
        FacilitiesPointsHead04:"Get in Touch",
        FacilitiesPointsDes04:"If you or a loved one are in need of hemodialysis services, we invite you to reach out to our Hemodialysis Department.",
        FacilitiesEndPara01:"At Thunga hospital, our hemodialysis and kidney dialysis in Andheri we are dedicated to enhancing the well-being of our patients through exceptional hemodialysis care. Your health is our priority.",
    },
    {
        FacilitiesName:"Special health check up scheme",
        HeadTag:"special health checkup packages in andheri",
        MetaTitle:"Find Special Health Checkups in Andheri - Thunga Hospital",
        MetaDes:"Book your personalized Special Health Checkup Package in Andheri at Thunga Hospital! Expert doctors, comprehensive tests, affordable prices.",
        canonical:"https://thungasthhospital.com/special-health-checkup-packages-in-andheri",
        FacilitiesSlug:"/special-health-checkup-packages-in-andheri",
        FacilitiesMaindes:"At Thunga hospital our special health checkup packages in Andheri are committed to your well-being. Our Special Health Checkup Scheme is designed with your health and happiness in mind. We understand the importance of preventive healthcare, and that's why we offer this comprehensive program to help you stay ahead of potential health issues.",
        FacilitiesMissionTitle:"Our Mission",
        FacilitiesMissionDes:"Our mission is to empower you with the knowledge and tools you need to take control of your health. By participating in our Special Health Checkup Scheme, you're not just investing in your health; you're investing in a healthier, happier future.",
        FacilitiesImage:healthcheck,
        FacilitiesPointsHead:"Why Choose Us:",
        FacilitiesPointsHead01:"Expertise",
        FacilitiesPointsDes01:"Our hospital has a team of highly skilled and experienced healthcare professionals who are dedicated to providing top-quality medical care.",
        FacilitiesPointsHead02:"Comprehensive Checkups",
        FacilitiesPointsDes02:"Our scheme offers a wide range of health checkup packages to suit your specific needs. From general health assessments to specialized tests, we have you covered.",
        FacilitiesPointsHead03:"State-of-the-Art Facilities",
        FacilitiesPointsDes03:"We are equipped with the latest medical technology and equipment to ensure accurate diagnostics and precise results.",
        FacilitiesPointsHead04:"Personalized Care",
        FacilitiesPointsDes04:"Your health is unique, and we treat it that way. Our healthcare professionals tailor their recommendations based on your individual health profile.",
        FacilitiesPointsHead05:"Affordability",
        FacilitiesPointsDes05:"We believe that everyone should have access to quality healthcare. Our health checkup packages are priced competitively to make it accessible to all.",
        FacilitiesPointsHead06:"Contact Us",
        FacilitiesPointsDes06:"We are here to answer any questions you may have about our special health checkup scheme. Please don't hesitate to reach out to our friendly and knowledgeable staff at Thunga hospital.Take the first step towards a healthier you with [Thunga Hospital]'s Special Health Checkup Scheme. Your well-being is our priority.",
    },
    {
        FacilitiesName:"Endoscopy Suite",
        HeadTag:"Endoscopy Centres in andheri",
        MetaTitle:"Advanced Endoscopy Center In Andheri| Thunga Hospital",
        MetaDes:"Worried about digestive issues? Thunga Hospital's Endoscopy Centre in Andheri offers solutions. Schedule for comprehensive exams & personalized care.",
        canonical:"https://thungasthhospital.com/endoscopy-centres-in-andheri",
        FacilitiesSlug:"/endoscopy-centres-in-andheri",
        FacilitiesMaindes:"Welcome to our state-of-the-art Endoscopy centre in Andheri, where we prioritize your health and well-being. As part of Thunga hospital we are committed to delivering the highest standard of care through our specialized endoscopy services.",
        FacilitiesMissionTitle:"Our Mission",
        FacilitiesMissionDes:"At our Endoscopy center in Andheri our mission is to provide safe, compassionate, and cutting-edge endoscopic procedures to diagnose and treat a wide range of gastrointestinal and respiratory conditions. We are dedicated to improving the lives of our patients through precision, innovation, and a patient-centered approach.",
        FacilitiesImage:Endoscopy,
        FacilitiesPointsHead01:"Expertise and Excellence",
        FacilitiesPointsDes01:"Our team of board-certified gastroenterologists, pulmonologists, nurses, and technicians are experts in their respective fields. They work collaboratively to ensure the most accurate diagnoses and effective treatments. We continuously invest in the latest endoscopic technology to maintain our commitment to excellence.",
        FacilitiesPointsHead02:"Patient-Centered Care ",
        FacilitiesPointsDes02:"We understand that undergoing an endoscopy procedure can be a stressful experience. That's why we focus on providing a warm, empathetic, and patient-centered environment. Your comfort and well-being are our top priorities, and we strive to answer your questions and address your concerns every step of the way.",
        FacilitiesPointsHead03:"Comprehensive Services",
        FacilitiesPointsDes03:"Our Endoscopy Suite offers a wide range of endoscopic procedures, including but not limited to: Gastroscopy (Upper Endoscopy), Colonoscopy, Endoscopic Retrograde, Cholangiopancreatography (ERCP)Bronchoscopy, Endobronchial Ultrasound (EBUS)And many more.",
        FacilitiesPointsHead04:"Quality and Safety",
        FacilitiesPointsDes04:"Patient safety is paramount at our Endoscopy Suite. We adhere to the highest standards of infection control, sterilization, and procedural safety. Our facility is equipped with cutting-edge equipment to ensure the highest quality outcomes.",
        FacilitiesPointsHead05:"Contact Us",
        FacilitiesPointsDes05:"We're here to address your concerns and provide you with the information you need. Feel free to reach out to us to schedule an appointment or learn more about our services.",
        FacilitiesEndPara01:"Thank you for considering Thunga hospital endoscopy center in Andheri  for your healthcare needs. Your trust is our motivation, and we look forward to serving you with exceptional care.",
    },
    {
        FacilitiesName:"Blood storage centre",
        HeadTag:"Blood bank Hospital in andheri",
        MetaTitle:"Blood Bank Hospital in Andheri - Thunga Hospital",
        MetaDes:"Thunga Hospital - Blood Bank Hospital in Andheri! Donate blood & make a difference. Find eligibility details, schedule your appointment & be a hero today!",
        canonical:"https://thungasthhospital.com/blood-bank-hospital-in-andheri",
        FacilitiesSlug:"/blood-bank-hospital-in-andheri",
        FacilitiesMaindes:"The Blood bank hospital in Andheri plays a vital role in saving lives and promoting the health and well-being of our community. With a commitment to excellence and unwavering dedication, we ensure a safe and reliable supply of blood and blood products to meet the needs of patients in our care.",
        FacilitiesImage:Bloodstoragecentre,
        FacilitiesPointsDes01:"At the heart of our operation is our commitment to the patients we serve. We understand the critical role blood plays in medical treatments, including surgeries, trauma care, cancer treatments, and managing chronic illnesses. This knowledge drives us to maintain a robust inventory of blood products, ensuring that no patient ever has to wait for a lifesaving transfusion.",
        FacilitiesPointsDes02:"Our blood donors are the lifeblood of our center. We deeply appreciate their generosity and continue to organize blood drives to meet the constant demand. We also prioritize donor education and support to ensure a smooth and comfortable experience for those who give the gift of life.",
        FacilitiesPointsDes03:"Safety, compassion, and professionalism are the cornerstones of our Blood bank in Andheri. We stand ready to respond to emergency situations, and our work goes beyond just storing blood—it's about saving lives. We are honored to be part of this critical healthcare journey, and we remain dedicated to the health and well-being of our patients and the community we serve.",
    },
];

export default FacilitiesData;
