import Orthopedic from "../img/services/orthopedic-surgery.jpg";
import Gynecology from "../img/services/Gynecology.jpg";
import Cardiology from "../img/services/Cardiology.jpg";
import Pediatric from '../img/services/Pediatric.jpg';
import Neurology from "../img/services/Neurology.jpeg";
import Nephrology from "../img/services/Nephrology.jpeg";
import Urology from "../img/services/Urology.jpg";
import GeneralMedicine from '../img/services/General Medicine.jpg';
import PlasticCosmetic from "../img/services/GISurgery.jpg";
import GISurgery from "../img/services/GISurgery.jpg";

const SpecialitiesData = [
    {
        id: 1,
        ServiceName: 'Cardiology & Cardiac Surgery',
        HeadTag: "Best Cardiac Hospital in Andheri",
        MetaTitle: "Best Cardiac Hospital in Andheri | Thunga Hospital",
        MetaDes:"Experience unparalleled cardiac care at the Best Cardiac Hospital in Andheri. Trust us for comprehensive cardiovascular services.",
        canonical:"https://thungasthhospital.com/best-cardiac-hospital-andheri",
        ServiceSlug: "/best-cardiac-hospital-andheri",
        ServiceImages: Orthopedic,
        ServiceHeadPara: "Welcome to the best Cardiology and Cardiac Surgery hospital in Andheri. We are dedicated to providing world-class cardiac care and surgical services to our patients, ensuring their heart health and well-being.",
        ServiceHead01: "Our Commitment to Excellence",
        ServicePara01: "At our department of cardiac surgery in Andheri , we take pride in our commitment to excellence. Our team of highly skilled cardiologists, cardiothoracic surgeons, nurses, and support staff work collaboratively to deliver the best possible care for our patients. We use cutting-edge technology and evidence-based practices to ensure the highest quality of treatment.",
        ServicePointHead01: "Comprehensive Cardiac Care",
        ServicePointPara01: "We offer a wide range of services, including diagnostic testing, non-invasive treatments, and complex cardiac surgeries. For cardiac surgery in Andheri Our comprehensive approach covers prevention, diagnosis, treatment, and rehabilitation. Whether you require routine check-ups, interventional procedures, or open-heart surgery, we are here to provide the most advanced and compassionate care.",
        ServicePointHead02: "Patient-Centered Approach",
        ServicePointPara02: "The well-being of our patients is at the center of everything we do. We understand that dealing with cardiac issues can be challenging, and we are committed to supporting you throughout your journey to recovery. Our caring and dedicated team will provide personalized treatment plans and emotional support to ensure you receive the best care possible.",
        ServicePointHead03: "State-of-the-Art Facilities",
        ServicePointPara03: "Our department of cardiac surgery in Andheri is equipped with state-of-the-art facilities, including advanced cardiac catheterization labs, operating rooms, and recovery units. This allows us to perform a wide range of procedures with precision and safety.",
        ServicePointHead04: "Research and Innovation",
        ServicePointPara04: "We are actively involved in research and innovation in the field of cardiology and cardio surgery. Our commitment to staying at the forefront of medical advancements ensures that you receive the most up-to-date and effective treatments available.",
        ServicePointPara05: "Contact Us If you have any questions, need to schedule an appointment, or require more information, please don't hesitate to reach out to us. Your heart health is our priority.",
        ServicePointPara06: "Thank you for choosing Thunga STH hospitals Cardiology and Cardiac Surgery hospital in Andheri for your cardiac care needs. We look forward to serving you and helping you achieve the best possible heart health.",
    },
    {
        id: 2,
        ServiceName: 'Orthopedic & Rheumatology',
        HeadTag: "Best Orthopedic Hospitals in Andheri",
        MetaTitle: "Best Orthopedic Hospitals in Andheri | Thunga Hospital",
        MetaDes:"Discover the Best Orthopedic Hospitals in Andheri offering top-notch medical expertise and compassionate care. Choose excellence for your orthopedic health.",
        canonical:"https://thungasthhospital.com/best-orthopedic-hospitals-andheri",
        ServiceSlug: "/best-orthopedic-hospitals-andheri",
        ServiceImages: Gynecology,
        ServiceHeadPara: "The best Orthopedic hospital in Andheri is dedicated to providing comprehensive and compassionate care for patients with musculoskeletal and joint conditions. Our team of experienced orthopedic surgeons, rheumatologists, and support staff work collaboratively to ensure the highest standard of care for our patients.",
        ServiceHead01: "Our expertise",
        ServicePara01: "In the best Rheumatology in Andheri we specialize in the diagnosis and treatment of a wide range of orthopedic conditions, including fractures, joint replacements, sports injuries, and spinal disorders. Our state-of-the-art facilities and advanced surgical techniques ensure that our patients receive the most effective and minimally invasive treatments. We prioritize patient education and involve our patients in their treatment decisions, providing them with the knowledge and support they need for a smooth recovery.",
        ServicePara02: "Our best Rheumatologist in Andheri focuses on the management of autoimmune and inflammatory diseases that affect the joints and soft tissues.",
        ServicePara03: "Our rheumatologists in Andheri are experts in conditions such as rheumatoid arthritis, osteoarthritis, lupus, and more. They employ a patient-centered approach, offering personalized treatment plans that may include medication, physical therapy, and lifestyle modifications to improve patients' quality of life.",
        ServicePara04: "At our department, we are committed to ongoing research and innovation in the field of orthopedics and rheumatology, ensuring that our patients have access to the latest treatments and therapies. We also prioritize preventive care and wellness, aiming to educate our patients on injury prevention and joint health.",
        ServicePara05: "Our dedicated team of healthcare professionals, cutting-edge technology, and patient-centered approach make the Orthopaedic and Rheumatology Department a trusted destination for individuals seeking specialized care for musculoskeletal and joint-related issues. We are proud to serve our community and are committed to improving the lives of our patients by promoting mobility, reducing pain, and enhancing their overall well-being.",
    },
    {
        id: 3,
        ServiceName: 'Obstetrics & Gynaecology',
        HeadTag: "Best Gynecology Hospitals in Andheri",
        MetaTitle: "Best Gynecology Hospitals in Andheri | Thunga Hospital",
        MetaDes:"Discover the Best Gynecology Hospitals in Andheri providing expert care for women's health. Our top-rated facilities ensure comprehensive services.",
        canonical:"https://thungasthhospital.com/best-gynecology-hospitals-andheri",
        ServiceSlug: "/best-gynecology-hospitals-andheri",
        ServiceImages: Cardiology,
        ServiceHeadPara: "The best Gynecology hospital in Andheri is a beacon of comprehensive women's healthcare. With a dedicated team of skilled professionals, we strive to provide exceptional care to women at every stage of life. Our commitment extends beyond medical expertise, embracing compassion and understanding.In the realm of obstetrics, we specialize in guiding expectant mothers through the miraculous journey of pregnancy, ensuring optimal health for both mother and child. Our state-of-the-art facilities offer advanced prenatal diagnostics and personalized birthing experiences, fostering a supportive environment for families.",
        ServicePara01: "In the best gynecology hospital in Andheri, we address a spectrum of women's health issues with precision and empathy. From routine screenings to complex surgeries, our experts employ cutting-edge technology to deliver top-notch care. We prioritize open communication, empowering patients to make informed decisions about their health.",
        ServicePara02: "Education is integral to our mission. We actively engage in community outreach programs, emphasizing preventive care and health awareness. Our team is committed to advancing medical knowledge through research, contributing to the evolution of women's healthcare practices globally.",
        ServicePointHead: "Services in the best gynecology hospital in Andheri ",
        ServicePointHead01: "Maternal-Fetal Medicine",
        ServicePointPara01: "Our experts in this field focus on high-risk pregnancies, ensuring the well-being of both mother and baby. Advanced monitoring and interventions are tailored to meet specific challenges.",
        ServicePointHead02: "Reproductive Endocrinology and Infertility",
        ServicePointPara02: "We offer comprehensive care for couples facing fertility issues. Our specialists employ advanced reproductive technologies and personalized treatment plans to enhance the chances of conception.",
        ServicePointHead03: "Gynecologic Oncology",
        ServicePointPara03: "Dedicated to the prevention and treatment of gynecological cancers, our team provides cutting-edge therapies and compassionate support throughout the entire cancer care journey.Minimally Invasive.",
        ServicePointHead04: "Gynecologic Surgery",
        ServicePointPara04: "Our surgeons excel in minimally invasive techniques, including laparoscopic and robotic procedures, promoting quicker recovery and reduced discomfort for patients undergoing gynecological surgeries.",

    },
    {
        id: 4,
        ServiceName: 'Pediatric',
        HeadTag: "Pediatric hospital in Andheri",
        MetaTitle: "Pediatric hospital in Andheri | Thunga Hospital",
        MetaDes:"Discover top-notch Pediatric Care in Andheri at our specialized hospital. We provide comprehensive healthcare services for children.",
        canonical:"https://thungasthhospital.com/pediatric-hospital-andheri",
        ServiceSlug: "/pediatric-hospital-andheri",
        ServiceImages: Pediatric,
        ServiceHeadPara: "Our pediatric hospital in Andheri is dedicated to providing compassionate and specialized care for our youngest patients. With a team of skilled healthcare professionals, we strive to create a nurturing environment that promotes the health and well-being of children. From routine check-ups to specialized treatments, we're here to support families every step of the way. Our  pediatrician in Andheri believes that Your child's health is our priority.",
        ServicePointHead01: "Services",
        ServicePointPara01: "In our pediatric hospital in Andheri we offer a comprehensive range of services tailored to meet the unique needs of children. This includes routine wellness check-ups, vaccinations, acute care for illnesses and injuries, as well as specialized care for pediatric conditions. Our team of experienced pediatrician in Andheri nurses, and support staff are committed to ensuring the health and happiness of your child. Additionally, we provide a child-friendly environment to make the healthcare experience positive for both children and their families.",
        ServicePointHead02: "Facilities",
        ServicePointPara02: "Our pediatric department is equipped with state-of-the-art facilities to ensure the highest quality of care for your child. We have child-friendly examination rooms, specialized equipment for pediatric diagnostics, and dedicated play areas to create a comfortable and engaging atmosphere. Our team is trained to work with children, ensuring a gentle and understanding approach. We prioritize safety, hygiene, and the overall well-being of our young patients, providing a supportive environment for both medical treatment and emotional comfort.",
    },
    {
        id: 5,
        ServiceName: 'Neurology & Neuro Surgery',
        HeadTag: "Top Neurosurgery Hospital in Andheri",
        MetaTitle: "Top Neurosurgery Hospital in Andheri | Thunga Hospital",
        MetaDes:"Experience Top Neurosurgery Care in Andheri at our top-rated hospital. Unparalleled expertise, cutting-edge technology, and compassionate care await you. ",
        canonical:"https://thungasthhospital.com/top-neurosurgery-hospital-andheri",
        ServiceSlug: "/top-neurosurgery-hospital-andheri",
        ServiceImages: Neurology,
        ServiceHeadPara: "Welcome to top Neurosurgery hospital in Andheri! Our dedicated team of experts combines advanced medical technology with compassionate care to provide top-notch treatment for neurological conditions. From intricate surgeries to innovative therapies, we're committed to enhancing the lives of our patients. Our best neurologist in Andheri believes that Your well-being is our priority, and we strive to deliver excellence in every aspect of neuro healthcare.",
        ServicePointHead01: "Services",
        ServicePointPara01: "In hospital our best neurologist in Andheri offers a comprehensive range of services tailored to meet your unique needs. From precise diagnostics utilizing state-of-the-art imaging technology to advanced surgical interventions, we cover a spectrum of neurological conditions. Our services include neurodiagnostics, neuroimaging, neurosurgical procedures, minimally invasive interventions, neurorehabilitation, and ongoing post-operative care. Rest assured, your neurological health is in expert hands.",
        ServicePointHead02: "Facilities",
        ServicePointPara02: "In our Neurology department, we pride ourselves on providing cutting-edge facilities to ensure the highest quality of care. Our state-of-the-art neurodiagnostic lab offers advanced testing for accurate diagnoses. The neuroimaging center is equipped with the latest technology for detailed scans. Our neurologists have access to a fully equipped consultation area for thorough examinations, and our dedicated neurorehabilitation facility supports the holistic recovery of our patients. Your journey to neurological well-being begins with our modern and comprehensive facilities.",
    },
    {
        id: 6,
        ServiceName: 'Nephrology / Urology',
        HeadTag: "Nephrologists in Andheri",
        MetaTitle: "Nephrologists in Andheri | Thunga Hospital",
        MetaDes:"Discover skilled Nephrologists in Andheri providing comprehensive kidney care. Our expert team ensures personalized solutions for renal health.",
        canonical:"https://thungasthhospital.com/nephrologists-andheri-kidney-care",
        ServiceSlug: "/nephrologists-andheri-kidney-care",
        ServiceImages: Nephrology,
        ServiceHeadPara: "Our kidney hospitals in Andheri are dedicated to providing comprehensive care for patients with kidney-related conditions. Our team of skilled nephrologists and healthcare professionals is committed to delivering personalized and advanced treatment options, ensuring the best possible outcomes for our patients. We prioritize a patient-centric approach, combining expertise with compassion to address a wide range of kidney disorders.",
        ServicePointHead: "In Our Nephrology Department , our nephrologist in Andheri offers a range of services to cater to the diverse needs of our patients. These include:",
        ServicePointHead01: "Dialysis Services:",
        ServicePointPara01: " State-of-the-art dialysis facilities for both hemodialysis and peritoneal dialysis.Chronic Kidney Disease (CKD) Management: Comprehensive care and management plans for patients with all stages of CKD.",
        ServicePointHead02: "Kidney Transplantation",
        ServicePointPara02: "Evaluation, preparation, and post-transplant care for patients seeking kidney transplantation.",
        ServicePointHead03: "Hypertension Management",
        ServicePointPara03: "Specialized treatment plans for individuals with kidney-related hypertension.",
        ServicePointHead04: "Electrolyte Imbalance Treatment",
        ServicePointPara04: "Expertise in addressing electrolyte disorders to maintain optimal kidney function.",
        ServicePointHead05: "Glomerular Disease Management",
        ServicePointPara05: "Diagnosis and management of various glomerular diseases affecting the kidneys.",
        ServicePointHead06: "Stone Disease Management",
        ServicePointPara06: "Evaluation and treatment of kidney stones through medical or surgical interventions.",
        ServicePointHead07: "Pediatric Nephrology Services",
        ServicePointPara07: "Specialized care for children with kidney disorders, ensuring age-appropriate treatment.Our dedicated team of nephrologists and support staff is committed to delivering high-quality, patient-centered care in every aspect of our services.",
    },
    {
        id: 7,
        ServiceName: 'Hematology',
        HeadTag: "Hematology Hospital in Andheri",
        MetaTitle: "Hematology Hospital in Andheri | Thunga Hospital",
        MetaDes:"Discover world-class Hematology Hospital in Andheri. Expertise, compassion, and cutting-edge treatments for blood disorders.",
        canonical:"https://thungasthhospital.com/hematology-hospital-andheri",
        ServiceSlug: "/hematology-hospital-andheri",
        ServiceImages: Urology,
        ServiceHeadPara: "The Hematology  hospital in Andheri stands as a beacon of excellence in the realm of blood-related disorders. Committed to advancing patient care, our team of dedicated professionals combines expertise with compassion to deliver comprehensive hematological services.",
        ServicePara01: "At the forefront of diagnostics, we employ cutting-edge technologies to unravel the intricacies of blood disorders, ensuring swift and accurate assessments. Our state-of-the-art laboratory is equipped to handle a myriad of tests, enabling us to provide timely and precise results crucial for effective treatment plans.",
        ServicePara02: "Collaboration is the cornerstone of our approach. Our multidisciplinary team, and our blood specialist in Andheri comprising hematologists, pathologists, nurses, and support staff, works seamlessly to address the diverse needs of our patients. Through a holistic and patient-centric model, we not only treat the conditions but also focus on enhancing the overall well-being of individuals under our care.",
        ServicePara03: "Education is a driving force within our department. We are dedicated to nurturing the next generation of hematologists through training programs and research initiatives. By fostering an environment of continuous learning, we aim to contribute to the evolution of hematological sciences and improve outcomes for patients worldwide.",
        ServicePara04: "Our commitment extends beyond the confines of our hospital walls. Actively engaged in community outreach, we strive to raise awareness about blood disorders, emphasizing prevention and early detection. Through health campaigns and educational events, we aspire to empower individuals to take charge of their hematological health.In essence, the Hematology Department at our hospital is not merely a medical facility; it is a hub of innovation, collaboration, and compassionate care. Driven by a collective passion for excellence, we stand resolute in our mission to make a meaningful impact on the lives of those affected by blood-related challenges.",
    },
    {
        id: 8,
        ServiceName: 'General Medicine',
        HeadTag: "General Physicians in Andheri",
        MetaTitle: "General Physicians in Andheri | Thunga Hospital",
        MetaDes:"Discover the General Physicians in Andheri for comprehensive healthcare. Find experienced doctors providing trusted medical services tailored to your needs.",
        canonical:"https://thungasthhospital.com/general-physicians-andheri",
        ServiceSlug: "/general-physicians-andheri",
        ServiceImages: GeneralMedicine,
        ServiceHeadPara: "The General physician hospital in Andheri is a beacon of comprehensive healthcare, dedicated to providing top-notch medical services with a focus on holistic patient well-being. Our team of highly skilled and compassionate physicians, nurses, and support staff work tirelessly to deliver personalized care to each individual who walks through our doors.",
        ServicePointHead01: "Expertise",
        ServicePointPara01: "At the core of our department's philosophy is a commitment to excellence in diagnosis, treatment, and ongoing patient education. And we have the best general physician in Andheri. We utilize cutting-edge medical technology and stay abreast of the latest advancements in the field to ensure that our patients receive the best possible care. Whether it's routine check-ups, complex medical cases, or preventive health measures, we approach each situation with a combination of expertise and empathy.",
        ServicePointPara02: "Our multidisciplinary approach fosters collaboration among specialists, allowing us to address a wide range of medical conditions comprehensively. We understand that health is not merely the absence of disease, and thus, our healthcare professionals strive to promote wellness and preventive measures alongside addressing acute and chronic illnesses.",
        ServicePointHead03: "Patient centric care ",
        ServicePointPara03: "Patient-centric care is the heartbeat of our department. From the moment a patient enters our facility, they are met with a warm and welcoming environment. We prioritize clear communication, ensuring that patients and their families are actively involved in decision-making regarding their healthcare journey.",
        ServicePointPara04: "In addition to clinical care, our general physician in Andheri actively engages in medical research and education, contributing to the advancement of medical knowledge and fostering the next generation of healthcare professionals. Our commitment extends beyond the hospital walls as we actively participate in community outreach programs, promoting health awareness and preventive measures.",
        ServicePointPara05: "In essence, the General Medicine Department at our hospital stands as a bastion of health, blending expertise with empathy to provide unparalleled medical care to our diverse community. We are not just healing individuals; we are nurturing a healthier, happier community, one patient at a time.",
    },
    {
        id: 9,
        ServiceName: 'Respiratory Medicine',
        HeadTag: "Best Pulmonology Hospitals in Andheri",
        MetaTitle: "Best Pulmonology Hospitals in Andheri | Thunga Hospital",
        MetaDes:"Best Pulmonology Hospitals in Andheri offering expert respiratory care. Our specialized facilities ensure comprehensive treatment for respiratory conditions.",
        canonical:"https://thungasthhospital.com/best-pulmonology-hospitals-andheri",
        ServiceSlug: "/best-pulmonology-hospitals-andheri",
        ServiceImages: PlasticCosmetic,
        ServiceHeadPara: "The best pulmonology hospital in Andheri is at the forefront of comprehensive and compassionate care for patients with respiratory conditions. Our dedicated team of experienced pulmonologists, respiratory therapists, and support staff is committed to providing top-notch medical services.",
        ServiceHead01: "Our Expertise",
        ServicePara01: "Our best lung doctor in Andheri specializes in the diagnosis, treatment, and management of a wide range of respiratory disorders, including asthma, chronic obstructive pulmonary disease (COPD), interstitial lung diseases, and pulmonary infections.",
        ServicePointHead01: "Our facilities",
        ServicePointPara01: "Our cutting-edge facilities enable us to perform state-of-the-art diagnostic tests, such as pulmonary function tests, bronchoscopy, and sleep studies, ensuring accurate and timely assessments.Collaboration is at the heart of our approach. Our multidisciplinary team works closely to tailor personalized treatment plans for each patient, considering their unique medical history and needs. We prioritize patient education, empowering individuals to actively participate in their care and make informed decisions regarding their respiratory health.",
        ServicePointPara02: "Research is a key component of our department's identity. Our best lung doctor in Andheri actively engaged in clinical trials and studies to advance our understanding of respiratory diseases and explore innovative treatment options. This commitment to research allows us to stay at the forefront of medical advancements and offer our patients the most up-to-date and effective interventions.",
        ServicePointPara03: "At our Respiratory Medicine Department, we not only focus on treating existing conditions but also emphasize preventive care. Our wellness programs and outreach initiatives aim to raise awareness about respiratory health in the community, promoting early detection and proactive management of respiratory issues.",
        ServicePointPara04: "In summary, our Respiratory Medicine Department is dedicated to providing comprehensive, patient-centered care, fostering collaboration among healthcare professionals, advancing research in the field, and promoting community-wide respiratory health awareness.",
    },
    {
        id: 10,
        ServiceName: 'Vascular Intervention',
        HeadTag: "Vascular Surgery Hospitals in Andheri",
        MetaTitle: "Vascular Surgery Hospitals in Andheri | Thunga Hospital",
        MetaDes:"Discover top-notch Vascular Surgery Hospitals in Andheri. Our expert care ensures comprehensive solutions for your vascular health.",
        canonical:"https://thungasthhospital.com/vascular-surgery-hospitals-andheri",
        ServiceSlug: "/vascular-surgery-hospitals-andheri",
        ServiceImages: GISurgery,
        ServiceHeadPara: "Welcome to the Vascular surgery hospital in Andheri ,where our mission is to redefine vascular care through expertise, innovation, and unwavering commitment to patient well-being.Our department stands at the forefront of vascular medicine, boasting a team of highly skilled specialists dedicated to delivering exceptional care. With a comprehensive approach to vascular health, we address a spectrum of conditions, from arterial and venous disorders to complex vascular malformations.",
        ServicePara01: "At the heart of our success is a state-of-the-art facility equipped with cutting-edge technology. We have the best vascular surgeons in Andheri. Our interventional radiologists, vascular surgeons, and support staff collaborate seamlessly to provide accurate diagnoses and tailored treatment plans. We pride ourselves on staying at the forefront of medical advancements, ensuring our patients benefit from the latest breakthroughs in vascular interventions.",
        ServicePointHead01: "Patient centric care ",
        ServicePointPara01: "What sets us apart is our patient-centric philosophy. We understand that each individual is unique, requiring personalized care. From minimally invasive procedures to complex interventions, our team is dedicated to optimizing outcomes while minimizing discomfort and recovery time.",
        ServicePointPara02: "As advocates for preventive care, we emphasize the importance of early detection and intervention. Through ongoing research and education, we strive to raise awareness about vascular health in our community, empowering individuals to take control of their well-being.",
        ServicePointPara03: "Beyond clinical excellence, we prioritize compassionate and collaborative care. Our team believes in open communication, ensuring that patients and their families are well-informed and actively involved in the decision-making process. We understand that navigating vascular issues can be challenging, and we are here to provide support every step of the way.",
        ServicePointPara04: "In the Vascular Intervention Department, we don't just treat conditions; we foster a culture of continuous improvement and dedication to advancing the field of vascular medicine. Join us on this journey towards better vascular health, where expertise meets compassion for a brighter and healthier future.",
    },
    {
        id: 11,
        ServiceName: 'Laparoscopy & General Surgery',
        HeadTag: "Laparoscopic Surgery in Andheri",
        MetaTitle: "Laparoscopic Surgery in Andheri | Thunga Hospital",
        MetaDes:"Discover advanced Laparoscopic Surgery in Andheri by skilled surgeons. Our precision procedures ensure optimal results. ",
        canonical:"https://thungasthhospital.com/laparoscopic-surgery-andheri",
        ServiceSlug: "/laparoscopic-surgery-andheri",
        ServiceImages: GISurgery,
        ServiceHeadPara: "Welcome to our Laparoscopic surgery hospital in Andheri, where cutting-edge medical care meets compassion. Our hospital is proud to house a state-of-the-art facility dedicated to delivering excellence in laparoscopic and general surgical procedures.",
        ServicePara01: "At the heart of our department of Laparoscopic surgery hospital in Andheri is a team of highly skilled and experienced surgeons committed to providing the highest standard of care. Our specialists are proficient in a wide range of minimally invasive laparoscopic procedures, ensuring precision, reduced recovery times, and minimal scarring for our patients.",
        ServicePara02: "We boast a comprehensive approach to general surgery, addressing a spectrum of conditions with expertise and innovation. From routine appendectomies to complex abdominal surgeries, our team is well-equipped to handle diverse surgical needs. Our commitment to staying at the forefront of medical advancements ensures that our patients benefit from the latest technologies and methodologies available in the field.",
        ServicePara03: "In addition to our skilled surgical team, our department is supported by a dedicated and compassionate nursing and administrative staff. We understand that undergoing surgery can be a challenging experience, and we prioritize creating a supportive and reassuring environment for our patients.Our facility is equipped with cutting-edge diagnostic and surgical equipment, enabling us to deliver precise and effective interventions. Patient safety is paramount, and we adhere to rigorous standards to maintain a sterile and secure environment.",
        ServicePara04: "As part of our commitment to holistic healthcare, we emphasize patient education. Our team takes the time to explain procedures, answer questions, and ensure that patients are well-informed and actively involved in their healthcare journey.",
        ServicePara05: "Choosing our Laparoscopy and General Surgery Department means choosing excellence, innovation, and a compassionate approach to healthcare. We take pride in being a trusted destination for surgical care, dedicated to improving the lives of our patients through advanced and personalized treatments.",
    },
    {
        id: 12,
        ServiceName: 'Gastroenterology',
        HeadTag: "Best Gastroenterology Hospitals in Andheri",
        MetaTitle: "Best Gastroenterology Hospitals in Andheri | Thunga Hospital",
        MetaDes:"Discover excellence in digestive health at the Best Gastroenterology Hospitals in Andheri. Our expert medical professionals provide comprehensive care.",
        canonical:"https://thungasthhospital.com/best-gastroenterology-hospitals-andheri",
        ServiceSlug: "/best-gastroenterology-hospitals-andheri",
        ServiceImages: GISurgery,
        ServiceHeadPara: "The best  Gastroenterology  hospitals in Andheri is a dedicated hub of expertise, committed to providing comprehensive and compassionate care for digestive health. Our team of highly skilled gastroenterologists, nurses, and support staff work collaboratively to address a wide range of gastrointestinal conditions with precision and empathy.",
        ServiceHead01: "Our facilities",
        ServicePara01: "Our best gastroenterologist in Andheri gives you the best treatment With cutting-edge technology and state-of-the-art facilities, we offer advanced diagnostic procedures and innovative treatments tailored to each patient's unique needs. From routine screenings to complex interventions, our department is equipped to handle various gastrointestinal disorders, including but not limited to, digestive tract infections, inflammatory bowel diseases, liver disorders, and pancreatic conditions.",
        ServicePointHead01: "Our mission",
        ServicePointPara01: "Patient well-being is at the forefront of our mission. Our caring and knowledgeable professionals prioritize open communication, ensuring that patients and their families are informed and actively involved in their healthcare journey. We strive to create a comfortable and supportive environment, fostering trust and confidence in the care we provide.",
        ServicePointPara02: "Collaboration is a cornerstone of our approach. We engage in interdisciplinary cooperation, working closely with other specialties to offer comprehensive healthcare solutions. Our commitment extends beyond treatment; we emphasize preventive care and patient education, empowering individuals to make informed choices for long-term digestive health.",
        ServicePointPara03: "Research and continuous learning are integral to our department's ethos. We stay at the forefront of medical advancements, participating in clinical trials and staying abreast of emerging treatments. This commitment to excellence ensures that our patients receive the most up-to-date and effective care available.",
        ServicePointPara04: "At the Gastroenterology hospital in Andheri, we don't just treat conditions; we care for individuals. Our team is dedicated to improving the quality of life for our patients, addressing their unique needs with expertise, empathy, and a personalized touch.",
    },
    {
        id: 13,
        ServiceName: 'Oncology & Onco Surgery',
        HeadTag: "Best Hospital for Cancer Surgery in Andheri",
        MetaTitle: "Best Hospital for Cancer Surgery in Andheri | Thunga Hospital",
        MetaDes:"Discover unparalleled expertise and compassionate care at the Best Hospital for Cancer Surgery in Andheri. Our dedicated team is committed to treatment .",
        canonical:"https://thungasthhospital.com/best-hospital-cancer-surgery-andheri",
        ServiceSlug: "/best-hospital-cancer-surgery-andheri",
        ServiceImages: GISurgery,
        ServiceHeadPara: "The Oncology and Onco surgery Department at our best hospital for cancer surgery in Andheri is a beacon of hope and healing for those navigating the challenging journey of cancer. Comprising a multidisciplinary team of renowned oncologists, surgeons, nurses, and support staff, we are dedicated to providing comprehensive and compassionate care.",
        ServicePointHead01: "Our facilities",
        ServicePointPara01: "With the best oncologist surgeon doctor in Andheri, Our department is equipped with state-of-the-art facilities, cutting-edge technology, and innovative treatment modalities to ensure that our patients receive the highest standard of care. From early detection to advanced surgical interventions, we cover the entire spectrum of cancer care with a patient-centric approach.",
        ServicePointPara02: "Our experienced oncologists surgeon doctor in Andheri specialize in diagnosing various types of cancers, tailoring treatment plans to individual needs.",
        ServicePointPara03: "Collaborating seamlessly with our skilled onco surgeons, we strive to optimize outcomes while prioritizing the quality of life for our patients. Whether it's surgery, chemotherapy, immunotherapy, or a combination of treatments, we are committed to personalized, evidence-based care.Beyond medical expertise, we understand the emotional and psychological toll that cancer can take. Our supportive team is here to guide and comfort patients and their families throughout every step of their journey. We believe in fostering a sense of community, providing resources for education, and promoting a holistic approach to well-being.",
        ServicePointPara04: "Research is a cornerstone of our department, with a commitment to advancing the understanding of cancer and exploring innovative treatments. We actively participate in clinical trials, contributing to the global effort to conquer this formidable disease.",
        ServicePointPara05: "In the Oncology and Oncosurgery Department, our mission is clear: to empower our patients in their fight against cancer and to continuously strive for excellence in clinical care, research, and support services. Together, we stand against cancer, united in the pursuit of healing and hope.",
    },
    {
        id: 14,
        ServiceName: 'ENT / Ophthalmology',
        HeadTag: "Best ENT Hospitals in Andheri",
        MetaTitle: "Best ENT Hospitals in Andheri | Thunga Hospital",
        MetaDes:"Explore the Best ENT Hospitals in Andheri renowned for superior ear, nose & throat healthcare. Find expert specialists & cutting-edge treatments for ENT.",
        canonical:"https://thungasthhospital.com/best-ent-hospitals-andheri",
        ServiceSlug: "/best-ent-hospitals-andheri",
        ServiceImages: GISurgery,
        ServiceHeadPara: "Welcome to the best ENT hospitals in Andheri, The bustling hub of healing within our hospital, the ENT (Ear, Nose, and Throat) department stands as a beacon of expertise and compassionate care. Our dedicated team of skilled ENT specialists is committed to addressing a myriad of ear, nose, and throat conditions with precision and empathy.Equipped with state-of-the-art technology, our department offers comprehensive diagnostic and therapeutic services. From hearing evaluations and sinus treatments to vocal cord interventions, we strive to provide personalized care tailored to each patient's unique needs.",
        ServicePara01: "Collaboration is at the heart of our approach. Our multidisciplinary team, comprising otolaryngologists, audiologists, speech therapists, and nurses, works seamlessly to deliver integrated care. This ensures a holistic understanding of the patient's health and a collaborative strategy for optimal outcomes.",
        ServicePointHead01: "Patient centric care",
        ServicePointPara01: "Patient education is a cornerstone of our practice. We believe in empowering individuals with knowledge about their conditions and treatment options, fostering a sense of partnership in the healing process. This commitment to education extends beyond the clinic, as we engage in community outreach programs to raise awareness about ENT health and prevention.",
        ServicePointPara02: "At the ENT department, we have the best ENT doctor in Andheri. We continually explore cutting-edge advancements in ENT care, staying at the forefront of medical technology to deliver the best possible outcomes for our patients. Our team's dedication to ongoing professional development ensures that we provide care based on the latest evidence and best practices.",
        ServicePointPara03: "In summary, the ENT department in our hospital is more than a medical facility; it is a haven where expertise, technology, and compassion converge to enhance the quality of life for those seeking relief from ear, nose, and throat ailments.",
    },
    {
        id: 15,
        ServiceName: 'Endocrinology / Advanced Diabetic Foot Care',
        HeadTag: "Diabetic Foot Care Hospital in Andheri",
        MetaTitle: "Diabetic Foot Care Hospital in Andheri | Thunga Hospital",
        MetaDes:"Discover a comprehensive Diabetic Foot Care Hospital in Andheri. Our expert podiatrists provide advanced services for diabetes-related foot conditions.",
        canonical:"https://thungasthhospital.com/diabetic-foot-care-hospital-andheri",
        ServiceSlug: "/diabetic-foot-care-hospital-andheri",
        ServiceImages: GISurgery,
        ServiceHeadPara: "The Endocrinology the diabetic food care hospital in Andheri is a dedicated hub of expertise, compassion, and cutting-edge care. Comprising a team of skilled endocrinologists, nurses, and support staff, we are committed to addressing a wide array of endocrine disorders with precision and empathy.",
        ServicePointHead01: "Specialist",
        ServicePointPara01: "Our department specializes in the diagnosis and management of conditions such as diabetes, thyroid disorders, hormonal imbalances, and more. We have the best endocrinologist in Andheri Equipped with state-of-the-art technology and diagnostic tools, we ensure accurate assessments for personalized treatment plans.",
        ServicePointPara02: "Collaboration is at the core of our approach. Our multidisciplinary team works seamlessly to provide comprehensive care, considering not only the medical aspects but also the unique needs and lifestyles of our patients. From advanced hormonal therapies to diabetes education, we strive to empower individuals to actively participate in their health journey.",
        ServicePointPara03: "Patient education is a focal point of our efforts. We believe that informed patients make healthier choices, so we dedicate time to educate on lifestyle modifications, medication management, and the latest advancements in endocrinology.",
        ServicePointPara04: "Research is another cornerstone of our department. By staying at the forefront of medical advancements, we contribute to the evolution of endocrine care, ultimately improving outcomes for our patients. Our commitment to excellence extends beyond the clinic, fostering an environment that nurtures learning and innovation.",
        ServicePointPara05: "At the heart of our mission is a dedication to enhancing the quality of life for individuals grappling with endocrine disorders. We understand the profound impact these conditions can have on daily life, and our team is here to provide support, guidance, and unparalleled medical expertise. Together, we are shaping a healthier future through comprehensive and compassionate endocrine care.",
    },
    {
        id: 16,
        ServiceName: 'Plastic & Cosmetic Surgery',
        HeadTag: "Cosmetic Surgery Clinics in Andheri",
        MetaTitle: "Cosmetic Surgery Clinics in Andheri | Thunga Hospital",
        MetaDes:"Discover the epitome of aesthetic transformation with leading Cosmetic Surgery Clinics in Andheri. Our expert surgeons redefine beauty and confidence.",
        canonical:"https://thungasthhospital.com/cosmetic-surgery-clinics-andheri",
        ServiceSlug: "/cosmetic-surgery-clinics-andheri",
        ServiceImages: GISurgery,
        ServiceHeadPara: "Welcome to our cutting-edge cosmetic surgery clinic in Andheri , where artistry meets precision in enhancing natural beauty and restoring confidence. Committed to providing unparalleled care, our skilled team of board-certified plastic surgeons combines artistic finesse with advanced medical expertise.",
        ServicePara01: "At the forefront of innovation, our department is equipped with state-of-the-art facilities, ensuring the highest standards of safety and comfort for our patients. We offer a comprehensive range of cosmetic and reconstructive procedures, from subtle enhancements to transformative surgeries.",
        ServicePara02: "Our plastic surgery clinic in Andheri, renowned for their excellence and dedication, approach each case with a personalized touch, understanding the unique goals and concerns of every individual. Whether it's a cosmetic procedure to refine features or a reconstructive surgery to restore form and function, our team at plastic surgery clinic in Andheri is dedicated to achieving optimal results.Patient education is a cornerstone of our practice. We prioritize open communication, guiding patients through every step of their journey. From the initial consultation to post-operative care, our team fosters a supportive environment where questions are welcomed, and concerns are addressed with empathy and expertise.",
        ServicePara03: "Emphasizing ethical practices and patient well-being, we adhere to the highest standards of medical ethics. Our commitment extends beyond surgical excellence to holistic care, promoting overall health and self-esteem.",
        ServicePara04: "As advocates for continuous learning and advancement, our plastic surgeons stay at the forefront of emerging techniques and technologies, ensuring that our patients benefit from the latest developments in the field.",
        ServicePara05: "Welcome to a place where transformation goes hand in hand with compassionate care – a testament to our unwavering commitment to helping individuals rediscover their confidence and embrace their true beauty.",
    },
    {
        id: 17,
        ServiceName: 'Dermatology',
        HeadTag: "Best Dermatology Hospital in Andheri",
        MetaTitle: "Best Dermatology Hospital in Andheri | Thunga Hospital",
        MetaDes:"Experience top-notch skin care and dermatological services at the Best Dermatology Hospital in Andheri. Our experts provide comprehensive solutions for skin.",
        canonical:"https://thungasthhospital.com/best-dermatology-hospital-andheri",
        ServiceSlug: "/best-dermatology-hospital-andheri",
        ServiceImages: GISurgery,
        ServiceHeadPara: "Welcome to our best Dermatology hospital in Andheri, where skin health takes center stage. Committed to providing comprehensive and compassionate care, our team of experienced dermatologists, nurses, and support staff strive to enhance the well-being of every patient.",
        ServicePara01: "At the heart of our department is a dedication to diagnosing and treating a wide array of dermatological conditions, from common skin concerns to more complex disorders. Our dermatologist in Andheri gives you the treatment.We employ state-of-the-art technologies and stay abreast of the latest advancements in dermatology to ensure accurate diagnoses and effective treatments.Our dermatologists in Andheri bring a wealth of expertise in medical, surgical, and cosmetic dermatology. Whether you seek relief from a persistent skin condition, require a surgical procedure, or are interested in rejuvenating your skin's appearance, our team is here for you. We tailor our approach to meet individual needs, recognizing that every patient is unique.",
        ServicePara02: "Collaboration is key in our department, and we work closely with other medical specialties to provide holistic care. Our commitment extends beyond treatment to education, empowering patients with knowledge to make informed decisions about their skin health. We believe in fostering a supportive and informative environment, answering questions and addressing concerns to ensure a positive experience for our patients.",
        ServicePara03: "As advocates for skin health, we are actively involved in community outreach and awareness programs. Our goal is not only to treat dermatological conditions but also to promote preventive care and skin wellness.Your journey to healthy skin begins here, at our Dermatology Department. Trust us to prioritize your well-being and provide the personalized care you deserve.",
    },
];

export default SpecialitiesData;