import ThungaHospital from "../img/Other/Thunga Hospital.JPG";
import enterance1 from "../img/Hospital/entrance/entrance 14.JPG";
import enterance2 from "../img/Hospital/entrance/entrance 03.JPG";
import Billing from "../img/Hospital/entrance/entrance 06.JPG";
import Reception01 from "../img/Hospital/entrance/entrance 11.JPG";
import Reception02 from "../img/Other/resorption 1.JPG";
import WaitingArea01 from "../img/Hospital/waiting area/waiting area 02.JPG";
import WaitingArea02 from "../img/Hospital/waiting area/waiting area 03.JPG";
import WaitingArea03 from "../img/Hospital/waiting area/waiting area 04.JPG";
import physician01 from "../img/Hospital/physician/physician 05.JPG";
import physician02 from "../img/Hospital/physician/physician 06.JPG";
import ICU01 from "../img/Hospital/ICU/ICU 01.JPG";
import ICU02 from "../img/Hospital/ICU/ICU 02.JPG";
import ICU03 from "../img/Hospital/ICU/ICU 03.JPG";
import ICU04 from "../img/Hospital/ICU/ICU 04.JPG";
import OT01 from "../img/Hospital/OT/OT 01.JPG";
import OT02 from "../img/Hospital/OT/OT 02.JPG";
import OT03 from "../img/Hospital/OT/OT 03.JPG";
import OT04 from "../img/Hospital/OT/OT 04.JPG";
import OT05 from "../img/Hospital/OT/OT 05.JPG";
import Pathology01 from "../img/Hospital/Pathology/Pathology 01.JPG";
import Pathology02 from "../img/Hospital/Pathology/Pathology 02.JPG";
import Pathology03 from "../img/Hospital/Pathology/Pathology 03.JPG";
import Pathology04 from "../img/Hospital/Pathology/Pathology 04.JPG";
import Xray01 from "../img/Hospital/X ray/X ray 01.JPG";
import Xray02 from "../img/Hospital/X ray/X ray 04.JPG";
import DeluxeRoom01 from "../img/Hospital/Deluxe Room/Deluxe Room 01.JPG";
import DeluxeRoom02 from "../img/Hospital/Deluxe Room/Deluxe Room 02.JPG";
import DeluxeRoom03 from "../img/Hospital/Deluxe Room/Deluxe Room 05.JPG";
const GalleryData = [
    {
        id: 1,
        gImage: ThungaHospital,
        gimagename: "Thunga Hospital",
    },
    {
        id: 1,
        gImage: enterance1,
        gimagename: "Entrance",
    },
    {
        id: 1,
        gImage: enterance2,
        gimagename: "Entrance",
    },
    {
        id: 1,
        gImage: Billing,
        gimagename: "Billing",
    },
    {
        id: 1,
        gImage: Reception01,
        gimagename: "Reception",
    },
    {
        id: 1,
        gImage: Reception02,
        gimagename: "Reception",
    },
    {
        id: 11,
        gImage: WaitingArea01,
        gimagename: "Waiting Area",
    },
    {
        id: 11,
        gImage: WaitingArea02,
        gimagename: "Waiting Area",
    },
    {
        id: 11,
        gImage: WaitingArea03,
        gimagename: "Waiting Area",
    },
    {
        id: 11,
        gImage: physician01,
        gimagename: "Consultation Room",
    },
    {
        id: 11,
        gImage: physician02,
        gimagename: "Consultation Room",
    },
    {
        id: 11,
        gImage: ICU01,
        gimagename: "ICU",
    },
    {
        id: 11,
        gImage: ICU02,
        gimagename: "Recovery Room",
    },
    {
        id: 11,
        gImage: ICU03,
        gimagename: "ICU",
    },
    {
        id: 11,
        gImage: OT01,
        gimagename: "Operation Theater",
    },
    {
        id: 11,
        gImage: OT02,
        gimagename: "Operation Theater",
    },
    {
        id: 11,
        gImage: OT03,
        gimagename: "Operation Theater",
    },
    {
        id: 11,
        gImage: OT04,
        gimagename: "Operation Theater",
    },
    {
        id: 11,
        gImage: OT05,
        gimagename: "Operation Theater",
    },
    {
        id: 11,
        gImage: Pathology01,
        gimagename: "Pathology",
    },
    {
        id: 11,
        gImage: Pathology02,
        gimagename: "Pathology",
    },
    {
        id: 11,
        gImage: Pathology03,
        gimagename: "Pathology",
    },
    {
        id: 11,
        gImage: Xray01,
        gimagename: "X-ray",
    },
    {
        id: 11,
        gImage: Xray02,
        gimagename: "X-ray",
    },
    {
        id: 11,
        gImage: DeluxeRoom01,
        gimagename: "Deluxe Room",
    },
    {
        id: 11,
        gImage: DeluxeRoom02,
        gimagename: "Deluxe Room",
    },
    {
        id: 11,
        gImage: DeluxeRoom03,
        gimagename: "Deluxe Room",
    },
];

export default GalleryData;