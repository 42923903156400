import React, { useLayoutEffect, useState } from 'react';
import "../Css/FacilitesDetails.css";
import { AiFillHome, AiOutlineSafety } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import FacilitiesData from '../Components/FacilitiesData';
import { Link, useLocation } from 'react-router-dom';
import Footer from "../Components/Footer";

const FacilitiesDetails = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const search = useLocation();
  const Path = search.pathname;

  const [serviceInfo, setServiceInfo] = useState(FacilitiesData);
  const ServiceDataFinal = serviceInfo.find(e => e.FacilitiesSlug == Path)


  return (
    <>
      {/* ---------------- Section 1 ---------------------- */}
      <section className='FacilitiesDetails-Section-1'>
        <div className='FacilitiesDetails-Section-con'>
          <div className='FacilitiesDetails-Section-Text'>
            <h1>
              {ServiceDataFinal.HeadTag}
            </h1>
            <div className='FacilitiesDetails-Section-NavPage'>
            <span><Link to="/"><AiFillHome />Home</Link></span>
              <h3><MdKeyboardArrowRight /></h3>
              <h2>{ServiceDataFinal.FacilitiesName}</h2>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////////////// Section 2/////////////////////////////////// */}

      <section className='FacilitiesDetails-Section-2'>
        <div className='container'>
          <div className="row">
            <div className="col-md-8">
              <div className="Service-Section2-Details-con">
                <div className="Service-Section2-Details1-con">
                  <h1>{ServiceDataFinal.FacilitiesName}</h1>
                  <p>
                    {ServiceDataFinal.FacilitiesMaindes}
                  </p>
                </div>
                <div className="Service-Section2-Detail2-con">
                  <h3>{ServiceDataFinal.FacilitiesMissionTitle}</h3>
                  <p>
                    {ServiceDataFinal.FacilitiesMissionDes}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="Service-Section-Details-right-con">
                <div className="Service-Section-Details-right-Heading">
                  <h2>
                    Exceptional Multispecialty Hospital Services for Comprehensive Care
                  </h2>
                </div>
                <div className="Department-Section-Details-right" id="style-1">
                  {
                    FacilitiesData.map((ele) => {
                      return (
                        <>
                          <p>
                            <Link to={ele.FacilitiesSlug}>{ele.FacilitiesName}</Link>
                          </p>
                        </>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      {/* ///////////////////////////////// Section 3/////////////////////////////////// */}

      <section className='FacilitiesDetails-Section-3'>
        <div className='container'>
          <div className='FacilitiesDetails-Section-3-con'>
            <div className='FacilitiesDetailsFirstPoint'>
              <h2>{ServiceDataFinal.FacilitiesPoints01Head}</h2>

              <p>{ServiceDataFinal.FacilitiesPoints01des01}</p>
              <p>{ServiceDataFinal.FacilitiesPoints01des02}</p>
              <p>{ServiceDataFinal.FacilitiesPoints01des03}</p>
              <p>{ServiceDataFinal.FacilitiesPoints01des04}</p>
              <p>{ServiceDataFinal.FacilitiesPoints01des05}</p>
              <p>{ServiceDataFinal.FacilitiesPoints01des06}</p>
            </div>

            <div className='FacilitiesDetailsSecondPoint'>
              <h2>{ServiceDataFinal.FacilitiesPointsHead}</h2>
              <h3>{ServiceDataFinal.FacilitiesPointsHead01}</h3>
              <p>{ServiceDataFinal.FacilitiesPointsDes01}</p>
              <h3>{ServiceDataFinal.FacilitiesPointsHead02}</h3>
              <p>{ServiceDataFinal.FacilitiesPointsDes02}</p>
              <h3>{ServiceDataFinal.FacilitiesPointsHead03}</h3>
              <p>{ServiceDataFinal.FacilitiesPointsDes03}</p>
              <h3>{ServiceDataFinal.FacilitiesPointsHead04}</h3>
              <p>{ServiceDataFinal.FacilitiesPointsDes04}</p>
              <h3>{ServiceDataFinal.FacilitiesPointsHead05}</h3>
              <p>{ServiceDataFinal.FacilitiesPointsDes05}</p>
              <h3>{ServiceDataFinal.FacilitiesPointsHead06}</h3>
              <p>{ServiceDataFinal.FacilitiesPointsDes06}</p>
              <h3>{ServiceDataFinal.FacilitiesPointsHead07}</h3>
              <p>{ServiceDataFinal.FacilitiesPointsDes07}</p>
            </div>
            <p>{ServiceDataFinal.FacilitiesEndPara01}</p>
            <p>{ServiceDataFinal.FacilitiesEndPara02}</p>
            <p>{ServiceDataFinal.FacilitiesEndPara03}</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default FacilitiesDetails