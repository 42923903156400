import React from 'react';
import "../Css/Media.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
    
const Media = () => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='Media-Section-1'>
                <div className='Media-Section-con'>
                    <div className='Media-Section-Text'>
                        <h2>
                            Media
                        </h2>
                        <div className='Media-Section-NavPage'>
                            <span><AiFillHome />Home</span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>Media</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 2 ---------------------- */}

            <section className='Event-Section'>
                <div className='container'>
                    <div className="Event-Section-tab-con">
                    

                    </div>
                </div>
            </section>



        </>
    )
}

export default Media