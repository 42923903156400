import React from 'react'
import { BsCaretRightFill, BsFillCaretLeftFill } from 'react-icons/bs';
import { GiCrossMark } from 'react-icons/gi';
import "../Css/Model.css"

const Model = ({ clickImage, handelRotationRight, setClickImage, handelRotationLeft }) => {
    const handelClick = (e) => {
        console.log("hjkl;")
        if (e.target.classList.contains("dismiss")) {
            setClickImage(null)
        }
    }
    return (
        <>

            <div className='overlay dismiss' onClick={handelClick}>
                <img src={clickImage} alt="bigger piture" />
                <div onClick={handelRotationRight} className="overlay-arrows_left">
                    <BsFillCaretLeftFill />
                </div>
                <div onClick={handelRotationLeft} className="overlay-arrows_right">
                    <BsCaretRightFill />
                </div>
            </div>
        </>
    )
}

export default Model