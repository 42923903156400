import BoyDoctor from "../img/Doctor/Doctor Boy.jpg";
import BoyGirls from "../img/Doctor/Doctor Girls.jpg";



import DrAkhilAshokTawari from "../img/Doctor/Dr. Akhil Ashok Tawari.png";
import DrAlpesPanchal from "../img/Doctor/Dr. Alpes Panchal.webp";
import DrAmitJain from "../img/Doctor/Dr. Amit Jain.webp";
import DrAmitPGawnde from "../img/Doctor/Dr. Amit P Gawnde.webp";
import DrAnujTiwari from "../img/Doctor/Dr. Anuj Tiwari.webp";
import DrBhuwaniaPuneetSanjayKumar from "../img/Doctor/Dr. Bhuwania Puneet Sanjay Kumar.webp";
import DrChintanHegde from "../img/Doctor/Dr. Chintan Hegde.webp";
import DrDarpanJThakre from "../img/Doctor/Dr. Darpan J. Thakre.webp";
import GauravBhandari from "../img/Doctor/Dr. Gaurav Bhandari.jpg";
import HarshParekh from "../img/Doctor/Dr. Harsh Parekh.jpg";
import JatinKrishnaRai from "../img/Doctor/Dr. Jatin Krishna Rai.webp";
import JohannVarghese from "../img/Doctor/Dr. Johann Varghese.jpg";
import LatikaChawla from "../img/Doctor/Dr. Latika Chawla.jpg";
import NehaDalal from "../img/Doctor/Dr. Neha Dalal.webp";
import NidhiKhandelwal from "../img/Doctor/Dr. Nidhi Khandelwal.jpg";
import OjasPotdar from "../img/Doctor/Dr. Ojas Potdar.webp";
import RahulBahot from "../img/Doctor/Dr. Rahul Bahot.webp";
import SalaahQureshi from "../img/Doctor/Dr. Salaah Qureshi.webp";
import SambitMNanda from "../img/Doctor/Dr. Sambit M Nanda.webp";
import SanjeedCPujary from "../img/Doctor/Dr. Sanjeed C Pujary.jpg";
import SarikaHolmukhe from "../img/Doctor/Dr. Sarika Holmukhe.jpg";
import ShashikantMashal from "../img/Doctor/Dr. Shashikant Mashal.webp";
import ShradhaMaheshwari from "../img/Doctor/Dr. Shradha Maheshwari.jpg";
import SwatiKapadia from "../img/Doctor/Dr. Swati Kapadia.webp";
import VinaykumarThati from "../img/Doctor/Dr. Vinaykumar Thati.jpg";
import YessuKrishnaShetty from "../img/Doctor/Dr. Yessu Krishna Shetty.jpg";
import YogenChheda from "../img/Doctor/Dr. Yogen Chheda.webp";
import SamirBagadia from "../img/Doctor/Dr Samir Bagadia.webp";
import RajeevPunjabi from "../img/Doctor/Dr. Rajeev Punjabi.webp"
const DoctorData = [
    {
        DoctorSlug:"/neuro-physician-dr-nadaf",
        DoctorName: "Dr. Nadaf Swaleha Nurulla",
        Speciality: "Neuro Physician",
        DoctorImage: BoyDoctor,
        DoctorSpecialist: "Neurologist",
        DoctorEducation: "MBBS, MD - General Medicine, DM - Neurology",
        DoctorExperience: "13 Years Experience Overall  (5 years as specialist)",
        DoctorDes01: "Dr. Nadaf Swaleha Nurulla is a Neurologist in Malad West, Mumbai and has an experience of 13 years in this field. Dr. Nadaf Swaleha Nurulla practices at Thunga Hospital in Malad West, Mumbai. She completed MBBS from Maharashtra Universtity of Health Sciences, Nashik in 2010,MD - General Medicine from Maharashtra Universtity of Health Sciences, Nashik in 2014 and DM - Neurology from Maharashtra Universtity of Health Sciences, Nashik in 2018.",
    },
    {
        DoctorSlug:"/spine-surgeon-dr-akhil",
        DoctorName: "Dr. Akhil Ashok Tawari",
        Speciality: "Spine Surgeon",
        DoctorImage: DrAkhilAshokTawari,
        DoctorSpecialist: "Orthopedic surgeon",
        DoctorEducation: "MS - Orthopaedics, DNB - Orthopedics/Orthopedic Surgery, MBBS",
        DoctorExperience: "15 years experience overall",
        DoctorDes01: "Dr. Akhil Ashok Tawari is a Orthopedic surgeon,Spine Surgeon (Ortho) and Spine And Pain Specialist in Worli, Mumbai and has an experience of 15 years in these fields. Dr. Akhil Ashok Tawari practices at SRCC Children's Hospital in Worli, Mumbai and Thunga Hospital in Malad West, Mumbai. He completed Clinical Fellowship in Adult & Paediatric Spine Surgery from Boston School of Medicine in 2012,MS - Orthopaedics from Lokmanya Tilak Municipal Medical College, Sion,Mumbai in 2012 and Clinical Fellowship in Pediatric Spine Surgery from Miami Children's Hospital in 2012.He is a member of Association of Spine Surgeons of India (ASSI),BOMBAY ORTHOPAEDIC SOCIETY,NASS - North American Spine Society and Bombay Spine Club. Some of the services provided by the doctor are: Spinal Deformity Correction,Spinal Fusion,Disc Prolapse,Scoliosis Correction and Spine etc",
    },
    {
        DoctorSlug:"/orthopedic-surgeon-dr-sanjeed",
        DoctorName: "Dr. Sanjeed C Pujary",
        Speciality: "Orthopedic Surgeon",
        DoctorImage: SanjeedCPujary,
        DoctorSpecialist: "Orthopedic surgeon, Joint Replacement Surgeon",
        DoctorEducation: "MBBS, MS - Orthopaedics",
        DoctorExperience: "16 years experience overall",
        DoctorDes01: "Dr. Sanjeed C Pujary is a Orthopedic surgeon and Joint Replacement Surgeon in Andheri West, Mumbai and has an experience of 16 years in these fields. Dr. Sanjeed C Pujary practices at Practo Care Surgeries in Andheri West, Mumbai and Thunga Hospital in Malad West, Mumbai. He completed MBBS from Rajiv Gandhi University of Health Sciences in 2007 and MS - Orthopaedics from Yeneopaya University in 2012.He is a member of Indian Orthopaedic Association,Indian Medical Association (IMA) and BOMBAY ORTHOPAEDIC SOCIETY. Some of the services provided by the doctor are: Fracture Treatment,Hip Replacement,Revision Hip and Knee Arthroplasty,Hip Arthroplasty and Knee Arthroplasty etc",
    },
    {
        DoctorSlug:"/e-n-t-dr-shashikant",
        DoctorName: "Dr. Shashikant Mashal",
        Speciality: "E.N.T",
        DoctorImage: ShashikantMashal,
        DoctorSpecialist: "ENT/ Otorhinolaryngologist",
        DoctorEducation: "MBBS, MS - ENT",
        DoctorExperience: "21 Years Experience Overall  (14 years as specialist)",
        DoctorDes01: "Dr. Shashikant Mashal is a ENT/ Otorhinolaryngologist in Sion, Mumbai and has an experience of 21 years in this field. Dr. Shashikant Mashal practices at Sion Speciality ENT Clinic in Sion, Mumbai and Thunga Hospital in Malad West, Mumbai. He completed MBBS from Lokmanya Tilak Municipal Medical College, Sion,Mumbai in 2002 and MS - ENT from Grant Medical College and Sir JJ Hospital, Mumbai in 2007.He is a member of Association of Otolaryngologists of India (AOI). Some of the services provided by the doctor are: Vocal Cord Surgery,Reconstructive Middle Ear Surgery,Microscopic Voice Surgery,Nasal Polypectomy and Frontal Sinus Surgery etc. ",
    },
    {
        DoctorSlug:"/e-n-t-dr-yessu",
        DoctorName: "Dr. Yessu Krishna Shetty",
        Speciality: "E.N.T",
        DoctorImage: YessuKrishnaShetty,
        DoctorSpecialist: "ENT/ Otorhinolaryngologist",
        DoctorEducation: "MBBS, Diploma in Otorhinolaryngology (DLO), DNB - Otorhinolaryngology",
        DoctorExperience: "15 years experience overall",
        DoctorDes01: "Dr. Yessu Krishna Shetty is an ENT/ Otorhinolaryngologist in Andheri West, Mumbai and has an experience of 13 years in this field. . He completed MBBS from K.S. Hegde Medical Academy in 2008,Diploma in Otorhinolaryngology (DLO) from J.S.S Medical College, Mysore in 2012 and DNB - Otorhinolaryngology from Natboard New Delhi in 2014.He is a member of Maharashtra Medical Council,Association of Otolaryngologists of India (AOI),AOI Mumbai,AOI MUMBAI WEST BRANCH and AOI NAVI MUMBAI BRANCH. He is always keen on updating his knowledge about the latest advances in the ever evolving surgical field to provide the latest and advanced treatment to his patients.",
    },
    {
        DoctorSlug:"/obestrician-gynec-dr-latika",
        DoctorName: "Dr. Latika Chawla",
        Speciality: "Obestrician & Gynec",
        DoctorImage: LatikaChawla,
        DoctorSpecialist: "Gynecologist, Laparoscopic Surgeon (Obs & Gyn)",
        DoctorEducation: "MBBS, DNB - Obstetrics & Gynecology, MRCOG(UK)",
        DoctorExperience: "13 years experience overall",
        DoctorDes01: "Dr Latika Chawla is an Obstetrician and Gynecological Endoscopic Surgeon at Womens Hospital, Khar (west), Mumbai.She completed her MBBS from B.J Medical college, Pune in 2010. She obtained her DNB from the National Board of Examinations in 2014 (Deenanath Mangeshkar Hospital,Pune). She has been awarded the prestigious membership of the Royal College of Obstetricians and Gynecologists, London in 2017 (MRCOG). She has been trained in Advanced Gynecological Endoscopy under Dr Rakesh Sinha and has 4 years experience in the above fields.She has National and International publications and has presented and won in Paper presentations across India. She has special interest in endometriosis surgery, urogynecology and robotics.",
    },
    {
        DoctorSlug:"/obestrician-gynec-dr-sambit",
        DoctorName: "Dr. Sambit M Nanda",
        Speciality: "Obestrician & Gynec",
        DoctorImage: SambitMNanda,
        DoctorSpecialist: "Laparoscopic Surgeon (Obs & Gyn),Gynecologist",
        DoctorEducation: "MBBS, MS - Obstetrics & Gynaecology",
        DoctorExperience: "10 Years Experience Overall  (7 years as specialist)",
        DoctorDes01: "Gynac laparoscopic surgeon training in advanced laparoscopy and gyne oncology. Trained in India and germany. Special interests in infertility, benign gynecology, gyne oncology and uro gynaecology. Youngest member of the world's first lap assisted uterine transplant.",
    },
    {
        DoctorSlug:"/obestrician-gynec-dr-swati",
        DoctorName: "Dr. Swati Kapadia",
        Speciality: "Obestrician & Gynec",
        DoctorImage: SwatiKapadia,
        DoctorSpecialist: "Gynecologist,Obstetrician,Laparoscopic Surgeon (Obs & Gyn),Infertility Specialist,General Physician",
        DoctorEducation: "MBBS, DGO",
        DoctorExperience: "33 Years Experience Overall  (16 years as specialist)",
        DoctorDes01: "Dr. Swati Kapadia is a Gynaecologist, Obstetrician, Laparoscopic Surgeon and Infertility Specialist in Perungudi, Chennai with an experience of 16 years. She completed her MBBS from Dr. Panjabrao Deshmukh Memorial Medical College, Amravati, Maharashtra and Masters from Culcutta University. She got a fellowship in Laparoscopy from World Laparoscopy centre, Delhi. Later she moved to Germany to finish her Diploma in Reproductive Medicine (Germany) - Kiel University. She is an active member of European Society of Human Reproduction and Embryology (ESHRE) and Federation of Obstetric and Gynaecological Societies of India (FOGSI).",
        DoctorDes02: "She started her career in Bandari Hospital, Indoor and later she moved to Life Line Hospitals Chennai. Currently she is practicing in Sai Krupa Clinic, Perungudi, a clinic with in house Lab and Scan facility and a senior consultant at Global Hospitals in Perumbakkam and Apollo Speciality Hospitals OMR, Chennai. She is also a visiting consultant in Kamakshi Memorial Hospital, Chennai.",
        DoctorDes03: "In her service, she has done more than 1000 surgeries, deliveries, caesarean sections and IVF cycles also she did more than 500 surgeries in Laparoscopy like Laparoscopic removal of uterus (Hysterectomy), Removal of Fibroid by Laparoscopy, Laparoscopic Tubal sterilization, Tubal Recanalization and Laparoscopic Ovarian Cystectomy etc.",
        DoctorDes04: "She takes utmost care of her patients by providing world class Gynae, infertility and Laparoscopic services at affordable prices. She keeps her service to be the priority by being available for her patients all the time. She also conducts seminars to create awareness on women Gynae problems and wellness and how to face them.",
    },
    {
        DoctorSlug:"/neuro-surgeon-dr-darpan",
        DoctorName: "Dr. Darpan J. Thakre",
        Speciality: "Neuro Surgeon",
        DoctorImage: DrDarpanJThakre,
        DoctorSpecialist: "Neurosurgeon,Neurologist",
        DoctorEducation: "MBBS, MS - General Surgery, DNB - Neurosurgery",
        DoctorExperience: "15 Years Experience Overall  (11 years as specialist)",
        DoctorDes01: "Dr Darpan j. Thakre is a senior practising neurosurgeon with a vast operative experience of doing more than 5000 complicated brain and spine surgeries.He has done his masters in general surgery and DNB-neurosurgery from premier tertiary care institutes in India. He is an active life member of NSA ( India), BNA ( Mumbai), CNS (USA) and dandy walkers society ( California, USA).",
        DoctorDes02: "He has a special interest in minimally invasive skull base & paediatric neurosurgery. Other surgeries done by him are head & spine trauma/ tumours, vascular surgeries - aneurysms/ avm's, minimally invasive spine surgeries & instrumentation, trigeminal neuralgia.",
    },
    {
        DoctorSlug:"/orthopedic-surgeon-dr-chintan",
        DoctorName: "Dr. Chintan Hegde",
        Speciality: "Orthopedic Surgeon",
        DoctorImage: DrChintanHegde,
        DoctorSpecialist: "Orthopedic surgeon,Joint Replacement Surgeon",
        DoctorEducation: "MBBS, MS - Orthopaedics",
        DoctorExperience: "15 Years Experience Overall  (9 years as specialist)",
        DoctorDes01: "Dr. Chintan Hegde is a Orthopedic surgeon and Joint Replacement Surgeon in Andheri, Mumbai and has an experience of 15 years in these fields. Dr. Chintan Hegde practices at Bones & Joints Clinic in Andheri, Mumbai,Thunga Hospital in Mira Bhayandar, Mumbai and Balaji Speciality Hospital in Sakinaka, Mumbai. He completed MBBS from Rajiv Gandhi University of Health Sciences in 2008 and MS - Orthopaedics from Rajiv Gandhi University of Health Sciences in 2011.",
        DoctorDes02: "He is a member of Indian Orthopaedic Association,Indian Arthroscopy Society,Indian Society for Hip and Knee Surgeons,SociÃ©tÃ© Internationale de Chirurgie OrthopÃ©dique et de Traumatologie (SICOT) and Bombay Orthopaedic Society. Some of the services provided by the doctor are: Bankart Repair,Limb Lengthening,Arthritis Management,Joint Replacement Surgery and Physiotherapy for Sports Injury Rehabilitation etc.",
    },
    {
        DoctorSlug:"/vascular-interventional-dr-jatin",
        DoctorName: "Dr. Jatin Krishna Rai",
        Speciality: "Vascular Interventional",
        DoctorImage: JatinKrishnaRai,
        DoctorSpecialist: "Interventional Radiologist",
        DoctorEducation: "MD - Radio Diagnosis/Radiology",
        DoctorExperience: "11 Years Experience Overall",
        DoctorDes01: "Dr. Jatin Krishna Rai is a Interventional Radiologist in Malad West, Mumbai and has an experience of 11 years in this field. Dr. Jatin Krishna Rai practices at Thunga Hospital in Malad West, Mumbai and Thunga Hospital in Mira Bhayandar, Mumbai. He completed MD - Radio Diagnosis/Radiology from Rajiv Gandhi University of Health Sciences in 2011 and Fellowship in Neuro & Vascular Interventional Radiology from The Tamil Nadu Dr. M.G.R. Medical University (TNMGRMU) in 2012.",
    },
    {
        DoctorSlug:"/gi-bariatric-surgeon-dr-nidhi",
        DoctorName: "Dr. Nidhi Khandelwal",
        Speciality: "Gi Bariatric Surgeon",
        DoctorImage: NidhiKhandelwal,
        DoctorSpecialist: "General Surgeon",
        DoctorEducation: "MBBS, MS - General Surgery",
        DoctorExperience: "13 years experience overall",
        DoctorDes01: "Dr. Nidhi Khandelwal is a General Surgeon in Peddar Road, Mumbai and has an experience of 13 years in this field. Dr. Nidhi Khandelwal practices at Jaslok Hospital in Peddar Road, Mumbai and Thunga Hospital in Malad West, Mumbai. She completed MBBS from GMC Mumbai (JJ Hospital) in 2010 and MS - General Surgery from Bombay Hospital in 2014.She is a member of Maharastra Medical Council.",
    },
    {
        DoctorSlug:"/laproscopy-surgeon-dr-vinaykumar",
        DoctorName: "Dr. Vinaykumar Thati",
        Speciality: "Laproscopy Surgeon",
        DoctorImage: VinaykumarThati,
        DoctorSpecialist: "Laparoscopic Surgeon, General Surgeon, Bariatric Surgeon, Proctologist",
        DoctorEducation: "MBBS, MS - General Surgery, DNB - General Surgery",
        DoctorExperience: "22 years experience overall",
        DoctorDes01: "Dr. Vinaykumar Thati is a laparoscopy, general surgeon and gastrointestinal surgery in Tardeo, Mumbai and has an experience of 16 years in these fields. Dr. Vinaykumar Thati practices at surgery clinic in Tardeo, Mumbai. He is a member of Indian Medical Association (IMA), Indian Association Of Gastrointestinal Endo-Surgeons (IAGES) And Asia Pacific Hernia Society.",
    },
    {
        DoctorSlug:"/physician-dr-salaah",
        DoctorName: "Dr. Salaah Qureshi",
        Speciality: "Physician",
        DoctorImage: SalaahQureshi,
        DoctorSpecialist: "Consultant Physician,Special Interest in Diabetology",
        DoctorEducation: "MD - General Medicine, MBBS",
        DoctorExperience: "20 Years Experience Overall  (13 years as specialist)",
        DoctorDes01: "Specialist in Heart Disease, Diabetes, Hypertension, Infections, High Cholesterol and Critical Care, Diabetologist",
    },
    {
        DoctorSlug:"/neuro-surgeon-dr-shradha",
        DoctorName: "Dr. Shradha Maheshwari",
        Speciality: "Neuro Surgeon",
        DoctorImage: ShradhaMaheshwari,
        DoctorSpecialist: "Neurosurgeon",
        DoctorEducation: "MBBS, DNB - Neurosurgery",
        DoctorExperience: "19 years experience overall",
        DoctorDes01: "Dr. Shradha Maheshwari is third female Neurosurgeon in the city of Mumbai, currently working for S L Raheja Hopsital (A Fortis Associate), Mumbai. With over 12 years of experience in Neuro Spine Surgery, she was the 15th lady to become a Neurosurgeon in the country.Following her graduation she has worked at KEM Hospital, followed by her stint as Assistant Professor at BYL Nair Hospital and TN Medical college.",
        DoctorDes02: "Having significant exposure in Brain and Spine surgery over the years, she has special interests in Skull base surgery, Pediatric Neurosurgery and Minimally invasive brain and spine surgery.",
        DoctorDes03: "She is only female Neurosurgeon in the country who is the founder of a Neurosurgery department at a government run medical college. She is the first lady Spinal Surgeon in the city of Mumbai and is the first surgeon to perform the complex surgery on atlanto axial dislocation from anterior approach in the city of Mumbai and second in the country. She traveled around the world to propagate this technique. She is the executive committee member & Treasurer of the Neuro Spinal Surgeons Association and chairs their Women's wing.",
    },
    {
        DoctorSlug:"/urologist-dr-samir",
        DoctorName: "Dr. Samir Bagadia",
        Speciality: "Urologist",
        DoctorImage: SamirBagadia,
        DoctorSpecialist: "Urologist, Andrologist, Laparoscopic Surgeon",
        DoctorEducation: "MBBS, MS - General Surgery, DNB - Urology/Genito - Urinary Surgery",
        DoctorExperience: "16 Years Experience Overall  (10 years as specialist)",
        DoctorDes01: "Dr Samir Bagadia is an urologist based in Mumbai.He obtained his postgraduate training from LTMMC, Sion (Sion hospital).He then went to Hyderabad in Krishna Institute of Medical Sciences to get superspeciality training in Urology, Kidney transplant and Robotic surgery. He has various publication in zonal as well as national conferences. He was working as an Urologist and Kidney transplant surgeon in Apollo Hospital, New Mumbai. His special interest lies in Endourology, Laparoscopic, Laser and Robotic surgeries.He has performed 2000+ successful surgeries and has more than 7 years of experience as a surgeon.",
    },
    {
        DoctorSlug:"/urologist-dr-ojas",
        DoctorName: "Dr. Ojas Potdar",
        Speciality: "Urologist",
        DoctorImage: OjasPotdar,
        DoctorSpecialist: "Urological Surgeon,Andrologist",
        DoctorEducation: "MBBS, DNB - General Surgery, DNB - Urology/Genito - Urinary Surgery",
        DoctorExperience: "6 Years Experience Overall  (1 year as specialist)",
        DoctorDes01: "Dr. Ojas Potdar is a highly Skilled and experienced urologist dedicated to providing the highest level of care to patients with urinary tract and male and female reproductive system disorders. With 3 years of experience in the field, he has developed a reputation for excellence in the diagnosis, treatment, and management of a wide range of urological conditions.",
        DoctorDes02: "His extensive training and expertise allow them to provide patients with personalized care and treatment options, tailored to their individual needs and medical history. They specialize in the diagnosis and treatment of conditions such as kidney stones, urinary tract infections, prostate cancer, bladder cancer, and more.",
        DoctorDes03: "As a urologist, he is committed to staying up-to-date with the latest advances in urological care and treatment, ensuring that patients receive the most advanced and effective treatments available. With a compassionate and patient-centered approach, he strives to provide the highest level of care to each and every patient, helping them achieve optimal health and well-being.",
    },
    {
        DoctorSlug:"/neprologist-dr-bhuwania",
        DoctorName: "Dr. Bhuwania Puneet Sanjay Kumar",
        Speciality: "Neprologist",
        DoctorImage: DrBhuwaniaPuneetSanjayKumar,
        DoctorSpecialist: "Nephrologist/Renal Specialist",
        DoctorEducation: "MBBS, MD - General Medicine, DNB - General Medicine, DNB - Nephrology",
        DoctorExperience: "9 Years Experience Overall  (3 years as specialist)",
        DoctorDes01: "Dr. Bhuwania Puneet Sanjay Kumar has more than 9 years of clinical experience. He Special interest in Kidney Transplantation, Dialysis, Critical care, Glomerulonephritis",
    },
    {
        DoctorSlug:"/neprologist-dr-kavita",
        DoctorName: "Dr. Kavita Vishwakarma",
        Speciality: "Neprologist",
        DoctorImage: BoyGirls,
        DoctorSpecialist: "Nephrologist/Renal Specialist",
        DoctorEducation: "MBBS, MD - General Medicine, DM - Nephrology",
        DoctorExperience: "16 Years Experience Overall  (5 years as specialist)",
        DoctorDes01: "Dr. Kavita Vishwakarma is a Nephrologist/Renal Specialist in Jogeshwari West, Mumbai and has an experience of 16 years in this field. Dr. Kavita Vishwakarma practices at Mallika Hospital in Jogeshwari West, Mumbai and Thunga Hospital in Malad West, Mumbai. She completed MBBS from Chaudhary Charan Singh University in 2007,MD - General Medicine from Chatrapati Shivaji University in 2011 and DM - Nephrology from Sanjay Gandhi Post Graduate Institute of Medical Sciences in 2018.",
    },
    {
        DoctorSlug:"/neprologist-dr-gaurav",
        DoctorName: "Dr. Gaurav Bhandari",
        Speciality: "Neprologist",
        DoctorImage: GauravBhandari,
        DoctorSpecialist: "Nephrologist/Renal Specialist",
        DoctorEducation: "MBBS, MD - General Medicine, DNB - Nephrology",
        DoctorExperience: "5 years experience overall",
        DoctorDes01: "Dr Gaurav M Bhandari is a Nephrologist (kidney specialist) in Mumbai. He has completed his MBBS from GMC and JJ Group of Hospitals, Mumbai. He accomplished his MD (General Medicine) from TNMC and BYL Nair Ch Hospital, Mumbai. He then joined Sir Ganga Ram Hospital for DrNB (NEPHROLOGY), New Delhi, which he cleared brilliantly. He was also honoured as 'RESEARCHER OF THE YEAR, 2021' , Nephrology Department, Sir Ganga Ram Hospital, New Delhi. He is the Author of Nephrology Theory Examination for DrNB and contributing author of COVID update chapter in Indian context. He was also honoured with the prestigious Commendable thesis award in DrNB Nephrology.",
    },
    {
        DoctorSlug:"/psyciatrist-dr-alpes",
        DoctorName: "Dr. Alpes Panchal",
        Speciality: "Psyciatrist",
        DoctorImage: DrAlpesPanchal,
        DoctorSpecialist: "Psychiatrist,Addiction Psychiatrist,Geriatric Psychiatrist",
        DoctorEducation: "MBBS, DPM (Psychiatry)",
        DoctorExperience: "14 Years Experience Overall  (7 years as specialist)",
        DoctorDes01: "Consultant Psychiatrist & De addiction Specialist,Adult & Adolescent Psychiatry,Founder, The Freemind Initiative",
    },
    {
        DoctorSlug:"/endocrinologist-dr-harsh",
        DoctorName: "Dr. Harsh Parekh",
        Speciality: "Endocrinologist",
        DoctorImage: HarshParekh,
        DoctorSpecialist: "Endocrinologist, Diabetologist",
        DoctorEducation: "DNB - Endocrinology, Diabetes, Metabolism, MD - Medicine, MBBS",
        DoctorExperience: "14 years experience overall",
        DoctorDes01: "Harsh Parekh completed his MBBS from Grant Medical College, Mumbai. Then he moved to Gujarat where he completed his MD Medicine from Government Medical College, Bhavnagar in 2013. After that, he worked as Assistant Professor in the Department of Medicine in the same college. Thereafter he moved to Hyderabad where he worked as Senior Resident in the Department of Endocrinology at CARE Hospital and completed my DNB in Endocrinology in 2018.",
    },
    {
        DoctorSlug:"/endocrinologist-dr-johann",
        DoctorName: "Dr. Johann Varghese",
        Speciality: "Endocrinologist",
        DoctorImage: JohannVarghese,
        DoctorSpecialist: "Endocrinologist",
        DoctorEducation: "MBBS, DNB - General Medicine, DM - Endocrinology",
        DoctorExperience: "4 years experience overall",
        DoctorDes01: "Dr. Johann Varghese is Passionate about providing holistic care in hormonal and lifestyle disorders.",
    },
    {
        DoctorSlug:"/dermatologist-dr-sarika",
        DoctorName: "Dr. Sarika Holmukhe",
        Speciality: "Dermatologist",
        DoctorImage: SarikaHolmukhe,
        DoctorSpecialist: "Dermatologist, Cosmetologist",
        DoctorEducation: "DNB - Dermatology & Venereology, MBBS, DDVL",
        DoctorExperience: "18 years experience overall",
        DoctorDes01: "Dr. Sarika Holmukhe says I am dedicated towards clinical dermatology and cosmetology and trichology. Special passion for Dermato Surgeries. I am a trained Botox, Filler and Laser Specialist.",
    },
    {
        DoctorSlug:"/dermatologist-dr-neha",
        DoctorName: "Dr. Neha Dalal",
        Speciality: "Dermatologist",
        DoctorImage: NehaDalal,
        DoctorSpecialist: "Dermatologist,Cosmetologist",
        DoctorEducation: "MBBS, MD - Skin & VD",
        DoctorExperience: "23 Years Experience Overall",
        DoctorDes01: "Dr. Neha Dalal is a Dermatologist, Cosmetologist and Trichologist in Vileparle West, Mumbai and has an experience of 16 years in these fields. Dr. Neha Dalal practices at Dr.Neha Dalal's Skin, Hair and Laser Clinic in Vileparle West, Mumbai and Versova Medical Centre in Andheri West, Mumbai. She completed MBBS from Baroda medical College in 1997 and MD - Skin & VD from Baroda medical College in 2000.",
        DoctorDes02: "She is a member of Indian Association of Dermatologists, Venereologists and Leprologists (IADVL) and Cosmetic Dermatology Society of India (CDSI).",
    },
    {
        DoctorSlug:"/physician-dr-anuj",
        DoctorName: "Dr. Anuj Tiwari",
        Speciality: "Physician",
        DoctorImage: DrAnujTiwari,
        DoctorSpecialist: "Internal Medicine,General Physician,Consultant Physician,Geriatrician",
        DoctorEducation: "MBBS, DNB - General Medicine",
        DoctorExperience: "7 Years Experience Overall  (4 years as specialist)",
        DoctorDes01: "Dr Anuj Tiwari is a General Physician.",
    },
    {
        DoctorSlug:"/pulmonologist-dr-amit",
        DoctorName: "Dr. Amit P Gawnde",
        Speciality: "Pulmonologist",
        DoctorImage: DrAmitPGawnde,
        DoctorSpecialist: "Pulmonologist",
        DoctorEducation: "Diploma in Tuberculosis Diseases (TDD)",
        DoctorExperience: "7 Years Experience Overall  (5 years as specialist)",
        DoctorDes01: "Dr. Amit P Gawnde is a Pulmonologist in Vileparle West, Mumbai and has an experience of 7 years in this field. Dr. Amit P Gawnde practices at Sujay Hospital in Vileparle West, Mumbai,Apollo Clinic in Andheri East, Mumbai and Practo Instant Full Time 2 in JP Nagar, Bangalore. He completed Diploma in Tuberculosis Diseases (TDD) from College of Physicians and Surgeons,Parel in 2016 and Certificate of Training in Bronchoscopy from Tata Memorial Hospital, Mumbai in 2017.",
        DoctorDes02: "He is a member of Indian Chest Society and American College of Chest Physicians. Some of the services provided by the doctor are: Pulmonary Function Test (PFT) and Bronchoscopy etc.",
    },
    {
        DoctorSlug:"/oncologist-surgeon-dr-yogen",
        DoctorName: "Dr. Yogen Chheda",
        Speciality: "Oncologist Surgeon",
        DoctorImage: YogenChheda,
        DoctorSpecialist: "Surgical Oncologist,General Surgeon",
        DoctorEducation: "MCh - Surgical Oncology, MS - General Surgery, MBBS",
        DoctorExperience: "10 Years Experience Overall  (9 years as specialist)",
        DoctorDes01: "Dr. Yogen P Chedda , completed his super specialization ( M.Ch ) in surgical oncology from a reputed institute in India , following which he pursued advanced training in Laparoscopy and Endoscopy ( Minimal Invasive Surgery ) in gastro intestinal oncology at Ahmedabad.",
        DoctorDes02: "He then served as Associate Professor in Surgical Oncology at Gujarat University. For further clinical and surgical experience, Dr Yogen P Chheda acquired training in Robotic Surgery at ROSWELL PARK CANCER INSTITUTE , Buffalo, NY , USA.",
        DoctorDes03: "His advanced fellowship training at NATIONAL CANCER CENTRE at Tokyo, Japan in esophagus and colorectal cancer makes him a specialist in comprehensive G.I. Oncology ( Endscopy with laproscopy with robotics ).",
        DoctorDes04: "Dr. Chheda’s area of expertise is BREAST and COLORECTAL CANCER with special interest in management of early breast cancer and breast conservation surgery and minimal invasive surgery for management of cancers of esophagus and colorectal cancer. ",
    },
    {
        DoctorSlug:"/neprologist-dr-amit",
        DoctorName: "Dr. Amit Jain",
        Speciality: "Neprologist",
        DoctorImage: DrAmitJain,
        DoctorSpecialist: "Nephrologist/Renal Specialist",
        DoctorEducation: "DNB - Nephrology, MBBS, MD - General Medicine",
        DoctorExperience: "9 Years Experience Overall  (5 years as specialist)",
        DoctorDes01: "Dr. Amit S Jain has completed his MBBS from the prestigious Seth G S Medical college and KEM Hospital in 2010. He accomplished his MD Medicine from Sardar Patel Medical College, Bikaner, Rajasthan in 2014. He then joined Jaslok Hospital and Research Centre for DNB NEPHROLOGY which he cleared in 2019. He has won various awards for paper presentations at state and national level conferences. He was honoured with prestigious AMAR GANDHI AWARD at Jaslok hospital in 2019.",
    },
    {
        DoctorSlug:"/pulmonologist-dr-rahul",
        DoctorName: "Dr. Rahul Bahot",
        Speciality: "Pulmonologist",
        DoctorImage: RahulBahot,
        DoctorSpecialist: "Tuberculous and chest Diseases Specialist,Pulmonologist",
        DoctorEducation: "DNB - Respiratory Diseases, MBBS",
        DoctorExperience: "20 Years Experience Overall  (14 years as specialist)",
        DoctorDes01: "Dr. Rahul Bahot is a seasoned chest and critical care consultant who is also a highly skilled bronchoscopist and interventional pulmonologist in HCG Cancer Centre Mumbai",
        DoctorDes02: "Dr. Rahul's diverse skills and extensive knowledge make him an asset in providing comprehensive care to patients with respiratory conditions. He specializes in the following areas:Management of respiratory diseases,Diagnostic and therapeutic bronchoscopy,Pulmonary rehabilitation,VO2 MAX studies,Polysomnography,Pulmonary function tests,Weaning from ventilators,He also has vast experience attending patients in the OPD, IPD, and ICU.,He is also interested in the areas of clinical research, clinical trials, and medical education.",
        DoctorDes03: "In addition to his medical expertise, Dr. Rahul is an accomplished trainer, and he nurtures postgraduate residents. He is also medical council-accredited faculty for “Continued Medical Education”. His astounding training capabilities extend to grooming individuals for medical writing.",
        DoctorDes04: "Dr. Rahul’s dedication to continuous learning and patient-centred approach ensures that each patient benefits from the highest level of expertise and personalized care. ",
    },
    {
        DoctorSlug:"/gynecologist-dr-rajeevt",
        DoctorName: "Dr. Rajeev Punjabi",
        Speciality: "Gynecologist",
        DoctorImage: RajeevPunjabi,
        DoctorSpecialist: "Gynaecological Endoscopist,Laparoscopic Surgeon (Obs & Gyn)",
        DoctorEducation: "MBBS, Diploma in Advanced Endoscopic Gynae Surgery, Diploma in Advanced Endoscopic Gynae Surgery, Diploma in Advanced Endoscopic Gynae Surgery",
        DoctorExperience: "23 Years Experience Overall  (20 years as specialist)",
        DoctorDes01: "Pioneer of Waterbirth in India - Runs the Only Waterbirthing Practice in Mumbai since 2003. Minimally Invasive Laparoscopic Gynaecologist and Fertility Enhancing Surgery Expert. Obstetrician and Gynaecologist.",
    },
];

export default DoctorData;