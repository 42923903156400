import SerImg1 from "../img/services/orthopedic-surgery.jpg";
import SerImg2 from "../img/services/orthopedic-surgery.jpg";
import SerImg3 from "../img/services/orthopedic-surgery.jpg";
import SerImg4 from "../img/services/orthopedic-surgery.jpg";
import SerImg5 from "../img/services/orthopedic-surgery.jpg";
import SerImg6 from "../img/services/orthopedic-surgery.jpg";
import SerImg7 from "../img/services/orthopedic-surgery.jpg";
import SerImg8 from "../img/services/orthopedic-surgery.jpg";
import SerImg9 from "../img/services/orthopedic-surgery.jpg";
import SerImg10 from "../img/services/orthopedic-surgery.jpg";
import SerImg11 from "../img/services/orthopedic-surgery.jpg";
import SerImg12 from "../img/services/orthopedic-surgery.jpg";
import SerImg13 from "../img/services/orthopedic-surgery.jpg";
import SerImg14 from "../img/services/orthopedic-surgery.jpg";
import SerImg15 from "../img/services/orthopedic-surgery.jpg";
import SerImg16 from "../img/services/orthopedic-surgery.jpg";
import SerImg17 from "../img/services/orthopedic-surgery.jpg";
import SerImg18 from "../img/services/orthopedic-surgery.jpg";
import SerImg19 from "../img/services/orthopedic-surgery.jpg";



const ServiceData = [
    {
        id:1,
        ServiceImage:SerImg1,
        Header:"Orthopedic Surgery",
        Branch:"Both",
        category:"Both",
        Slugs:"/orthopedic-surgery",
        ShortDesc:"Orthopedic surgery is a specialized branch of medicine that focuses on the diagnosis, treatment, and prevention of musculoskeletal conditions and injuries. Orthopedic surgeons are skilled professionals dedicated to restoring the function and mobility of bones, joints, muscles, ligaments, and tendons. With a commitment to both surgical and non-surgical approaches, orthopedic surgery aims to improve the quality of life for individuals of all ages by addressing a wide range of orthopedic issues.",
        BannerDesc:"Orthopedic surgery is a specialized branch of medicine that focuses on the diagnosis, treatment, and prevention of musculoskeletal conditions and injuries. Orthopedic surgeons are skilled professionals dedicated to restoring the function and mobility of bones, joints, muscles, ligaments, and tendons. With a commitment to both surgical and non-surgical approaches, orthopedic surgery aims to improve the quality of life for individuals of all ages by addressing a wide range of orthopedic issues.",
        ShortDesc1:"Orthopedic surgery is a crucial medical field that deals with the management of musculoskeletal disorders, including fractures, joint injuries, degenerative conditions, and more. Orthopedic surgeons employ advanced techniques to provide comprehensive care, whether it involves surgery, rehabilitation, or preventive measures. By focusing on restoring function and alleviating pain, orthopedic surgery contributes to enhancing patients' overall well-being and physical capabilities.",
        shortTitle:"Orthopedic Surgery: Advancing Mobility and Well-being Through Expert Musculoskeletal Care and Innovative Treatment Approaches",
        descHead1:"Comprehensive Musculoskeletal Care :",
        descHead2:"Surgical and Non-Surgical Approaches :",
        descHead3:"Joint Replacement Expertise :",
        descHead4:"Trauma Care :",
        descHead5:"Pediatric Orthopedics :",
        descHead6:"Pediatric Orthopedics :",
        descPoint1:"Orthopedic surgery encompasses a wide range of conditions, from sports injuries to degenerative diseases like arthritis.",
        descPoint2:"Orthopedic surgeons employ various techniques, including surgery, medication, physical therapy, and other interventions.",
        descPoint3:"Surgeons specialize in joint replacements like hip, knee, and shoulder, helping patients regain mobility and comfort.",
        descPoint4:"Orthopedic surgeons handle traumatic injuries, such as fractures and dislocations, providing prompt and effective treatment.",
        descPoint5:"Specialists address musculoskeletal issues in children, ensuring healthy growth and development.",
        descPoint6:" Orthopedic surgeons play a key role in sports medicine, diagnosing and treating athletic injuries to facilitate recovery.",
    },
    {
        id:2,
        ServiceImage:SerImg2,
        Header:"Spine Surgery",
        Branch:"Ghatkopar",
        category:"Ghatkopar",
        Slugs:"/spine-surgery",
        ShortDesc:"Spine surgery is a specialized field within orthopedic and neurosurgery that focuses on the diagnosis, treatment, and correction of spinal disorders and conditions. It involves surgical interventions aimed at alleviating pain, restoring function, and improving the quality of life for individuals suffering from spine-related issues. With advancements in technology and surgical techniques, spine surgery offers effective solutions for a range of spinal conditions, from degenerative disorders to spinal deformities..",
        BannerDesc:"Spine surgery is a specialized field within orthopedic and neurosurgery that focuses on the diagnosis, treatment, and correction of spinal disorders and conditions. It involves surgical interventions aimed at alleviating pain, restoring function, and improving the quality of life for individuals suffering from spine-related issues. With advancements in technology and surgical techniques, spine surgery offers effective solutions for a range of spinal conditions, from degenerative disorders to spinal deformities.",
        ShortDesc1:"Spine surgery is a specialized medical discipline dedicated to addressing a wide spectrum of spinal disorders and conditions. Surgeons in this field employ cutting-edge techniques to treat issues such as herniated discs, spinal stenosis, scoliosis, and more. By utilizing minimally invasive procedures and advanced instrumentation, spine surgery aims to reduce pain, improve mobility, and enhance the overall well-being of patients with spinal problems.",
        shortTitle:"Spine Surgery: Pioneering Relief and Restoration for Spinal Disorders Through State-of-the-Art Techniques and Individualized Treatment",
        descHead1:"Diverse Spinal Conditions :",
        descHead2:"Minimally Invasive Techniques :",
        descHead3:"Precision Instrumentation :",
        descHead4:"Spinal Fusion :",
        descHead5:"Microdiscectomy :",
        descHead6:"Scoliosis Correction :",
        descPoint1:"Spine surgery encompasses various conditions, including herniated discs, spinal fractures, degenerative disc disease, and spinal deformities.",
        descPoint2:"Surgeons utilize minimally invasive approaches whenever possible, leading to smaller incisions, less pain, and quicker recovery times.",
        descPoint3:"Advanced tools and instrumentation enable surgeons to perform complex procedures with greater precision and minimal disruption to surrounding tissues.",
        descPoint4:"This procedure aims to stabilize and fuse two or more vertebrae to alleviate pain and address issues like spinal instability.",
        descPoint5:"A minimally invasive procedure that removes a portion of a herniated disc to relieve pressure on nerves and reduce pain.",
        descPoint6:"Spine surgeons specialize in correcting spinal deformities like scoliosis, enhancing posture and restoring a balanced spine.",
    },
    {
        id:3,
        ServiceImage:SerImg3,
        Header:"Medicine",
        Branch:"Both",
        category:"Both",
        Slugs:"/general-medicine",
        ShortDesc:"General medicine, often referred to as internal medicine, is a comprehensive medical field that focuses on the diagnosis, treatment, and prevention of a wide range of illnesses and health issues in adults. General medicine practitioners, known as internists or general physicians, are highly trained to provide holistic care and manage various medical conditions, serving as the primary point of contact for patients seeking medical assistance.",
        BannerDesc:"General medicine, often referred to as internal medicine, is a comprehensive medical field that focuses on the diagnosis, treatment, and prevention of a wide range of illnesses and health issues in adults. General medicine practitioners, known as internists or general physicians, are highly trained to provide holistic care and manage various medical conditions, serving as the primary point of contact for patients seeking medical assistance.",
        ShortDesc1:"General medicine, also known as internal medicine, encompasses a broad spectrum of medical care dedicated to the health and well-being of adults. It involves diagnosing, treating, and managing various acute and chronic illnesses, as well as promoting preventive measures to maintain optimal health. General physicians play a crucial role in providing comprehensive healthcare services, from routine check-ups to complex disease management.",
        shortTitle:"General Medicine: Nurturing Health and Wellness through Expert Diagnosis, Holistic Care, and Preventive Medicine",
        descHead1:"Comprehensive Care :",
        descHead2:"Diagnosis and Treatment :",
        descHead3:"Preventive Medicine :",
        descHead4:"Coordination of Care :",
        descHead5:"Health Promotion :",
        descHead6:"Holistic Approach :",
        descPoint1:"General medicine covers a wide array of health issues, addressing everything from common colds to chronic diseases like diabetes and hypertension.",
        descPoint2:"General physicians are skilled in diagnosing medical conditions through patient history, physical examinations, and diagnostic tests.",
        descPoint3:"Emphasis on preventive care helps patients maintain good health, manage risk factors, and avoid potential health complications.",
        descPoint4:"General physicians often serve as primary care providers who coordinate and manage patients' overall healthcare journey.",
        descPoint5:"They offer guidance on healthy lifestyle choices, nutrition, exercise, and disease prevention to enhance patients' well-being.",
        descPoint6:"General medicine takes into account patients' physical, emotional, and mental health, providing a well-rounded approach to healthcare.",
    },
    {
        id:4,
        ServiceImage:SerImg4,
        Header:"Neuro Surgery",
        Branch:"Both",
        category:"Both",
        Slugs:"/neuro-surgery",
        ShortDesc:"Neuro surgery, a specialized branch of medical science, focuses on the surgical treatment of disorders affecting the nervous system. This intricate field involves surgical interventions on the brain, spinal cord, and peripheral nerves to address conditions like brain tumors, spinal cord injuries, neurovascular disorders, and more. Neuro surgeons are highly skilled professionals who employ advanced techniques and technologies to provide patients with effective surgical solutions for complex neurological issues.",
        BannerDesc:"Neuro surgery, a specialized branch of medical science, focuses on the surgical treatment of disorders affecting the nervous system. This intricate field involves surgical interventions on the brain, spinal cord, and peripheral nerves to address conditions like brain tumors, spinal cord injuries, neurovascular disorders, and more. Neuro surgeons are highly skilled professionals who employ advanced techniques and technologies to provide patients with effective surgical solutions for complex neurological issues.",
        ShortDesc1:"Neuro surgery is a specialized medical discipline dedicated to the surgical management of disorders related to the nervous system. It encompasses intricate procedures performed on the brain, spinal cord, and peripheral nerves to treat a variety of conditions, such as brain tumors, spinal deformities, aneurysms, and nerve injuries. Neuro surgeons are trained to navigate the delicate structures of the nervous system, ensuring optimal outcomes for patients.",
        shortTitle:"Neuro Surgery: Precision and Expertise in Surgical Interventions for Complex Neurological Conditions",
        descHead1:"Complex Procedures :",
        descHead2:"Brain Tumor Treatment :",
        descHead3:"Spinal Cord Interventions :",
        descHead4:"Neurovascular Disorders :",
        descHead5:"Minimally Invasive Techniques :",
        descHead6:"Collaborative Care :",
        descPoint1:"Neuro surgery involves intricate surgeries on the brain and spinal cord, requiring specialized skills and expertise.",
        descPoint2:"Neuro surgeons perform tumor resections to remove brain tumors, often collaborating with oncologists for comprehensive care.",
        descPoint3:"Surgical procedures address spinal cord injuries, herniated discs, and deformities to alleviate pain and improve mobility.",
        descPoint4:"Surgeons manage neurovascular conditions like aneurysms and arteriovenous malformations to prevent complications.",
        descPoint5:"Advanced technologies enable neuro surgeons to perform minimally invasive procedures, reducing risks and recovery times.",
        descPoint6:" Neuro surgeons work alongside neurologists, radiologists, and other specialists to provide holistic care for patients with neurological conditions.",
    },
    {
        id:5,
        ServiceImage:SerImg5,
        Header:"ENT (Microscopic) Surgery",
        Branch:"Both",
        category:"Both",
        Slugs:"/e-n-t-surgery",
        ShortDesc:"ENT (Ear, Nose, and Throat) Microscopic Surgery is a specialized field of medicine that focuses on diagnosing and treating disorders of the ear, nose, throat, and related structures using advanced microscopic techniques. This surgical approach allows for highly precise interventions, resulting in improved outcomes and minimal discomfort for patients. ENT surgeons who specialize in microscopic surgery use specialized instruments and visualization tools to address a wide range of conditions affecting hearing, balance, breathing, and vocal function.",
        BannerDesc:"ENT (Ear, Nose, and Throat) Microscopic Surgery is a specialized field of medicine that focuses on diagnosing and treating disorders of the ear, nose, throat, and related structures using advanced microscopic techniques. This surgical approach allows for highly precise interventions, resulting in improved outcomes and minimal discomfort for patients. ENT surgeons who specialize in microscopic surgery use specialized instruments and visualization tools to address a wide range of conditions affecting hearing, balance, breathing, and vocal function.",
        ShortDesc1:"ENT (Microscopic) Surgery is a specialized branch of medicine that employs advanced microscopic techniques to diagnose and treat disorders of the ear, nose, throat, and adjacent areas. Surgeons use specially designed microscopes and instruments to perform precise interventions for conditions such as chronic sinusitis, ear infections, vocal cord issues, and nasal obstructions. This approach ensures accurate diagnosis, minimally invasive procedures, and enhanced patient recovery.",
        shortTitle:"ENT (Microscopic) Surgery: Precision Interventions for Ear, Nose, and Throat Disorders Using Advanced Microscopic Techniques",
        descHead1:"Enhanced Precision :",
        descHead2:"Ear Disorders :",
        descHead3:"Nasal and Sinus Issues :",
        descHead4:"Throat and Vocal Cord Concerns :",
        descHead5:"Minimized Trauma :",
        descHead6:"Balanced Approach :",
        descPoint1:"Microscopic surgery provides surgeons with a detailed view of the surgical area, enabling precise interventions.",
        descPoint2:"Microscopic techniques are used to treat conditions like chronic ear infections, hearing loss, and perforated eardrums.",
        descPoint3:"Surgeons address chronic sinusitis, nasal polyps, and deviated septums using minimally invasive methods.",
        descPoint4:"Microscopic surgery treats vocal cord nodules, polyps, and other throat-related conditions.",
        descPoint5:"Microscopic interventions result in smaller incisions, reduced tissue damage, and quicker recovery times.",
        descPoint6:"Surgeons balance the removal of diseased tissue with the preservation of healthy structures.",
    },
    {
        id:6,
        ServiceImage:SerImg6,
        Header:"Peadiatric Surgery",
        Branch:"Chembur",
        category:"Chembur",
        Slugs:"/peadiatric-surgery",
        ShortDesc:"Pediatric Surgery is a specialized medical field dedicated to the surgical care of infants, children, and adolescents. This branch of surgery involves the diagnosis, treatment, and management of a wide range of congenital and acquired conditions that require surgical intervention in young patients. Pediatric surgeons are trained to address anatomical, physiological, and emotional differences unique to children, ensuring their well-being and healthy development.",
        BannerDesc:"Pediatric Surgery is a specialized medical field dedicated to the surgical care of infants, children, and adolescents. This branch of surgery involves the diagnosis, treatment, and management of a wide range of congenital and acquired conditions that require surgical intervention in young patients. Pediatric surgeons are trained to address anatomical, physiological, and emotional differences unique to children, ensuring their well-being and healthy development.",
        ShortDesc1:"Pediatric Surgery is a medical specialty focused on performing surgical procedures on infants, children, and adolescents. Pediatric surgeons are equipped to treat a diverse range of conditions, from congenital anomalies to injuries and tumors, all while considering the distinct medical and emotional needs of young patients. The field aims to provide compassionate care and optimal outcomes for pediatric patients.",
        shortTitle:"Pediatric Surgery: Nurturing Health and Well-being Through Specialized Surgical Care for Infants, Children, and Adolescents",
        descHead1:"Specialized Training :",
        descHead2:"Congenital Anomalies :",
        descHead3:"Pediatric Trauma :",
        descHead4:"Tumor Removal :",
        descHead5:"Minimally Invasive Techniques :",
        descHead6:"Psychosocial Aspects :",
        descPoint1:"Pediatric surgeons undergo extensive training to manage surgical issues specific to children, considering their unique anatomy and physiology.",
        descPoint2:"Pediatric surgeons address birth defects like cleft lip and palate, heart defects, and gastrointestinal malformations.",
        descPoint3:"These specialists handle injuries and accidents in children, ensuring appropriate care and timely interventions.",
        descPoint4:"Pediatric surgeons remove tumors and growths in various parts of the body, collaborating with oncologists for comprehensive care.",
        descPoint5:"Many pediatric surgeries utilize minimally invasive procedures, resulting in smaller incisions and faster recovery.",
        descPoint6:"Pediatric surgeons work closely with families, addressing emotional and psychological aspects of surgery on young patients.",
    },
    {
        id:7,
        ServiceImage:SerImg7,
        Header:"Peadiatric",
        Branch:"Both",
        category:"Both",
        Slugs:"/peadiatric",
        ShortDesc:"Pediatrics is a branch of medicine dedicated to the health and well-being of infants, children, and adolescents. It encompasses the comprehensive care, diagnosis, treatment, and prevention of various health conditions that affect young individuals. Pediatricians play a vital role in monitoring growth and development, providing vaccinations, managing illnesses, and guiding parents on proper child care practices.",
        BannerDesc:"Pediatrics is a branch of medicine dedicated to the health and well-being of infants, children, and adolescents. It encompasses the comprehensive care, diagnosis, treatment, and prevention of various health conditions that affect young individuals. Pediatricians play a vital role in monitoring growth and development, providing vaccinations, managing illnesses, and guiding parents on proper child care practices.",
        ShortDesc1:"Pediatrics is a medical specialty focused on the holistic care of children from birth through adolescence. Pediatricians are trained to address physical, emotional, and developmental needs, offering preventive care, medical treatments, and guidance to ensure children thrive and maintain optimal health.",
        shortTitle:"Pediatrics: Nurturing Childhood Health and Development Through Comprehensive Medical Care for Infants, Children, and Adolescents",
        descHead1:"Wellness Visits :",
        descHead2:"Childhood Vaccinations :",
        descHead3:"Illness Diagnosis and Treatment :",
        descHead4:"Developmental Milestones :",
        descHead5:"Parental Guidance :",
        descHead6:"Chronic Conditions :",
        descPoint1:"Pediatricians conduct routine check-ups to monitor growth, development, and overall health, providing guidance on nutrition and healthy habits.",
        descPoint2:"They administer vaccines to protect against infectious diseases, ensuring a strong foundation for a healthy future.",
        descPoint3:"Pediatricians diagnose and treat common childhood illnesses like colds, infections, and allergies, tailoring treatments to a child's specific needs.",
        descPoint4:"They track milestones such as speech, motor skills, and social interactions, intervening if delays are detected.",
        descPoint5:"Pediatricians offer guidance on parenting techniques, safety measures, and promoting a nurturing environment at home.",
        descPoint6:"Pediatricians manage chronic health conditions in children, collaborating with specialists when needed.",
    },
    {
        id:8,
        ServiceImage:SerImg8,
        Header:"OBS & GYNEAC",
        Branch:"Both",
        category:"Both",
        Slugs:"/obstetrics",
        ShortDesc:"OBS & GYNEAC is a specialized field of medicine dedicated to the care of pregnant women, childbirth, and postpartum care. Obstetricians, also known as OB-GYNs, play a crucial role in ensuring the health and well-being of both the expectant mother and her developing baby. Their expertise covers prenatal care, monitoring fetal growth, managing pregnancy complications, guiding labor and delivery, and offering postnatal support.",
        BannerDesc:"OBS & GYNEAC is a specialized field of medicine dedicated to the care of pregnant women, childbirth, and postpartum care. Obstetricians, also known as OB-GYNs, play a crucial role in ensuring the health and well-being of both the expectant mother and her developing baby. Their expertise covers prenatal care, monitoring fetal growth, managing pregnancy complications, guiding labor and delivery, and offering postnatal support.",
        ShortDesc1:"OBS & GYNEAC is the branch of medicine that focuses on the unique medical needs of pregnant women, encompassing prenatal care, childbirth, and postpartum recovery. Obstetricians provide comprehensive care throughout pregnancy, ensuring the safety and health of both mother and baby.",
        shortTitle:"OBS & GYNEAC: Nurturing Pregnancy and Ensuring Safe Delivery Through Expert Care for Expectant Mothers and Their Developing Babies",
        descHead1:"Prenatal Care :",
        descHead2:"Fetal Monitoring :",
        descHead3:"Labor and Delivery :",
        descHead4:"High-Risk Pregnancies :",
        descHead5:"Postpartum Care :",
        descHead6:"Family Planning :",
        descPoint1:"Obstetricians monitor the health of expectant mothers through regular check-ups, screenings, and ultrasounds to ensure a healthy pregnancy.",
        descPoint2:"They track fetal growth and development, addressing any concerns that may arise during pregnancy.",
        descPoint3:"OBS & GYNEAC guide women through labor and childbirth, offering pain management options and interventions if necessary.",
        descPoint4:"For pregnancies with complications or medical conditions, obstetricians provide specialized care to manage risks and ensure a safe outcome.",
        descPoint5:"After delivery, obstetricians support women in their postpartum recovery, addressing physical and emotional changes.",
        descPoint6:"Obstetricians offer guidance on family planning, contraception, and reproductive health to women throughout their lives.",
    },
    {
        id:9,
        ServiceImage:SerImg9,
        Header:"Oncology",
        Branch:"Chembur",
        category:"Chembur",
        Slugs:"/surgical-oncology",
        ShortDesc:"Oncology is a critical medical field dedicated to the study, diagnosis, treatment, and management of cancer. Oncologists, specialized physicians in this domain, work tirelessly to provide comprehensive care for cancer patients, tailoring treatment plans that encompass a range of modalities. Their goal is to improve patient outcomes, enhance quality of life, and advance our understanding of cancer and its complexities.",
        BannerDesc:"Oncology is a critical medical field dedicated to the study, diagnosis, treatment, and management of cancer. Oncologists, specialized physicians in this domain, work tirelessly to provide comprehensive care for cancer patients, tailoring treatment plans that encompass a range of modalities. Their goal is to improve patient outcomes, enhance quality of life, and advance our understanding of cancer and its complexities.",
        ShortDesc1:"Oncology is a specialized branch of medicine focused on the diagnosis, treatment, and ongoing care of individuals with cancer. Oncologists utilize a multidisciplinary approach to address various cancer types and stages, striving to provide personalized and effective care.",
        shortTitle:"Oncology: Advancing Cancer Care Through Comprehensive Diagnosis, Tailored Treatment, and Ongoing Support for Patients Fighting Against Cancer",
        descHead1:"Diagnosis :",
        descHead2:"Treatment Planning :",
        descHead3:"Multidisciplinary Approach :",
        descHead4:"Palliative Care :",
        descHead5:"Research and Clinical Trials :",
        descHead6:"Patient Support :",
        descPoint1:"Oncologists use advanced imaging, biopsies, and molecular testing to accurately diagnose cancer, identifying its type and stage.",
        descPoint2:"Based on diagnosis, oncologists develop personalized treatment plans that may include surgery, chemotherapy, radiation therapy, immunotherapy, and targeted therapies.",
        descPoint3:"Oncology often involves collaboration with other medical specialists, such as surgeons, radiologists, and pathologists, to provide comprehensive care.",
        descPoint4:"Oncologists prioritize the management of cancer symptoms and side effects to improve patients' quality of life during and after treatment.",
        descPoint5:"Oncologists contribute to groundbreaking research and clinical trials, aiming to develop innovative treatments and improve cancer outcomes.",
        descPoint6:" Emotional and psychological support is integral; oncologists provide guidance, address patient concerns, and offer resources for patients and their families.",
    },
    {
        id:10,
        ServiceImage:SerImg10,
        Header:"Urology",
        Branch:"Chembur",
        category:"Chembur",
        Slugs:"/urology",
        ShortDesc:"Urology is a specialized medical field dedicated to the diagnosis, treatment, and management of conditions related to the urinary tract and male reproductive system. Urologists, skilled in both surgical and non-surgical approaches, address a wide range of urological issues to ensure patients' urinary health and overall well-being.",
        BannerDesc:"Urology is a specialized medical field dedicated to the diagnosis, treatment, and management of conditions related to the urinary tract and male reproductive system. Urologists, skilled in both surgical and non-surgical approaches, address a wide range of urological issues to ensure patients' urinary health and overall well-being.",
        ShortDesc1:"Urology is a vital medical discipline focused on the urinary tract and male reproductive system. Urologists diagnose and treat various conditions, from kidney stones to prostate cancer, utilizing advanced techniques to improve patients' urinary health.",
        shortTitle:"Urology: Advancing Urinary Health and Male Reproductive Care Through Comprehensive Diagnostics, Surgical Excellence, and Patient-Centered Solutions",
        descHead1:"Comprehensive Diagnostics :",
        descHead2:"Kidney Health :",
        descHead3:"Prostate Health :",
        descHead4:"Urinary Tract Disorders :",
        descHead5:"Male Reproductive Health :",
        descHead6:"Surgical Expertise :",
        descPoint1:"Urologists employ advanced imaging, tests, and procedures to diagnose conditions affecting the urinary tract and male reproductive organs.",
        descPoint2:"Urologists manage kidney-related issues, such as kidney stones, infections, and kidney diseases, ensuring proper kidney function.",
        descPoint3:"Monitoring and treating conditions like enlarged prostate (BPH) and prostate cancer is a key focus for urologists.",
        descPoint4:"Urologists address urinary tract infections, bladder dysfunction, and urinary incontinence through various medical and surgical interventions.",
        descPoint5:"Urologists manage male reproductive health concerns, including erectile dysfunction, infertility, and testicular disorders.",
        descPoint6:"Urologists perform surgical procedures such as minimally invasive surgeries, laser treatments, and robotic-assisted surgeries for optimal patient outcomes.",
    },
    {
        id:11,
        ServiceImage:SerImg11,
        Header:"General Surgery",
        category:"Chembur",
        Slugs:"/general-surgeon",
        ShortDesc:"Delve into the dynamic world of general surgery and the crucial role that general surgeons play in diagnosing, treating, and performing a wide range of surgical procedures. From emergency interventions to elective surgeries, this comprehensive content provides insights into the skills, responsibilities, and importance of general surgeons in the field of medicine.",
        BannerDesc:"Delve into the dynamic world of general surgery and the crucial role that general surgeons play in diagnosing, treating, and performing a wide range of surgical procedures. From emergency interventions to elective surgeries, this comprehensive content provides insights into the skills, responsibilities, and importance of general surgeons in the field of medicine.",
        ShortDesc1:"General surgeons are medical professionals who specialize in performing surgical procedures across various areas of the body. Their expertise spans from routine surgeries to complex interventions, encompassing emergency trauma care, elective procedures, and critical surgeries. This content offers a glimpse into the multifaceted role of a general surgeon, showcasing their dedication to patient care, precision in the operating room, and commitment to improving lives through surgical excellence.",
        shortTitle:"Mastering the Art of Surgery: Exploring the Role of a General Surgery",
        descHead1:"Versatility in Surgical Procedures :",
        descHead2:"Emergency Care:",
        descHead3:"Preoperative and Postoperative Care :",
        descHead4:"Collaboration with Specialists :",
        descHead5:"Precision and Skill :",
        descHead6:"Lifelong Learning :",
        descPoint1:"Explore the diverse scope of surgeries that general surgeons undertake, including appendectomies, hernia repairs, gallbladder removals, and more. Their ability to adapt to a wide range of surgical challenges is a hallmark of their profession.",
        descPoint2:"Discover how general surgeons are often at the forefront of emergency medical care, handling life-saving surgeries for trauma patients, accident victims, and those in critical conditions. Their quick thinking and surgical skills can make a significant difference in patient outcomes.",
        descPoint3:"Learn about the comprehensive care that general surgeons provide before and after surgery. From preoperative assessments to postoperative follow-ups, they ensure patient safety, comfort, and recovery.",
        descPoint4:"Understand the collaborative nature of general surgery, as surgeons work closely with other medical specialists to deliver comprehensive treatment plans. This interdisciplinary approach ensures a holistic approach to patient care.",
        descPoint5:"Gain insight into the meticulous nature of surgical procedures and the precision required by general surgeons. Their technical expertise, combined with years of training, enables them to achieve optimal results for their patients.",
        descPoint6:"Explore the commitment of general surgeons to ongoing learning and professional development. Advances in medical technology and techniques require them to stay updated and adapt to evolving practices.",
    },
    {
        id:12,
        ServiceImage:SerImg12,
        Header:"Nephrologist",
        Branch:"Chembur",
        category:"Chembur",
        Slugs:"/nephrologist",
        ShortDesc:"A nephrologist is a specialized medical professional focused on the diagnosis, treatment, and management of kidney-related conditions. Their expertise lies in maintaining optimal kidney function and preventing kidney-related disorders.",
        BannerDesc:"In essence, nephrologists are dedicated to safeguarding kidney health, offering a range of treatments and guidance to ensure optimal kidney function and overall quality of life.",
        ShortDesc1:"Gynecology is a medical specialty that focuses on the health of the female reproductive system and the female reproductive organs, including the uterus, ovaries, fallopian tubes, and vagina. Gynecologists are medical doctors who specialize in diagnosing and treating a wide range of conditions related to women's reproductive health, including menstrual problems, infertility, menopause, pregnancy, childbirth, and various gynecological cancers. They also provide preventive care, such as pelvic exams, pap tests, and screenings for sexually transmitted infections. Gynecologists play a critical role in ensuring the overall health and well-being of women.",
        shortTitle:"Nurturing Kidney Vitality for Lifelong Wellness",
        descHead1:"Kidney Expertise :",
        descHead2:"Disease Diagnosis :",
        descHead3:"Treatment Precision  :",
        descHead4:"Dialysis and Transplants :",
        descHead5:"Holistic Care :",
        descHead6:"Preventive Guidance :",
        descPoint1:"Nephrologists are dedicated to understanding the intricate workings of the kidneys and their impact on overall health.",
        descPoint2:"They diagnose and treat a range of kidney-related ailments, including kidney stones, chronic kidney disease, and hypertension.",
        descPoint3:" Nephrologists employ a range of therapies, medications, and interventions to address kidney conditions effectively.",
        descPoint4:"They specialize in managing dialysis treatments and evaluating candidates for kidney transplants.",
        descPoint5:" Nephrologists consider the broader health implications of kidney disorders, providing comprehensive care.",
        descPoint6:" They educate patients about kidney health and empower them to adopt lifestyles that support kidney well-being.",
    },
    {
        id:13,
        ServiceImage:SerImg13,
        Header:"Dermatologist",
        Branch:"Both",
        category:"Both",
        Slugs:"/dermatology",
        ShortDesc:"A dermatologist is a specialized medical professional who specializes in diagnosing, treating, and managing skin, hair, and nail conditions. They are experts in maintaining skin health and enhancing its appearance",
        BannerDesc:"A dermatologist is a specialized medical professional who specializes in diagnosing, treating, and managing skin, hair, and nail conditions. They are experts in maintaining skin health and enhancing its appearance",
        ShortDesc1:"In essence, dermatologists are allies in achieving healthy, vibrant skin. They provide a range of treatments, guidance, and solutions to address skin concerns and promote self-assured well-being.",
        shortTitle:"Radiant Skin, Confident You",
        descHead1:"Skin Expertise :",
        descHead2:"Diagnostic Precision :",
        descHead3:"Aesthetic Enhancement :",
        descHead4:"Hair and Nail Care :",
        descHead5:"Personalized Treatments :",
        descHead6:"Preventive Education :",
        descPoint1:"Dermatologists have in-depth knowledge of skin biology and its relation to overall health.",
        descPoint2:"They diagnose and treat various skin issues, including acne, eczema, and skin cancer, using advanced techniques.",
        descPoint3:" Dermatologists offer cosmetic procedures to enhance skin appearance, including Botox, fillers, and laser treatments.",
        descPoint4:"They address conditions related to hair loss and nail disorders, ensuring comprehensive care.",
        descPoint5:"Dermatologists tailor treatments to individual needs, considering factors like skin type and medical history.",
        descPoint6:"They educate patients on skin protection and care to prevent future issues and maintain skin health.",
    },
    {
        id:14,
        ServiceImage:SerImg14,
        Header:"Pulmonology",
        Branch:"Both",
        category:"Both",
        Slugs:"/chest-physican",
        ShortDesc:"Pulmonology, a specialized medical field, revolves around the study and management of respiratory disorders and lung health. Dedicated pulmonologists diagnose, treat, and provide essential care for a wide spectrum of respiratory conditions, aiming to improve breathing and overall well-being.",
        BannerDesc:"Pulmonology, a specialized medical field, revolves around the study and management of respiratory disorders and lung health. Dedicated pulmonologists diagnose, treat, and provide essential care for a wide spectrum of respiratory conditions, aiming to improve breathing and overall well-being.",
        ShortDesc1:"Pulmonology is a vital branch of medicine that focuses on respiratory health. Pulmonologists, equipped with extensive knowledge and expertise, diagnose and treat various lung-related issues. From common concerns like asthma and bronchitis to complex conditions such as lung infections and pulmonary fibrosis, pulmonology encompasses a range of disorders. Through innovative diagnostics and tailored treatments, pulmonologists enhance lung function, alleviate symptoms, and promote optimal respiratory wellness.",
        shortTitle:"Pulmonology: Nurturing Respiratory Wellness Through Specialized Expertise and Comprehensive Care",
        descHead1:"Respiratory Experts :",
        descHead2:"Holistic Care :",
        descHead3:"Diagnostic Precision :",
        descHead4:"Tailored Treatment :",
        descHead5:"Innovative Interventions :",
        descHead6:"Collaborative Approach :",
        descPoint1:"Pulmonologists are specialized physicians dedicated to understanding and managing lung and respiratory disorders.",
        descPoint2:"They provide comprehensive care for a diverse range of conditions, including chronic obstructive pulmonary disease (COPD), pneumonia, and lung cancer.",
        descPoint3:"Pulmonologists employ advanced diagnostic tools like spirometry and imaging to accurately identify and assess respiratory issues.",
        descPoint4:" Treatment plans are customized to each patient's condition, addressing symptoms, improving lung function, and enhancing quality of life.",
        descPoint5:"These specialists stay updated with the latest medical advancements, offering innovative treatments such as bronchoscopies and pulmonary rehabilitation.",
        descPoint6:"Pulmonologists collaborate with other medical experts to ensure well-rounded care, addressing both respiratory and overall health concerns.",
    },
    {
        id:15,
        ServiceImage:SerImg15,
        Header:"Hematology",
        Branch:"Chembur",
        category:"Chembur",
        Slugs:"/hematology",
        ShortDesc:"Hematology, a specialized field within medicine, is dedicated to the study, diagnosis, and treatment of blood-related disorders. Hematologists explore the intricacies of blood composition, its components, and their impact on overall health. Their expertise contributes to improved blood conditions, enhanced well-being, and better quality of life for patients.",
        BannerDesc:"Hematology, a specialized field within medicine, is dedicated to the study, diagnosis, and treatment of blood-related disorders. Hematologists explore the intricacies of blood composition, its components, and their impact on overall health. Their expertise contributes to improved blood conditions, enhanced well-being, and better quality of life for patients.",
        ShortDesc1:"Hematology stands as a pivotal branch of medicine focused on blood disorders. Hematologists, armed with in-depth knowledge and advanced techniques, diagnose and treat an array of conditions such as anemia, bleeding disorders, and blood cancers. By utilizing cutting-edge diagnostics and tailored therapies, hematologists aim to restore blood health, manage symptoms, and foster positive outcomes for patients.",
        shortTitle:"There are many different types of gynecological conditions that a gynecologist may diagnose and treat. Some common gynecological conditions include:",
        descHead1:"Blood Disorder Specialists :",
        descHead2:"Diverse Scope of Care :",
        descHead3:"Diagnostic Expertise :",
        descHead4:"Personalized Treatment Plans :",
        descHead5:"Cutting-Edge Therapies :",
        descHead6:"Collaborative Care :",
        descPoint1:"Hematologists are medical professionals skilled in diagnosing and managing various blood-related conditions.",
        descPoint2:"They provide comprehensive treatment for a range of disorders, including leukemia, hemophilia, and thrombosis",
        descPoint3:"Hematologists employ advanced laboratory tests and genetic analyses to pinpoint underlying causes of blood disorders.",
        descPoint4:"Therapies are customized to each patient's condition, addressing specific symptoms and promoting optimal blood health.",
        descPoint5:"Hematology incorporates innovative treatments such as stem cell transplants and targeted therapies for blood cancers.",
        descPoint6:"Hematologists work closely with other medical professionals to ensure holistic management of blood-related conditions.",
    },
    {
        id:16,
        ServiceImage:SerImg16,
        Header:"Cardiology",
        Branch:"Both",
        category:"Both",
        Slugs:"/cardiologist",
        ShortDesc:"Cardiology, a specialized field of medicine, is dedicated to the study, diagnosis, and treatment of heart-related conditions. Cardiologists possess expertise in understanding the complexities of the cardiovascular system, aiming to safeguard heart health and ensure optimal functioning. Through advanced techniques and interventions, cardiology strives to enhance heart well-being and prevent cardiac ailments.",
        BannerDesc:"Cardiology, a specialized field of medicine, is dedicated to the study, diagnosis, and treatment of heart-related conditions. Cardiologists possess expertise in understanding the complexities of the cardiovascular system, aiming to safeguard heart health and ensure optimal functioning. Through advanced techniques and interventions, cardiology strives to enhance heart well-being and prevent cardiac ailments.",
        ShortDesc1:"Cardiology stands as a vital medical discipline focused on heart health. Expert cardiologists play a pivotal role in diagnosing and managing a wide spectrum of cardiovascular conditions, ranging from hypertension and coronary artery disease to heart failure and arrhythmias. Employing state-of-the-art diagnostics and innovative treatments, cardiology aims to promote heart vitality, manage symptoms, and improve patients' quality of life.",
        shortTitle:"Cardiology: Navigating the Intricacies of Heart Health, Pioneering Diagnosis, and Elevating Treatment for Cardiovascular Well-Being",
        descHead1:"Heart Health Specialists :",
        descHead2:"Holistic Care :",
        descHead3:"Advanced Diagnostics :",
        descHead4:"Tailored Interventions :",
        descHead5:"Innovative Procedures :",
        descHead6:"Collaborative Approach :",
        descPoint1:"Cardiologists are medical professionals with specialized training in diagnosing and treating various heart-related issues.",
        descPoint2:" They provide comprehensive care for a diverse range of heart conditions, from congenital heart defects to acquired disorders.",
        descPoint3:"Cardiologists employ cutting-edge technologies such as echocardiography and angiography to accurately assess heart function.",
        descPoint4:"Treatment plans are personalized to each patient's condition, focusing on symptom relief and long-term heart health.",
        descPoint5:"Cardiology encompasses innovative procedures like angioplasty, stent implantation, and cardiac ablation for arrhythmias.",
        descPoint6:"Cardiologists work in conjunction with other medical professionals to ensure comprehensive cardiovascular care.",
    },
    {
        id:17,
        ServiceImage:SerImg17,
        Header:"Opthalmology",
        Branch:"Chembur",
        Branch:"Chembur",
        category:"Ghatkopar",
        Slugs:"/opthalmology",
        ShortDesc:"Ophthalmology, a specialized field of medicine, revolves around the study, diagnosis, and treatment of eye-related conditions. Ophthalmologists are skilled professionals dedicated to preserving and enhancing vision. By utilizing cutting-edge techniques and innovative treatments, ophthalmology aims to address a wide range of ocular issues, ensuring optimal eye health and visual clarity.",
        BannerDesc:"Ophthalmology, a specialized field of medicine, revolves around the study, diagnosis, and treatment of eye-related conditions. Ophthalmologists are skilled professionals dedicated to preserving and enhancing vision. By utilizing cutting-edge techniques and innovative treatments, ophthalmology aims to address a wide range of ocular issues, ensuring optimal eye health and visual clarity.",
        ShortDesc1:"Ophthalmology is a crucial medical discipline centered on eye care. Ophthalmologists play a pivotal role in diagnosing and managing various eye conditions, ranging from refractive errors and glaucoma to retinal disorders. Leveraging state-of-the-art diagnostics and advanced surgical procedures, ophthalmology aims to safeguard eye health, improve vision, and enhance patients' quality of life.",
        shortTitle:"Ophthalmology: Illuminating the Path to Clear Vision, Pioneering Diagnosis, and Elevating Eye Health for Enhanced Visual Well-Being",
        descHead1:"Vision Care Specialists :",
        descHead2:"Comprehensive Eye Care :",
        descHead3:"Cutting-Edge Diagnostics :",
        descHead4:"Individualized Treatment Plans :",
        descHead5:"Surgical Excellence :",
        descHead6:"Collaboration and Care :",
        descPoint1:"Ophthalmologists possess specialized expertise in diagnosing and treating an array of eye-related ailments.",
        descPoint2:"They provide comprehensive care for conditions like cataracts, macular degeneration, and diabetic retinopathy.",
        descPoint3:"Ophthalmologists utilize advanced technologies such as optical coherence tomography and visual field testing for accurate diagnoses.",
        descPoint4:"Treatment approaches are tailored to each patient's unique needs, focusing on preserving or restoring visual function.",
        descPoint5:"Ophthalmology includes intricate procedures like cataract surgery, LASIK, and vitrectomy to address various eye issues.",
        descPoint6:"Ophthalmologists collaborate with other medical experts to ensure comprehensive eye health and overall well-being.",
    },
    {
        id:18,
        ServiceImage:SerImg18,
        Header:"Gastroenterologist",
        Branch:"Ghatkopar",
        category:"Ghatkopar",
        Slugs:"/gastroenterologist",
        ShortDesc:"Gastroenterology, a specialized medical field, is dedicated to the study, diagnosis, and treatment of disorders affecting the digestive system. Gastroenterologists are highly skilled physicians who focus on the health and well-being of the gastrointestinal tract. Through advanced procedures and interventions, gastroenterology aims to alleviate digestive ailments, improve overall gastrointestinal function, and enhance patients' quality of life.",
        BannerDesc:"Gastroenterology, a specialized medical field, is dedicated to the study, diagnosis, and treatment of disorders affecting the digestive system. Gastroenterologists are highly skilled physicians who focus on the health and well-being of the gastrointestinal tract. Through advanced procedures and interventions, gastroenterology aims to alleviate digestive ailments, improve overall gastrointestinal function, and enhance patients' quality of life.",
        ShortDesc1:"Gastroenterology stands at the forefront of digestive health care. Gastroenterologists play a crucial role in diagnosing and managing various gastrointestinal conditions, ranging from irritable bowel syndrome and gastroesophageal reflux disease to inflammatory bowel disease. Leveraging state-of-the-art diagnostics and cutting-edge endoscopic procedures, gastroenterology strives to restore digestive harmony, manage symptoms, and promote wellness.",
        shortTitle:"Gastroenterology: Navigating the Pathways of Digestive Health, Pioneering Diagnosis, and Elevating Gastrointestinal Wellness for Enhanced Quality of Life",
        descHead1:"Digestive System Specialists :",
        descHead2:"Comprehensive Digestive Care :",
        descHead3:"Advanced Diagnostics :",
        descHead4:"Tailored Treatment Plans :",
        descHead5:"Innovative Procedures :",
        descHead6:"Collaborative Care :",
        descPoint1:"Gastroenterologists are medical experts specializing in the diagnosis and treatment of conditions affecting the digestive tract.",
        descPoint2:"They address a wide range of gastrointestinal disorders, including peptic ulcers, Crohn's disease, and liver disorders.",
        descPoint3:"Gastroenterologists utilize advanced imaging techniques and tests like endoscopy and colonoscopy to accurately diagnose conditions.",
        descPoint4:"Treatment approaches are tailored to each patient's specific condition, focusing on symptom relief and digestive health optimization.",
        descPoint5:"Gastroenterology encompasses innovative procedures like endoscopic retrograde cholangiopancreatography (ERCP) and capsule endoscopy.",
        descPoint6:" Gastroenterologists collaborate with other medical specialists to ensure comprehensive care for patients' gastrointestinal well-being.",
    },
    {
        id:19,
        ServiceImage:SerImg19,
        Header:"Diabetologist",
        Branch:"Ghatkopar",
        category:"Ghatkopar",
        Slugs:"/gastroenterologist",
        ShortDesc:"Diabetology is a specialized medical field focused on the study, management, and treatment of diabetes. Diabetologists are skilled medical professionals dedicated to understanding the complexities of diabetes and providing comprehensive care to individuals with this chronic condition. Through a combination of medical interventions, lifestyle modifications, and patient education, diabetology aims to empower individuals to effectively manage their diabetes and lead healthier lives.",
        BannerDesc:"Diabetology is a specialized medical field focused on the study, management, and treatment of diabetes. Diabetologists are skilled medical professionals dedicated to understanding the complexities of diabetes and providing comprehensive care to individuals with this chronic condition. Through a combination of medical interventions, lifestyle modifications, and patient education, diabetology aims to empower individuals to effectively manage their diabetes and lead healthier lives.",
        ShortDesc1:"Diabetology is a vital discipline that addresses the challenges posed by diabetes, a chronic condition characterized by abnormal blood sugar levels. Diabetologists play a critical role in diagnosing and managing various types of diabetes, including type 1, type 2, and gestational diabetes. By focusing on personalized treatment plans, monitoring blood sugar levels, and promoting lifestyle changes, diabetology aims to enhance patients' well-being and minimize diabetes-related complications.",
        shortTitle:"Diabetology: Navigating the Complexities of Diabetes, Empowering Individuals to Manage Their Health and Well-being for a Fulfilling Life",
        descHead1:"Expert Diabetes Management :",
        descHead2:"Personalized Treatment Plans :",
        descHead3:"Blood Sugar Monitoring :",
        descHead4:"Lifestyle Modifications :",
        descHead5:"Preventing Complications :",
        descHead6:"Patient Education :",
        descPoint1:"Diabetologists are experts in managing all aspects of diabetes, from diagnosis to long-term care.",
        descPoint2:"Treatment plans are tailored to each patient's unique needs, considering factors like age, lifestyle, and diabetes type.",
        descPoint3:"Diabetologists emphasize regular blood sugar monitoring to ensure optimal diabetes management and prevent complications.",
        descPoint4:"Patients are educated about the importance of diet, exercise, and weight management in diabetes control.",
        descPoint5:"Diabetologists focus on preventing complications like diabetic neuropathy, retinopathy, and cardiovascular issues.",
        descPoint6:"Empowering patients with knowledge about their condition equips them to make informed decisions about their health.",
    },

]

export default ServiceData;