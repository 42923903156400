import React, { useLayoutEffect, useState } from 'react';
import "../Css/DoctorInfo.css"
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Doctor01 from "../img/Doctor/doctor-1.jpg";
import Footer from '../Components/Footer';
import DoctorData from '../Components/DoctorData';
import { useLocation } from 'react-router-dom';

const DoctorInfo = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const search = useLocation();
    const Path = search.pathname;

    const [serviceInfo, setServiceInfo] = useState(DoctorData);
    const ServiceDataFinal = serviceInfo.find(e => e.DoctorSlug == Path)

    return (
        <>

            {/* ---------------- Section 1 ---------------------- */}
            <section className='DoctorInfo-Section-1'>
                <div className='DoctorInfo-Section-con'>
                    <div className='DoctorInfo-Section-Text'>
                        <h2>
                            Doctor Details
                        </h2>
                        <div className='DoctorInfo-Section-NavPage'>
                            <span><AiFillHome />Home</span>
                            <h3><MdKeyboardArrowRight /></h3>
                            <h2>DoctorInfo</h2>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 2 ---------------------- */}

            <section className='DoctorInfo-Section-2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='DoctorInfo-DoctorImage-con'>
                                <div className='DoctorInfo-DoctorImage'>
                                    <img src={ServiceDataFinal.DoctorImage} alt={ServiceDataFinal.DoctorName}/>
                                </div>
                            </div>
                            <div className='DoctorInfo-Doctor-Short-Details-con'>
                                <p>Welcome to our Thunga STH hospital, where a dedicated team of highly skilled and compassionate doctors collaborates to provide comprehensive healthcare. With a focus on excellence, our diverse medical professionals bring expertise in various specialties, ensuring personalized and holistic patient care. Committed to innovation and the latest medical advancements, we strive to create a healing environment where patients receive the highest standard of treatment.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='Doctor-inafo-section'>
                                <h2>{ServiceDataFinal.DoctorName}</h2>
                                <h3>{ServiceDataFinal.Speciality}</h3>
                                <p>{ServiceDataFinal.DoctorExperience}</p>
                                <p>{ServiceDataFinal.DoctorDes01}</p>
                            </div>

                            <div className='Doctor-inafo-section-education'>
                                <h2>Education + Trainings</h2>
                                <p>{ServiceDataFinal.DoctorEducation}</p>
                                <p>{ServiceDataFinal.DoctorExperience}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default DoctorInfo